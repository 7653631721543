import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";

const baseLanguageLocation = "step.MeetYourAdvisorStep";

interface MeetYourAdvisorStepProps {}

const MeetYourAdvisorStep: React.FC<MeetYourAdvisorStepProps> = () => {
  const theme = useTheme();
  return (
    <Stack mt={3} spacing={1} sx={{ width: "100%" }} alignItems={"center"}>
      <Box sx={{ width: ["100%", "500px"] }}>
        <Typography component="li" sx={{ mb: 1 }}>
          {theme.t(`${baseLanguageLocation}.bullets.first.text`)}
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          {theme.t(`${baseLanguageLocation}.bullets.second.text`)}
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          {theme.t(`${baseLanguageLocation}.bullets.third.text`)}
        </Typography>
      </Box>
    </Stack>
  );
};

export default MeetYourAdvisorStep;
