/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Box, Button, Stack } from "@mui/material";
import { SlideSchema } from "@library/components/Sherpa/functions";
import AdvisorProfile from "@library/components/AdvisorProfile";
import isFunction from "lodash/isFunction";

interface CustomFooterProps {
  theme: any;
  payload: any;
  slideState: any;
  slideIndex: number;
  slide: SlideSchema;
  nextSlideIndex: number;
  setSlideDirection: (direction: number) => void;
  formRef: any;
  setSkipModalOpen: (open: boolean) => void;
}

const OnboardingFooter: React.FC<CustomFooterProps> = ({
  theme,
  slide,
  slideIndex,
  nextSlideIndex,
  setSlideDirection,
  formRef,
  setSkipModalOpen,
  payload,
}) => {
  // Render different footer content based on the footerContent type
  const renderFooterContent = () => {
    if (slide.config?.footerContent?.type === "advisor") {
      return (
        <AdvisorProfile theme={theme} borderStyle="right" payload={payload} />
      );
    }
  };

  return (
    <>
      {slide.navigationPlacement === "form" ? (
        <Stack
          spacing={2}
          direction="row"
          mr={2}
          justifyContent="flex-end"
          alignItems="center"
          className="Navigation"
        >
          <Box>
            {slideIndex > 0 && (
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setSlideDirection(-1);
                }}
                sx={{
                  px: 2,
                  mx: 1,
                  height: 50,
                  fontSize: 18,
                  minWidth: "100px",
                  color: slide.dark
                    ? theme.palette.primary.contrastText
                    : undefined,
                  borderColor: slide.dark ? "transparent" : undefined,
                  backgroundColor: slide.dark
                    ? "rgba(255,255,255,.2)"
                    : undefined,
                }}
              >
                {slide.backTitle ? slide.backTitle : "Back"}
              </Button>
            )}
            {slide.canSkip && slide.skipPlacement !== "form" && (
              <Button
                variant="contained"
                onClick={() => setSkipModalOpen(true)}
                color="secondary"
                sx={{
                  textTransform: "none",
                  height: 50,
                  fontSize: 18,
                  px: 2,
                  minWidth: "100px",
                }}
              >
                {slide.skipTitle || slide.skipText || "Skip this step"}
              </Button>
            )}
            {slide.nextTitle !== "" && slide.noSubmit !== true && (
              <Button
                variant="contained"
                type="submit"
                data-cy="submit"
                data-key={slide.key}
                color="primary"
                sx={{
                  height: 50,
                  fontSize: 18,
                  px: 2,
                  mx: 1,
                  minWidth: "100px",
                }}
                onClick={() => {
                  const form = formRef.current || {
                    submit: () => {},
                  };
                  if (form && form.submit) form.submit();
                }}
              >
                {nextSlideIndex !== -1
                  ? slide.nextTitle && isFunction(slide.nextTitle)
                    ? slide.nextTitle({ theme })
                    : slide.nextTitle
                      ? slide.nextTitle
                      : "Next"
                  : "Next"}
              </Button>
            )}
          </Box>
        </Stack>
      ) : (
        <Stack
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            boxShadow: "0px 0px 20px 0px #CCC",
            height: "75px",
            backgroundColor: theme.palette.primary.contrastText,
            zIndex: 2,
          }}
          className="Navigation"
        >
          <Stack
            spacing={2}
            direction="row"
            mt={slide.config?.footerContent?.type === "advisor" ? 0 : 1.5} // Adjust margin based on advisor presence
            mr={2}
            justifyContent={["space-between"]}
            alignItems={["center"]}
          >
            {/* Advisor Profile - only shown on specific slides */}
            <Stack
            // sx={{
            //   width: ["0px", "0px", "initial"],
            //   opacity: ["0%", "0%", "100%"],
            // }}
            >
              {renderFooterContent()}
            </Stack>

            {/* Navigation Buttons */}
            <Stack direction="row" alignItems="center" height="100%">
              {slideIndex > 0 && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setSlideDirection(-1);
                  }}
                  sx={{
                    px: 2,
                    mx: 1,
                    height: 50,
                    fontSize: 18,
                    minWidth: "100px",
                    color: slide.dark
                      ? theme.palette.primary.contrastText
                      : undefined,
                    borderColor: slide.dark ? "transparent" : undefined,
                    backgroundColor: slide.dark
                      ? "rgba(255,255,255,.2)"
                      : undefined,
                  }}
                >
                  {slide.backTitle ? slide.backTitle : "Back"}
                </Button>
              )}
              {slide.canSkip && slide.skipPlacement !== "form" && (
                <Button
                  variant="contained"
                  onClick={() => setSkipModalOpen(true)}
                  color="secondary"
                  sx={{
                    textTransform: "none",
                    height: 50,
                    fontSize: 18,
                    px: 2,
                    minWidth: "100px",
                  }}
                >
                  {slide.skipTitle || slide.skipText || "Skip this step"}
                </Button>
              )}
              {slide.nextTitle !== "" && slide.noSubmit !== true && (
                <Button
                  variant="contained"
                  type="submit"
                  data-cy="submit"
                  data-key={slide.key}
                  color="primary"
                  sx={{
                    height: 50,
                    fontSize: 18,
                    px: 2,
                    mx: 1,
                    minWidth: "100px",
                  }}
                  onClick={() => {
                    const form = formRef.current || { submit: () => {} };
                    if (form && form.submit) form.submit();
                  }}
                >
                  {nextSlideIndex !== -1
                    ? slide.nextTitle && isFunction(slide.nextTitle)
                      ? slide.nextTitle({ theme })
                      : slide.nextTitle
                        ? slide.nextTitle
                        : "Next"
                    : "Next"}
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default OnboardingFooter;
