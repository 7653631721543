import React from "react";
import { Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/material";
import { Rebate } from "@library/domain/estimate";
import { formatCurrency } from "@library/common";
import PlaceholderImage from "@library/components/PlaceholderImage";

const EligibleRebatesStep = ({
  slideState,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slideState: any;
}) => {
  const theme = useTheme();
  const rebates = slideState.rebates || [];
  return (
    <Stack spacing={2}>
      {rebates.length
        ? rebates.map((r: Rebate, index: number) => {
            return (
              <Stack
                key={index}
                sx={{
                  backgroundColor: theme.palette.common.white,
                  boxShadow: "0px 1px 1px 1px rgba(0,0,0,0.1)",
                }}
                direction="row"
              >
                <Stack
                  sx={{
                    minWidth: "200px",
                    backgroundColor: theme.palette.divider,
                  }}
                >
                  {r.imageUrl ? (
                    <img
                      src={r.imageUrl}
                      alt={r.fileName || r.name}
                      style={{
                        width: "100%",
                        height: 200,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <PlaceholderImage
                      imageHeight={200}
                      iconSize={20}
                      top="0px"
                      position="initial"
                    />
                  )}
                </Stack>
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                  p={2}
                >
                  <Stack>
                    <Typography
                      sx={{ fontSize: "1.0rem", fontWeight: 600 }}
                      color="primary"
                    >
                      {r.source || "Source of the rebate"}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.75rem", fontWeight: 600, mt: 0.5 }}
                    >
                      {r.name}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {r.description || "Description of the rebate package"}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Chip label={r.type} />
                    </Box>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ height: "100%" }}
                    />
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      pl={2}
                      width={"100px"}
                    >
                      <Typography variant="body1">Up to</Typography>
                      <Typography sx={{ fontWeight: 600, fontSize: "1.5rem" }}>
                        {formatCurrency(r.total)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            );
          })
        : null}
    </Stack>
  );
};

export default EligibleRebatesStep;
