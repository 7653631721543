import React from "react";

import airHandlerUnit from "@library/assets/airHandlerUnit.png";

type TaskImageMapping = {
  readonly [key: string]: string;
};

interface TaskImageProps {
  taskName: string;
  width?: number;
  height?: number;
}

// FYI: we can move this if it gets too large
const TASK_IMAGES: TaskImageMapping = {
  "S-Series Multiposition Air Handler Unit": airHandlerUnit, // Example
  "Install heat pump Outdoor Unit (ODU)": "",
  "Install a backup high-efficiency natural gas furnace": "",
  "Modify ductwork": "",
  "Install an energy recovery ventilator (ERV) system": "",
  "Install whole-home supplemental dehumidification": "",
};

const TaskImage: React.FC<TaskImageProps> = ({
  taskName,
  width = 80,
  height = 80,
}) => {
  const imagePath = TASK_IMAGES[taskName];

  if (!imagePath) return null;

  return (
    <img
      src={imagePath}
      alt={taskName}
      style={{
        width,
        height,
        objectFit: "contain",
      }}
    />
  );
};

export default TaskImage;
