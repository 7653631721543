/* eslint-disable @typescript-eslint/no-explicit-any */
import get from "lodash/get";
import { Theme } from "@mui/material";

import onboardingSchema from "@library/common/schema";
import { SlideComponent } from "@library/components/Sherpa/functions";
import api from "@library/api";
import { formatPhoneNumber } from "@library/common";

import { Description, Title, CalendlyVirtualConsultation } from "../components";
import { baseSchema, baseUISchema } from ".";

const props = onboardingSchema.properties;

const isScheduling = ({ payload }: { payload: any }) => {
  if (
    [
      "weatherization",
      "water_heating",
      "residential_solar",
      "ev_charging",
      "energy_efficient_appliances",
      "electrical_upgrades",
      "im_not_sure",
    ].includes(payload.projectType)
  ) {
    return true;
  }
  if (payload.wholeOrPartialHome === "partial") {
    return true;
  }
  if (payload.heatingCoolingProjectType === "add") {
    return true;
  }
  return false;
};

const isHeatingCoolingProject = ({ payload }: { payload: any }) => {
  if (payload.projectType === "heating_cooling") return true;
  return false;
};

const tokens = {};

const parseCustomTokens = (token: string, def?: string | undefined) => {
  return get(tokens, token, def);
};

const parseCleanValues = ({
  payload,
  slideState,
  theme,
}: {
  payload: any;
  slideState: any;
  theme: Theme;
}) => {
  const projectTypeClean = theme.t(
    `labels.projectType.enum.${payload.projectType}`
  )
    ? parseCustomTokens(
        theme.t(`labels.projectType.enum.${payload.projectType}`).toLowerCase()
      ) ||
      theme.t(`labels.projectType.enum.${payload.projectType}`).toLowerCase()
    : "project";

  return {
    ...slideState,
    projectTypeClean,
  };
};
const startPartialOnboarding = async ({
  setPayload,
  payload,
  index,
  slide,
}: {
  setPayload: (value: any) => void;
  payload: any;
  index: number;
  slide: SlideComponent;
}) => {
  if (!localStorage.getItem("onboardingId")) {
    const slideIndex = index;
    const slideKey = slide.key;
    try {
      const response = await api.post("onboarding/partial", {
        payload,
        slideIndex,
        slideKey,
        channelId: localStorage.getItem("channel") || undefined,
        onboardingId: localStorage.getItem("onboardingId") || undefined,
        type: payload.projectType,
        scope:
          payload.heatingCoolingProjectType || payload.coolingProjectType || "",
      });
      if (response?.data?.onboarding?.id) {
        const data = response.data;
        localStorage.setItem("onboardingId", data.onboarding.id);
        const newPayload = {
          ...payload,
          ...(data.homeData?.profile?.data || {}),
          homeData: data.homeData || {},
          user: {
            ...(payload.user || {}),
            ...(data.homeData?.profile?.data?.user || {}),
          },
        };
        setPayload({
          ...newPayload,
        });
        api.put(`onboarding/partial/${data.onboarding.id}`, {
          payload: newPayload,
          slideIndex,
          slideKey,
        });
        return {
          payload: newPayload,
        };
      }
    } catch (e) {
      return {
        error: e,
      };
    }
  }
};

export const sortingWorkflowSlides = [
  {
    // 1
    key: "ProjectTypeStep",
    Title,
    Description,
    noSubmit: true,
    onLoad: ({ payload }: { payload: any }) => {
      const newPayload = {
        ...payload,
        projectType: "",
      };
      return { payload: newPayload };
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["projectType"],
        properties: {
          projectType: {
            ...props.projectType,
          },
        },
      };
    },
    uiSchema: ({ theme }: { theme: Theme }) => {
      return {
        ...baseUISchema,
        projectType: {
          "ui:widget": "RadioImages",
          nextOnChange: true,
          imageBackgroundColor: theme.palette.primary.main,
          xs: 12,
          sm: 6,
          md: 4,
          lg: 3,
        },
      };
    },
    onNext: ({
      payload,
      setPayload,
    }: {
      payload: any;
      setPayload: (payload: any) => void;
    }) => {
      if (payload.projectType === "heating_cooling") {
        setPayload({
          ...payload,
        });
        return {
          slideKey: "HeatingCoolingProjectTypeStep",
        };
      }
    },
  },
  {
    // 1.2
    key: "HeatingCoolingProjectScopeStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (payload.projectType === "heating_cooling") return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["heatingCoolingProjectScope"],
        properties: {
          heatingCoolingProjectScope: {
            ...props.heatingCoolingProjectScope,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        heatingCoolingProjectScope: {
          "ui:widget": "RadioImages",
          compressed: true,
          stacked: true,
          nextOnChange: true,
          width: ["100%", "400px"],
        },
      };
    },
  },
  // 2
  {
    key: "HeatingCoolingProjectStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (isHeatingCoolingProject({ payload })) return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["heatingCoolingProjectType"],
        properties: {
          heatingCoolingProjectType: {
            ...props.heatingCoolingProjectType,
          },
          heatingCoolingProjectTypeMoreInfo: {
            ...props.heatingCoolingProjectTypeMoreInfo,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        heatingCoolingProjectType: {
          stacked: true,
          compressed: true,
          showRadioCircles: true,
          "ui:widget": "RadioImages",
          width: ["100%", "400px"],
        },
        heatingCoolingProjectTypeMoreInfo: {
          multiline: true,
          rows: 3,
        },
      };
    },
    onNext: ({ payload }: { payload: any }) => {
      if (payload.heatingCoolingProjectType === "service") {
        return {
          slideKey: "HeatingOrAirConditioningServiceAddressStep",
        };
      } else if (payload.heatingCoolingProjectType === "replace") {
        return {
          slideKey: "HeatingOrAirConditioningReplacementStep",
        };
      } else if (payload.heatingCoolingProjectType === "add") {
        return {
          slideKey: "SchedulingAddressStep",
        };
      }
    },
  },
  // 4.0
  {
    key: "HeatingOrAirConditioningServiceAddressStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating_cooling", "heating", "air_conditioning"].includes(
          payload.projectType
        ) &&
        (payload.heatingCoolingProjectType === "service" ||
          payload.airConditioningProjectType === "service")
      )
        return true;
      return false;
    },
    sx: ({ theme }: { theme: Theme }) => {
      return {
        backgroundColor: theme.palette.primary.main,
        ".MuiTypography-root": {
          color: `${theme.palette.primary.contrastText}`,
        },
        ".MuiButtonBase-root": {
          color: `${theme.palette.primary.contrastText}`,
        },
        ".MuiButton-containedPrimary": {
          backgroundColor: `${theme.palette.secondary.main}`,
        },
        ".Navigation": {
          backgroundColor: `${theme.palette.primary.light}`,
          boxShadow: "none",
        },
      };
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            required: ["address"],
            type: "object",
            properties: {
              address: {
                title: "Address",
                type: "string",
              },
            },
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:options": {
            label: false,
          },
          address: {
            "ui:widget": "AutocompleteAddress",
            "ui:autocomplete": "off",
            width: "100%",
          },
        },
      };
    },
    onNext: startPartialOnboarding,
  },
  // 4.1
  {
    key: "HeatingOrAirConditioningServiceStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating_cooling", "heating", "air_conditioning"].includes(
          payload.projectType
        ) &&
        (payload.heatingCoolingProjectType === "service" ||
          payload.airConditioningProjectType === "service")
      )
        return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            ...props.user,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:field": "CommunicationsField",
          firstName: {
            "ui:placeholder": "John",
          },
          lastName: {
            "ui:placeholder": "Doe",
          },
          email: {
            "ui:placeholder": "your@email.com",
          },
          phoneNumber: {
            "ui:placeholder": "(123) 456-7890",
            format: formatPhoneNumber,
          },
        },
      };
    },
    onNext: () => {
      return {
        slideKey: "HeatingOrAirConditioningScheduleServiceStep",
      };
    },
  },
  // 5
  {
    key: "HeatingOrAirConditioningScheduleServiceStep",
    Title,
    Description,
    noSubmit: true,
    Header: CalendlyVirtualConsultation,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating_cooling", "heating", "air_conditioning"].includes(
          payload.projectType
        ) &&
        (payload.heatingCoolingProjectType === "service" ||
          payload.airConditioningProjectType === "service")
      )
        return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },
  // 6
  {
    key: "HeatingOrAirConditioningReplacementStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating_cooling", "heating", "air_conditioning"].includes(
          payload.projectType
        ) &&
        (payload.heatingCoolingProjectType === "replace" ||
          payload.airConditioningProjectType === "replace")
      )
        return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["projectMotivation"],
        properties: {
          projectMotivation: {
            ...props.projectMotivation,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        projectMotivation: {
          stacked: true,
          compressed: true,
          showRadioCircles: true,
          "ui:widget": "RadioImages",
          nextOnChange: true,
          width: ["100%", "400px"],
        },
      };
    },
    onNext: ({ payload }: { payload: any }) => {
      if (payload.projectMotivation === "support") {
        return {
          slideKey: "HeatingOrAirConditioningReplaceEmergencyStep",
        };
      } else if (
        payload.projectMotivation === "online_quote" ||
        payload.projectMotivation === "directional_estimate" ||
        payload.projectMotivation === "incentives"
      ) {
        return {
          slideKey: "HeatingOrAirConditioningWholeOrPartialStep",
        };
      } else if (payload.projectMotivation === "feedback") {
        return {
          slideKey: "UploadQuoteStep",
        };
      }
      //  else if (payload.projectMotivation === "incentives") {
      //   return {
      //     slideKey: "IncentivesAddressStep",
      //   };
      // }
    },
  },
  // 7
  {
    key: "HeatingOrAirConditioningReplaceEmergencyStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating_cooling", "heating", "air_conditioning"].includes(
          payload.projectType
        ) &&
        (payload.heatingCoolingProjectType === "replace" ||
          payload.airConditioningProjectType === "replace") &&
        payload.projectMotivation === "support"
      )
        return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            ...props.user,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:field": "CommunicationsField",
          firstName: {
            "ui:placeholder": "John",
          },
          lastName: {
            "ui:placeholder": "Doe",
          },
          email: {
            "ui:placeholder": "your@email.com",
          },
          phoneNumber: {
            "ui:placeholder": "(123) 456-7890",
            format: formatPhoneNumber,
          },
        },
      };
    },
    onNext: () => {
      return {
        slideKey: "HeatingOrAirConditioningReplaceEmergencyScheduleStep",
      };
    },
  },
  // 8
  {
    key: "HeatingOrAirConditioningReplaceEmergencyScheduleStep",
    Title,
    Description,
    Header: CalendlyVirtualConsultation,
    noSubmit: true,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating_cooling", "heating", "air_conditioning"].includes(
          payload.projectType
        ) &&
        (payload.heatingCoolingProjectType === "replace" ||
          payload.airConditioningProjectType === "replace") &&
        payload.projectMotivation === "support"
      )
        return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },
  // 9
  {
    key: "HeatingOrAirConditioningWholeOrPartialStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating_cooling", "heating", "air_conditioning"].includes(
          payload.projectType
        ) &&
        (payload.heatingCoolingProjectType === "replace" ||
          payload.airConditioningProjectType === "replace") &&
        (payload.projectMotivation === "online_quote" ||
          payload.projectMotivation === "directional_estimate")
      )
        return true;
      return false;
    },
    onNext: ({ payload }: { payload: any }) => {
      if (payload.wholeOrPartialHome === "partial") {
        return {
          slideKey: "SchedulingAddressStep",
        };
      }
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["wholeOrPartialHome"],
        properties: {
          wholeOrPartialHome: {
            ...props.wholeOrPartialHome,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        wholeOrPartialHome: {
          stacked: true,
          compressed: true,
          showRadioCircles: true,
          "ui:widget": "RadioImages",
          nextOnChange: true,
          width: ["100%", "400px"],
        },
      };
    },
  },
  // 12
  {
    key: "UploadQuoteStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating_cooling", "heating", "air_conditioning"].includes(
          payload.projectType
        ) &&
        (payload.heatingCoolingProjectType === "replace" ||
          payload.heatingCoolingProjectType === "add" ||
          payload.airConditioningProjectType === "replace" ||
          payload.airConditioningProjectType === "add") &&
        payload.projectMotivation === "feedback"
      )
        return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },

  // Scheduling Off-Ramp
  {
    key: "SchedulingAddressStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (isScheduling({ payload })) return true;
      return false;
    },
    sx: ({ theme }: { theme: Theme }) => {
      return {
        backgroundColor: theme.palette.primary.main,
        ".MuiTypography-root": {
          color: `${theme.palette.primary.contrastText}`,
        },
        ".MuiButtonBase-root": {
          color: `${theme.palette.primary.contrastText}`,
        },
        ".MuiButton-containedPrimary": {
          backgroundColor: `${theme.palette.secondary.main}`,
        },
        ".Navigation": {
          backgroundColor: `${theme.palette.primary.light}`,
          boxShadow: "none",
        },
      };
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            required: ["address"],
            type: "object",
            properties: {
              address: {
                title: "Address",
                type: "string",
              },
            },
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:options": {
            label: false,
          },
          address: {
            "ui:widget": "AutocompleteAddress",
            "ui:autocomplete": "off",
            width: "100%",
          },
        },
      };
    },
    onNext: startPartialOnboarding,
  },
  {
    key: "SchedulingCommunicationStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (isScheduling({ payload })) return true;
      return false;
    },
    onLoad: ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      return parseCleanValues({ payload, slideState, theme });
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            ...props.user,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:field": "CommunicationsField",
          firstName: {
            "ui:placeholder": "John",
          },
          lastName: {
            "ui:placeholder": "Doe",
          },
          email: {
            "ui:placeholder": "your@email.com",
          },
          phoneNumber: {
            "ui:placeholder": "(123) 456-7890",
            format: formatPhoneNumber,
          },
        },
      };
    },
  },
  {
    key: "SchedulingStep",
    Title,
    Description,
    noSubmit: true,
    Header: CalendlyVirtualConsultation,
    visible: ({ payload }: { payload: any }) => {
      if (isScheduling({ payload })) return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },
];

export default sortingWorkflowSlides;
