/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Box, Stack, Theme, Typography, useTheme } from "@mui/material";
import without from "lodash/without";
import get from "lodash/get";

import {
  DEFAULT_FIPS_CODE,
  formatCurrency,
  formatPhoneNumber,
} from "@library/common";
import { User } from "@library/domain/user";
import {
  OnNextReturn,
  SlideComponent,
} from "@library/components/Sherpa/functions";
import supabase from "@library/api/supabase";
import onboardingSchema from "@library/common/schema";
import api from "@library/api";
import { UTILITIES } from "@library/common/utilities";
import Checklist from "@library/components/form/assets/Checklist";

import { baseSchema, baseUISchema } from ".";
import {
  Description,
  Title,
  Sidebar,
  CalendlyVirtualConsultation,
} from "../components";

import LetsGetStartedStep from "../components/slides/LetsGetStartedStep";
import EligibleRebatesStep from "../components/slides/EligibleRebatesStep";
import MeetYourAdvisorStep from "../components/slides/MeetYourAdvisorStep";
import LightEstimateOptionsStep from "../components/slides/LightEstimateOptionsStep";
import { Rebate } from "@library/domain/estimate";

const props = onboardingSchema.properties;

const progress = {
  yourHome: "Your Home",
  yourSystem: "Your Current System",
  yourPreferences: "Your Preferences",
  instantEstimate: "Your Instant Estimate",
};

const tokens = {
  "furnace with ductwork": "furnace",
  "boiler with radiators": "boiler",
  "electric resistive heat with baseboards": "resistive heat",
  "heat pump, also known as a “mini split”": "heat pump",
  "none of these match my heating system": "heating system",
  "i don't know": "unknown",
  electricity: "electric",
};

const parseCustomTokens = (token: string, def?: string | undefined) => {
  return get(tokens, token, def);
};

const parseCleanValues = ({
  payload,
  slideState,
  theme,
}: {
  payload: any;
  slideState: any;
  theme: Theme;
}) => {
  const heatingEquipmentFuelClean = theme.t(
    `labels.fuelSource.enum.${payload.fuelSource}`
  )
    ? parseCustomTokens(
        theme.t(`labels.fuelSource.enum.${payload.fuelSource}`).toLowerCase()
      ) || theme.t(`labels.fuelSource.enum.${payload.fuelSource}`).toLowerCase()
    : payload.fuelSource;

  const heatingEquipmentTypeClean = payload.heatingEquipmentType?.map(
    (e: string) =>
      theme.t(`labels.heatingEquipmentType.enum.${e}`)
        ? parseCustomTokens(
            theme.t(`labels.heatingEquipmentType.enum.${e}`).toLowerCase(),
            "heating system"
          )
        : "heating system"
  );
  const coolingEquipmentTypeClean = payload.coolingEquipmentType?.map(
    (e: string) =>
      theme.t(`labels.coolingEquipmentType.enum.${e}`)
        ? parseCustomTokens(
            theme.t(`labels.coolingEquipmentType.enum.${e}`).toLowerCase(),
            "cooling system"
          )
        : "cooling system"
  );
  return {
    ...slideState,
    heatingEquipmentTypeClean,
    heatingEquipmentFuelClean,
    coolingEquipmentTypeClean,
  };
};

const isInstantEstimateWorkflow = ({ payload }: { payload: any }) => {
  if (payload.wholeOrPartialHome === "partial") return false;
  if (
    ["heating_cooling", "heating", "air_conditioning"].includes(
      payload.projectType
    ) &&
    (payload.heatingCoolingProjectType === "replace" ||
      payload.airConditioningProjectType === "replace") &&
    (payload.projectMotivation === "online_quote" ||
      payload.projectMotivation === "directional_estimate" ||
      payload.projectMotivation === "incentives")
  ) {
    return true;
  }
  return false;
};

const isFullQuoteWorkflow = ({ payload }: { payload: any }) => {
  if (
    payload.howWouldYouLikeToProceed === "instant_quote" ||
    payload.howWouldYouLikeToProceed === "save_progress"
  ) {
    return true;
  }
  return false;
};

const isScheduleVirtualConsulation = ({ payload }: { payload: any }) => {
  if (payload.howWouldYouLikeToProceed === "schedule_virtual_consultation") {
    return true;
  }
  return false;
};

const isSaveProgress = ({ payload }: { payload: any }) => {
  if (payload.howWouldYouLikeToProceed === "save_progress") {
    return true;
  }
  return false;
};

const getRecordCompleteness = (payload: any) => {
  const data = payload?.homeData?.profile?.data || {};
  if (
    data.yearBuilt &&
    data.sqFootage &&
    data.numBedrooms &&
    // data.numBathrooms &&
    data.homeType &&
    data.aboveGroundStories &&
    data.heatingEquipmentType?.length &&
    data.fuelSource &&
    data.coolingEquipmentType?.length
  ) {
    return "full";
  } else if (
    data.yearBuilt ||
    data.sqFootage ||
    data.numBedrooms ||
    // data.numBathrooms &&
    data.homeType ||
    data.aboveGroundStories ||
    data.heatingEquipmentType?.length ||
    data.fuelSource ||
    data.coolingEquipmentType?.length
  ) {
    return "partial";
  }
  return "none";
};

const isRebateCheck = ({ payload }: { payload: any }) => {
  return payload.rebateCheck;
};

interface IncomeRange {
  incomeAmount: number;
}

const buildIncomeOptions = (data: IncomeRange[]) => {
  const incomes = data?.map((x) => x.incomeAmount) ?? [];

  if (!incomes.length) return [];

  const [lim80, lim120, lim150] = incomes;
  const incomeLabels = [
    { const: 80, title: `Less than ${formatCurrency(lim80)}` },
    {
      const: 120,
      title: `Between ${formatCurrency(lim80)} - ${formatCurrency(lim120)}`,
    },
    {
      const: 150,
      title: `Between ${formatCurrency(lim120)} - ${formatCurrency(lim150)}`,
    },
    {
      const: 0,
      title: `More than ${formatCurrency(lim150)}`,
    },
  ];

  return incomeLabels;
};

const parseRebates = (data: any) => {
  let eligibleRebateTotal = 0;
  let rebates: Rebate[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_key, value] of Object.entries(data)) {
    const data = value as any;
    const _rebates = (data?.data?.rebates as Rebate[]) ?? [];
    let total = 0;
    _rebates.forEach((r: Rebate) => {
      total += r.total || 0;
    });
    if (total >= eligibleRebateTotal) {
      eligibleRebateTotal = total;
      rebates = _rebates;
    }
  }
  return [eligibleRebateTotal, rebates];
};

const fetchQuickEstimate = async ({
  payload,
  slideState,
  theme,
}: {
  payload: any;
  slideState: any;
  theme: Theme;
}) => {
  // @TODO maybe fetch from slideState as cache from previous step if it exists
  // to save one extra call? But maybe it doesn't matter because engine is so fast.
  const resp = await api.post("onboarding/engine", {
    onboarding: {
      ...payload,
      user: {
        ...payload.user,
        fipsCountyCode: payload.user.fipsCountyCode || DEFAULT_FIPS_CODE,
      },
      rooms:
        payload.rooms?.length > 0
          ? payload.rooms.map((room: any) => {
              return {
                size: "MEDIUM",
                floor: "1",
                wallsWithWindows: "a few",
                walls: "1",
                type: "BEDROOM",
                label: "Bedroom",
                isCurrentlyHeated: true,
                isCurrentlyAirConditioned: true,
                ...room,
              };
            })
          : Array.from({ length: payload.numBedrooms || 4 }, () => ({
              size: "MEDIUM",
              floor: "1",
              wallsWithWindows: "a few",
              walls: "1",
              type: "BEDROOM",
              label: "Bedroom",
              isCurrentlyHeated: true,
              isCurrentlyAirConditioned: true,
            })),
    },
    tiers: theme.config?.tiers?.available ?? ["base", "pearl"],
  });
  return {
    ...slideState,
    estimates: resp.data,
  };
};

const isDucted = ({ payload }: { payload: any }) => {
  const heatingEquipmentType = payload.heatingEquipmentType || [];
  const coolingEquipmentType = payload.coolingEquipmentType || [];
  if (
    heatingEquipmentType.includes("furnace_with_ducts") ||
    coolingEquipmentType.includes("central_air_conditioning")
  )
    return true;
  return false;
};

const isAFUERated = ({ payload }: { payload: any }) => {
  const heatingEquipmentType = payload.heatingEquipmentType || [];
  if (
    heatingEquipmentType.includes("furnace_with_ducts") ||
    heatingEquipmentType.includes("boiler_with_radiators") ||
    heatingEquipmentType.includes("electric_resistive_with_baseboards")
  )
    return true;
  return false;
};

const isHSPFRated = ({ payload }: { payload: any }) => {
  const heatingEquipmentType = payload.heatingEquipmentType || [];
  if (heatingEquipmentType.includes("heat_pump_mini_split")) return true;
  return false;
};

const isSEERRated = ({ payload }: { payload: any }) => {
  const coolingEquipmentType = payload.coolingEquipmentType || [];
  if (coolingEquipmentType.length) return true;
  return false;
};

const isBTURated = ({ payload }: { payload: any }) => {
  const heatingEquipmentType = payload.heatingEquipmentType || [];
  if (
    heatingEquipmentType.includes("furnace_with_ducts") ||
    heatingEquipmentType.includes("boiler_with_radiators") ||
    heatingEquipmentType.includes("heat_pump_mini_split")
  ) {
    return true;
  }
  return false;
};

const isTonRated = ({ payload }: { payload: any }) => {
  const coolingEquipmentType = payload.coolingEquipmentType || [];
  if (
    coolingEquipmentType.includes("central_air_conditioning") ||
    coolingEquipmentType.includes("window_unit_air_conditioning") ||
    coolingEquipmentType.includes("heat_pump_mini_split")
  ) {
    return true;
  }
  return false;
};

// const isWholeHomeWorkflow = ({ payload }: { payload: any }) => {
//   if (payload.wholeOrPartialHome === "whole") return true;
//   return false;
// };

// const isPartialHomeWorkflow = ({ payload }: { payload: any }) => {
//   if (payload.wholeOrPartialHome === "partial") return true;
//   return false;
// };

const sharedUIConfig = {
  radioImages: {
    "ui:widget": "RadioImages",
    stacked: true,
    nextOnChange: true,
    compressed: true,
    width: ["100%", "400px"],
  },
  buttonGroup: {
    "ui:widget": "ButtonGroup",
    fontSize: 14,
    width: ["100%", "400px"],
  },
  customSelect: {
    "ui:widget": "CustomSelectField",
    width: ["100%", "400px"],
    labelPlacement: "none",
  },
};

export const instantEstimateWorkflowSlides = [
  // 1
  {
    key: "LetsGetStartedStep",
    Title: (props: SlideComponent) => {
      const theme = useTheme();
      return <Title {...props} color={theme.palette.primary.main} />;
    },
    Description,
    Header: ({
      setSlideDirection,
    }: {
      setSlideDirection: (direction: number) => void;
    }) => {
      return <LetsGetStartedStep setSlideDirection={setSlideDirection} />;
    },
    nextTitle: ({ theme }: { theme: Theme }) => {
      return theme.t(`step.LetsGetStartedStep.buttons.startEstimate`);
    },
    visible: ({ payload }: { payload: any }) => {
      if (payload.projectMotivation === "incentives") return false;
      if (isRebateCheck({ payload })) return false;
      if (!isInstantEstimateWorkflow({ payload })) return false;
      return true;
    },
    schema: () => {},
    uiSchema: () => {},
    onNext: () => {},
  },
  // 2
  {
    key: "AddressStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      return true;
    },
    asyncOnLoad: false,
    asyncOnNext: false,
    sx: ({ theme }: { theme: Theme }) => {
      return {
        backgroundColor: theme.palette.primary.main,
        ".MuiTypography-root": {
          color: `${theme.palette.primary.contrastText}`,
        },
        ".MuiButtonBase-root": {
          color: `${theme.palette.primary.contrastText}`,
        },
        ".MuiButton-containedPrimary": {
          backgroundColor: `${theme.palette.secondary.main}`,
        },
        ".Navigation": {
          backgroundColor: `${theme.palette.primary.light}`,
          boxShadow: "none",
        },
      };
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            required: ["address"],
            type: "object",
            properties: {
              address: {
                ...props.address,
                type: "string",
              },
            },
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:options": {
            label: false,
          },
          address: {
            ...props.address,
            "ui:widget": "AutocompleteAddress",
            "ui:autocomplete": "off",
            width: "100%",
          },
        },
      };
    },
    onNext: async ({
      setPayload,
      payload,
      index,
    }: {
      setPayload: (value: any) => void;
      payload: any;
      index: number;
    }) => {
      if (!localStorage.getItem("onboardingId")) {
        const slideIndex = index;
        const slideKey = "AddressStep";
        try {
          const response = await api.post("onboarding/partial", {
            payload,
            slideIndex,
            slideKey,
            channelId: localStorage.getItem("channel") || undefined,
            onboardingId: localStorage.getItem("onboardingId") || undefined,
          });
          if (response?.data?.onboarding?.id) {
            const data = response.data;
            localStorage.setItem("onboardingId", data.onboarding.id);
            const newPayload = {
              ...payload,
              ...(data.homeData?.profile?.data || {}),
              homeData: data.homeData ?? {},
              user: {
                ...(payload.user || {}),
                ...(data.homeData?.profile?.data?.user || {}),
              },
            };
            newPayload.user.fipsCountyCode =
              newPayload.user.fipsCountyCode || DEFAULT_FIPS_CODE;
            setPayload({
              ...newPayload,
            });
            api.put(`onboarding/partial/${data.onboarding.id}`, {
              payload: newPayload,
              slideIndex,
              slideKey,
            });
            return {
              payload: newPayload,
            };
          }
        } catch (e) {
          return {
            error: e,
          };
        }
      }
    },
  },
  // 6, 7, 8
  {
    key: "HomeProfileStep",
    Title: ({ payload }: { payload: any }) => {
      const theme = useTheme();
      const recordCompleteness = getRecordCompleteness(payload);
      let text = "";
      if (recordCompleteness === "full") {
        text = "Here's what we found. Does this look right?";
      } else if (recordCompleteness === "partial") {
        text = "Here's what we found. There are a few gaps in our data!";
      } else {
        text = "Sorry, we couldn't find your home!";
      }
      return (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 4 }}
        >
          <Typography
            gutterBottom={false}
            textAlign="center"
            role="heading"
            style={{
              fontWeight: theme.typography.fontWeightBold,
            }}
            sx={{
              lineHeight: 1,
              display: "inline-block",
              fontWeight: "bold",
              textAlign: "center",
              width: ["100%", 600, 900],
              fontSize: ["1.5rem", "2rem", "2.5rem"],
            }}
          >
            {text}
          </Typography>
        </Stack>
      );
    },
    Description: ({ payload }: { payload: any }) => {
      const theme = useTheme();
      const recordCompleteness = getRecordCompleteness(payload);
      let text = "";
      if (recordCompleteness === "full") {
        text = "This information is important, so please review and verify.";
      } else if (recordCompleteness === "partial") {
        text = "Please review and verify.";
      } else {
        text =
          "We can still provide an instant estimate, but you'll have to answer a few more questions.";
      }
      return (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography
            variant="h4"
            sx={{
              display: "inline-block",
              fontWeight: "400",
              marginTop: 1,
              width: ["100%", 600, 900],
              color: theme.palette.text.secondary,
              textAlign: ["center"],
              fontSize: ["0.8rem", "1.1rem !important"],
            }}
          >
            {text}
          </Typography>
        </Stack>
      );
    },
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      return true;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: [
          "homeType",
          "sqFootage",
          "yearBuilt",
          "aboveGroundStories",
          "numBedrooms",
          "heatingEquipmentType",
          "fuelSource",
          "coolingEquipmentType",
        ],
        properties: {
          homeType: {
            ...props.homeType,
          },
          sqFootage: {
            ...props.sqFootage,
          },
          yearBuilt: {
            ...props.yearBuilt,
          },
          aboveGroundStories: {
            ...props.aboveGroundStories,
          },
          numBedrooms: {
            ...props.numBedrooms,
          },
          heatingEquipmentType: {
            ...props.heatingEquipmentType,
          },
          fuelSource: {
            ...props.fuelSource,
          },
          coolingEquipmentType: {
            ...props.coolingEquipmentType,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        "ui:field": "HomeDetailsField",
        heatingEquipmentType: {
          multiple: true,
          max: 1,
        },
        coolingEquipmentType: {
          multiple: true,
          max: 1,
        },
      };
    },
  },
  // 9
  {
    key: "EligbleRebateStep",
    Title,
    Description,
    canSkip: true,
    Header: ({ payload, slideState }: { payload: any; slideState: any }) => {
      return <EligibleRebatesStep payload={payload} slideState={slideState} />;
    },
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      return true;
    },
    skipText: "No, Thanks",
    nextTitle: "Yes, Let's Check!",
    schema: () => {},
    uiSchema: () => {},
    asyncOnLoad: false,
    fetchOnLoad: async ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      const ret = await fetchQuickEstimate({ payload, slideState, theme });
      const [eligibleRebateTotal, rebates] = parseRebates(ret.estimates) || 0;
      return {
        ...ret,
        eligibleRebateTotal,
        rebates,
      };
    },
    onNext: ({ payload }: { payload: any }) => {
      return {
        slideKey: "RebateInformationStep",
        payload: {
          ...payload,
          rebateCheck: true,
        },
      };
    },
    onSkip: ({ payload }: { payload: any }) => {
      return {
        slideKey: "LightEstimateOptionsStep",
        payload: {
          ...payload,
          rebateCheck: false,
        },
      };
    },
  },
  // 10
  {
    key: "RebateInformationStep",
    Title: (props: SlideComponent) => {
      const theme = useTheme();
      return <Title {...props} color={theme.palette.primary.main} />;
    },
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isRebateCheck({ payload })) return false;
      return true;
    },
    nextTitle: "I Understand.",
    schema: () => {},
    uiSchema: () => {},
  },
  // 11
  {
    key: "OwnOrRentStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isRebateCheck({ payload })) return false;
      return true;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["ownOrRent"],
        properties: {
          ownOrRent: {
            ...props.ownOrRent,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      ownOrRent: {
        ...props.ownOrRent,
        ...sharedUIConfig.radioImages,
        showRadioCircles: true,
      },
    }),
  },
  // 12
  {
    key: "PeopleLiveInHomeStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isRebateCheck({ payload })) return false;
      return true;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["peopleLiveInHome"],
        properties: {
          peopleLiveInHome: {
            ...props.peopleLiveInHome,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      peopleLiveInHome: {
        ...props.peopleLiveInHome,
        ...sharedUIConfig.customSelect,
      },
    }),
  },
  // 13
  {
    key: "HouseHoldIncomeStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isRebateCheck({ payload })) return false;
      return true;
    },
    asyncOnLoad: false,
    fetchOnLoad: async ({
      slideState = { HouseHoldIncomeStep: {} },
      payload = {},
    }: {
      slideState: any;
      payload: any;
      theme: Theme;
    }) => {
      const { data } = await api.get("onboarding/fips", {
        peopleLiveInHome: payload.peopleLiveInHome || 1,
        fips: payload.user?.fipsCountyCode || DEFAULT_FIPS_CODE,
      });
      const incomeOptions = buildIncomeOptions(data);
      const HouseHoldIncomeStep = slideState.HouseHoldIncomeStep ?? {};
      return {
        ...slideState,
        HouseHoldIncomeStep: {
          ...HouseHoldIncomeStep,
          incomeOptions,
        },
      };
    },
    schema: ({ slideState }: { slideState: any }) => {
      return {
        ...baseSchema,
        required: ["houseHoldIncome"],
        properties: {
          houseHoldIncome: {
            ...props.houseHoldIncome,
            type: "number",
            oneOf: slideState?.HouseHoldIncomeStep?.incomeOptions ?? [],
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      houseHoldIncome: {
        ...props.houseHoldIncome,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 14
  {
    key: "UtilityCompanyStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isRebateCheck({ payload })) return false;
      return true;
    },
    schema: ({ payload }: { payload: any }) => {
      const fipsCountyCode = payload?.user?.fipsCountyCode;
      const electricity = get(UTILITIES, `${fipsCountyCode}.electricity`, []);
      const natural_gas = get(UTILITIES, `${fipsCountyCode}.natural_gas`, []);
      return {
        ...baseSchema,
        required: ["electricUtility"],
        properties: {
          electricUtility: {
            ...props.electricUtility,
            enum: [...electricity, ...["Not Listed"]],
          },
          gasUtility: {
            ...props.gasUtility,
            enum: [...natural_gas, ...["Not Listed"]],
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        "ui:grouped": true, // Group the fields
        pt: 2,
        pb: 2,
        width: ["100%", "400px"],
        electricUtility: {
          ...props.electricUtility,
          labelPlacement: "inline",
          "ui:widget": "CustomSelectField",
          width: ["100%", "350px"],
        },
        gasUtility: {
          ...props.gasUtility,
          labelPlacement: "inline",
          "ui:widget": "CustomSelectField",
          width: ["100%", "350px"],
        },
      };
    },
  },
  // 15
  {
    key: "PotentiallyEligbleRebateStep",
    Title,
    Description,
    Header: ({ payload, slideState }: { payload: any; slideState: any }) => {
      return <EligibleRebatesStep payload={payload} slideState={slideState} />;
    },
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isRebateCheck({ payload })) return false;
      return true;
    },
    asyncOnLoad: false,
    fetchOnLoad: async ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      const resp = await api.post("onboarding/engine", {
        onboarding: {
          ...payload,
          rooms: Array.from({ length: payload.numBedrooms || 4 }, () => ({
            size: "MEDIUM",
            floor: "1",
            wallsWithWindows: "a few",
            walls: "1",
            type: "BEDROOM",
            label: "Bedroom",
            isCurrentlyHeated: true,
            isCurrentlyAirConditioned: true,
            heatComfortIssueInfo: "",
          })),
          user: {
            ...payload.user,
            fipsCountyCode: payload.user.fipsCountyCode || DEFAULT_FIPS_CODE,
          },
        },
        tiers: theme.config?.tiers?.available ?? ["base", "pearl"],
      });
      const [eligibleRebateTotal, rebates] = parseRebates(resp.data) || 0;
      return {
        ...slideState,
        eligibleRebateTotal,
        estimates: resp.data,
        rebates,
      };
    },
    schema: () => {},
    uiSchema: () => {},
  },
  // 16
  {
    key: "LightEstimateOptionsStep",
    Title: (props: SlideComponent) => {
      const theme = useTheme();
      return <Title {...props} color={theme.palette.primary.main} />;
    },
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      return true;
    },
    asyncOnLoad: false,
    fetchOnLoad: fetchQuickEstimate,
    Header: ({ slideState }: { slideState: any }) => {
      const estimates = slideState.estimates || {};
      return <LightEstimateOptionsStep estimates={estimates} />;
    },
    schema: () => {},
    uiSchema: () => {},
  },
  // 17
  {
    key: "HowWouldYouLikeToProceedStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      return true;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["howWouldYouLikeToProceed"],
        properties: {
          howWouldYouLikeToProceed: {
            ...props.howWouldYouLikeToProceed,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      howWouldYouLikeToProceed: {
        ...props.howWouldYouLikeToProceed,
        ...sharedUIConfig.radioImages,
        fontSize: ["0.7rem", "1rem"],
      },
    }),
  },
  // 18
  {
    key: "AccountCreationStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (isSaveProgress({ payload })) return true;
      if (isScheduleVirtualConsulation({ payload })) return true;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            ...props.user,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:field": "CommunicationsField",
          firstName: {
            "ui:placeholder": "John",
          },
          lastName: {
            "ui:placeholder": "Doe",
          },
          email: {
            "ui:placeholder": "your@email.com",
          },
          phoneNumber: {
            "ui:placeholder": "(123) 456-7890",
            format: formatPhoneNumber,
          },
        },
      };
    },
    onNext: async ({
      index,
      payload,
      setPayload,
      user,
      setUser,
      setIsAuthenticated,
      draft = false,
    }: {
      index: number;
      payload: any;
      setPayload: (value: any) => void;
      user: Partial<User>;
      setUser: (data: User) => void;
      setIsAuthenticated: (value: boolean) => void;
      draft?: boolean;
    }): Promise<OnNextReturn> => {
      if (draft) {
        setIsAuthenticated(true);
        return {};
      }
      if (!payload.user) payload.user = {};
      if (payload?.user?.email) {
        payload.user.email = payload.user.email.toLowerCase();
      }
      let supabaseId = payload.user.supabaseId;
      if (!supabaseId) {
        try {
          const sbid = JSON.parse(
            localStorage.getItem(
              `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
            ) || "{}"
          )?.user?.id;
          if (sbid && sbid.length > 5) {
            supabaseId = sbid;
            payload.user.supabaseId = supabaseId;
          }
        } catch (e) {
          // Do nothing
        }
      }
      if (!supabaseId) {
        // Set a random password that the user will never user
        // All users have to either reset password or use magic links
        const password = "Fa!<3_" + Math.random().toString(36).slice(-16);
        const email = payload?.user?.email;
        if (!email) {
          return {
            error: "Please provide an email.",
          };
        }
        const { data: supabaseData, error: supabaseError } =
          await supabase.auth.signUp({
            email,
            password,
          });
        if (supabaseError) {
          let error = String(supabaseError) as string | ReactElement;
          if (String(supabaseError).includes("already registered")) {
            error = (
              <span>
                User is already registered. <Link to="/login">Log in</Link> to
                continue where you left off.
              </span>
            );
          }
          return {
            error,
          };
        }
        supabaseId = supabaseData.user?.id;
        payload.user.supabaseId = supabaseId;
      }
      // @TODO we need to clean up the old onboarding payload
      const { data, error: onboardingError } = await api.post("onboarding", {
        payload,
        activeStep: index,
        activeStepKey: "AccountCreationStep",
      });
      if (onboardingError) {
        setPayload(payload);
        return {
          error: String(onboardingError),
        };
      }
      localStorage.setItem("onboardingId", data.onboarding.id);
      const _user = {
        ...user,
        ...payload.user,
        ...data.user,
      };
      setPayload(payload);
      setUser(_user);
      setIsAuthenticated(true);
      if (isSaveProgress({ payload }) && payload?.user?.email) {
        await supabase.auth.signInWithOtp({
          email: payload.user.email,
          options: {
            shouldCreateUser: false,
            emailRedirectTo: `${window.location.origin}/login`,
          },
        });
      }
      return {
        payload: {
          ...payload,
        },
      };
    },
  },
  // 18.1
  {
    key: "PostSaveProgressStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (isSaveProgress({ payload })) return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },
  {
    key: "HeatingAndCoolingSchedulingStep",
    Title,
    Description,
    noSubmit: true,
    Header: CalendlyVirtualConsultation,
    visible: ({ payload }: { payload: any }) => {
      if (isScheduleVirtualConsulation({ payload })) return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },
  // 24
  {
    key: "MeetYourAdvisorStep",
    progress: progress.yourHome,
    Title: (props: SlideComponent) => {
      const theme = useTheme();
      return <Title {...props} color={theme.palette.primary.main} />;
    },
    Description,
    Sidebar,
    Header: () => {
      return <MeetYourAdvisorStep />;
    },
    config: {
      footerContent: {
        type: "advisor",
        data: {}, // Optional data to pass to the footer
      },
    },
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {},
    uiSchema: () => {},
    onNext: () => {},
  },
  // 25
  {
    key: "BasementTypeStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    canSkip: true,
    skipText: "Skip this step, I don't have a basement",
    skipPlacement: "form",
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["basementType"],
        properties: {
          basementType: {
            ...props.basementType,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        basementType: {
          ...props.basementType,
          "ui:widget": "RadioImages",
          nextOnChange: true,
          label: false,
          imageBackgroundColor: "rgb(33, 33, 33)",
          fontSize: ["0.7rem", "1rem"],
          imagePadding: 0,
          imageWidth: "100%",
        },
      };
    },
  },
  // 26
  {
    key: "BasementFinishedStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.basementType) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["basementFinished"],
        properties: {
          basementFinished: {
            ...props.basementFinished,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        basementFinished: {
          ...props.basementFinished,
          "ui:widget": "RadioImages",
          nextOnChange: true,
          imageBackgroundColor: "rgb(33, 33, 33)",
          imagePadding: 0,
          imageWidth: "100%",
        },
      };
    },
  },
  // 27
  {
    key: "AtticTypeStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    canSkip: true,
    skipText: "Skip this step, I don't have an attic",
    skipPlacement: "form",
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["atticType"],
        properties: {
          atticType: {
            ...props.atticType,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        atticType: {
          ...props.atticType,
          "ui:widget": "RadioImages",
          fontSize: ["0.7rem", "1rem"],
          nextOnChange: true,
          imagePadding: 0,
          imageWidth: "100%",
        },
      };
    },
  },
  // 28
  {
    key: "AtticFinishedStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.atticType) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["atticFinish"],
        properties: {
          atticFinish: {
            ...props.atticFinish,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        atticFinish: {
          ...props.atticFinish,
          "ui:widget": "RadioImages",
          nextOnChange: true,
          imagePadding: 0,
          imageWidth: "100%",
        },
      };
    },
  },
  // 29
  {
    key: "GarageStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    canSkip: true,
    skipText: "Skip this step, I don't have a garage",
    skipPlacement: "form",
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["garageType"],
        properties: {
          garageType: {
            ...props.garageType,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        garageType: {
          ...props.garageType,
          "ui:widget": "RadioImages",
          nextOnChange: true,
          imagePadding: 0,
          imageWidth: "100%",
          fontSize: ["0.7rem", "1rem"],
        },
      };
    },
  },
  // 30
  {
    key: "FloorPlanStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (isDucted({ payload })) return false;
      return true;
    },
    onLoad: ({ slideState, payload }: { payload: any; slideState: any }) => {
      if (!payload.rooms?.length) {
        const rooms = Array.from(
          { length: payload.numBedrooms || 4 },
          (_x: number, i: number) => ({
            wallsWithWindows: "a few",
            walls: "2",
            type: "BEDROOM",
            name: `Bedroom ${i + 1}`,
            label: "Bedroom",
            isCurrentlyHeated: true,
            isCurrentlyAirConditioned: true,
          })
        );
        rooms.push({
          wallsWithWindows: "a few",
          walls: "2",
          type: "BATHROOM",
          name: `Bathroom`,
          label: "Bathroom",
          isCurrentlyHeated: true,
          isCurrentlyAirConditioned: true,
        });
        rooms.push({
          wallsWithWindows: "a few",
          walls: "2",
          type: "KITCHEN",
          name: `Kitchen`,
          label: "Kitchen",
          isCurrentlyHeated: true,
          isCurrentlyAirConditioned: true,
        });
        rooms.push({
          wallsWithWindows: "a few",
          walls: "2",
          type: "LIVING_ROOM",
          name: `Living Room`,
          label: "Living Room",
          isCurrentlyHeated: true,
          isCurrentlyAirConditioned: true,
        });
        return {
          payload: {
            ...payload,
            rooms,
          },
        };
      }
      return slideState;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["rooms"],
        properties: {
          rooms: {
            ...props.rooms,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        rooms: {
          "ui:widget": "FloorPlan",
        },
      };
    },
  },
  // 31
  {
    key: "RoomsConfigurationStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (isDucted({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["rooms"],
        properties: {
          rooms: {
            ...props.rooms,
            items: {
              ...props.rooms.items,
              required: ["name", "floor", "size"],
            },
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        "ui:field": "RoomsConfiguration",
        rooms: {
          ...props.rooms,
        },
      };
    },
  },
  // 32
  {
    key: "NumberOfWindowsStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["numWindows"],
        properties: {
          numWindows: {
            ...props.numWindows,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      numWindows: {
        ...props.numWindows,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 33
  {
    key: "WindowsStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["windowType"],
        properties: {
          windowType: {
            ...props.windowType,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      windowType: {
        ...props.windowType,
        ...sharedUIConfig.radioImages,
        images: false,
      },
    }),
  },
  // 34
  {
    key: "DraftyStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    onNext: ({ payload }: { payload: any }) => {
      const draftyStuffy = without(payload.draftyStuffy || [], "yes_drafty");
      if (payload.drafty === "yes_drafty") {
        draftyStuffy.push("yes_drafty");
      }
      return {
        payload: {
          ...payload,
          draftyStuffy,
        },
      };
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["drafty"],
        properties: {
          drafty: {
            ...props.drafty,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      drafty: {
        ...props.drafty,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 35
  {
    key: "StuffyStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    onNext: ({ payload }: { payload: any }) => {
      let draftyStuffy = without(
        payload.draftyStuffy || [],
        "yes_stuffy",
        "no"
      );
      if (payload.stuffy === "yes_stuffy") {
        draftyStuffy.push("yes_stuffy");
      }
      if (
        !draftyStuffy.includes("yes_drafty") &&
        !draftyStuffy.includes("yes_stuffy")
      ) {
        draftyStuffy = ["no"];
      }
      return {
        payload: {
          ...payload,
          draftyStuffy,
        },
      };
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["stuffy"],
        properties: {
          stuffy: {
            ...props.stuffy,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      stuffy: {
        ...props.stuffy,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 36
  {
    key: "UpgradeStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    canSkip: true,
    showSkipModal: false,
    skipPlacement: "form",
    skipText: "I have not made any of these energy efficiency investments.",
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: [],
        properties: {
          upgrades: {
            ...props.upgrades,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        upgrades: {
          width: ["100%", 400],
          "ui:widget": "CheckboxImages",
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
        },
      };
    },
  },
  // 37
  {
    key: "AirSealingInvestmentStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.upgrades.includes("air_sealing")) {
        return false;
      }
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["airSealingScope", "airSealingInstallation"],
        properties: {
          airSealingScope: {
            ...props.airSealingScope,
          },
          airSealingInstallation: {
            ...props.airSealingInstallation,
          },
          airSealingMoreInfo: {
            ...props.airSealingMoreInfo,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      airSealingScope: {
        ...props.airSealingScope,
        ...sharedUIConfig.buttonGroup,
      },
      airSealingInstallation: {
        ...props.airSealingInstallation,
        ...sharedUIConfig.buttonGroup,
      },
      airSealingMoreInfo: {
        ...props.airSealingMoreInfo,
        multiline: true,
        rows: 3,
      },
    }),
  },
  // 38
  {
    key: "DuctSealingInvestmentStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.upgrades.includes("duct_insulation_sealing")) {
        return false;
      }
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["ductSealingScope", "ductSealingInstallation"],
        properties: {
          ductSealingScope: {
            ...props.ductSealingScope,
          },
          ductSealingInstallation: {
            ...props.ductSealingInstallation,
          },
          ductSealingMoreInfo: {
            ...props.ductSealingMoreInfo,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      ductSealingScope: {
        ...props.ductSealingScope,
        ...sharedUIConfig.buttonGroup,
      },
      ductSealingInstallation: {
        ...props.ductSealingInstallation,
        ...sharedUIConfig.buttonGroup,
      },
      ductSealingMoreInfo: {
        ...props.ductSealingMoreInfo,
        multiline: true,
        rows: 3,
      },
    }),
  },
  // 39
  {
    key: "InsulationInvestmentStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.upgrades.includes("added_insulation")) {
        return false;
      }
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: [],
        properties: {
          atticDetails: {
            type: "object",
            properties: {
              atticInsulation: {
                ...props.atticInsulation,
              },
            },
            allOf: [
              {
                if: {
                  properties: {
                    atticInsulation: {
                      ...props.atticInsulation,
                      contains: { const: "yes" },
                    },
                  },
                },
                then: {
                  properties: {
                    atticInsulation: {
                      ...props.atticInsulation,
                    },
                    atticInsulationType: {
                      ...props.atticInsulationType,
                    },
                  },
                },
                else: {
                  properties: {
                    atticInsulation: {
                      ...props.atticInsulation,
                    },
                  },
                },
              },
            ],
          },
          basementDetails: {
            type: "object",
            properties: {
              basementInsulation: {
                ...props.basementInsulation,
              },
            },
            allOf: [
              {
                if: {
                  properties: {
                    basementInsulation: {
                      ...props.basementInsulation,
                      contains: { const: "yes" },
                    },
                  },
                },
                then: {
                  properties: {
                    basementInsulation: {
                      ...props.basementInsulation,
                    },
                    basementInsulationType: {
                      ...props.basementInsulationType,
                    },
                  },
                },
                else: {
                  properties: {
                    basementInsulation: {
                      ...props.basementInsulation,
                    },
                  },
                },
              },
            ],
          },
          wallDetails: {
            type: "object",
            properties: {
              wallInsulation: {
                ...props.wallInsulation,
              },
            },
            allOf: [
              {
                if: {
                  properties: {
                    wallInsulation: {
                      ...props.wallInsulation,
                      contains: { const: "yes" },
                    },
                  },
                },
                then: {
                  properties: {
                    wallInsulation: {
                      ...props.wallInsulation,
                    },
                    wallInsulationType: {
                      ...props.wallInsulationType,
                    },
                  },
                },
                else: {
                  properties: {
                    wallInsulation: {
                      ...props.wallInsulation,
                    },
                  },
                },
              },
            ],
          },
          rimJoistDetails: {
            type: "object",
            properties: {
              rimJoistInsulation: {
                ...props.rimJoistInsulation,
              },
            },
            allOf: [
              {
                if: {
                  properties: {
                    rimJoistInsulation: {
                      ...props.rimJoistInsulation,
                      contains: { const: "yes" },
                    },
                  },
                },
                then: {
                  properties: {
                    rimJoistInsulation: {
                      ...props.rimJoistInsulation,
                    },
                    rimJoistInsulationType: {
                      ...props.rimJoistInsulationType,
                    },
                  },
                },
                else: {
                  properties: {
                    rimJoistInsulation: {
                      ...props.rimJoistInsulation,
                    },
                  },
                },
              },
            ],
          },
        },
      };
    },
    uiSchema: () => {
      const selectFieldConfig = {
        "ui:widget": "CustomSelectField",
        width: 250, // Set fixed width for select field
        labelPlacement: "inset",
        label: "Insulation Type",
        placeholder: "Select",
        sx: () => ({
          mt: { xs: 1, sm: 0 }, // Add margin top on mobile only
          display: "flex",
          justifyContent: "center", // Center the select in its container
        }),
      };

      const groupedFieldsConfig = {
        "ui:grouped": true,
        groupDirection: { xs: "column", sm: "row" }, // Stack vertically on mobile
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        mb: { xs: 2, sm: 1 },
        pr: { xs: 0, sm: 2 },
        width: "100%",
        backgroundColor: "white",
        sx: {
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "center", sm: "flex-start" }, // Center items on mobile
          justifyContent: "center", // Center content in container
        },
      };

      const checkboxConfig = {
        "ui:widget": "CheckboxImages",
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        px: { xs: 0, sm: 1 },
        py: { xs: 1, sm: 1 },
        sx: {
          display: "flex",
          justifyContent: "center", // Center checkbox content
        },
      };

      return {
        ...baseUISchema,
        atticDetails: {
          ...baseUISchema,
          ...groupedFieldsConfig,
          atticInsulation: {
            ...props.atticInsulation,
            ...checkboxConfig,
          },
          atticInsulationType: {
            ...props.atticInsulationType,
            ...selectFieldConfig,
          },
        },
        basementDetails: {
          ...baseUISchema,
          ...groupedFieldsConfig,
          basementInsulation: {
            ...props.basementInsulation,
            ...checkboxConfig,
          },
          basementInsulationType: {
            ...props.basementInsulationType,
            ...selectFieldConfig,
          },
        },
        wallDetails: {
          ...baseUISchema,
          ...groupedFieldsConfig,
          wallInsulation: {
            ...props.wallInsulation,
            ...checkboxConfig,
          },
          wallInsulationType: {
            ...props.wallInsulationType,
            ...selectFieldConfig,
          },
        },
        rimJoistDetails: {
          ...baseUISchema,
          ...groupedFieldsConfig,
          rimJoistInsulation: {
            ...props.rimJoistInsulation,
            ...checkboxConfig,
          },
          rimJoistInsulationType: {
            ...props.rimJoistInsulationType,
            ...selectFieldConfig,
          },
        },
      };
    },
  },
  // 40
  {
    key: "IssuesStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    canSkip: true,
    skipPlacement: "form",
    showSkipModal: false,
    skipText: "I don't have any of these issues.",
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: [],
        properties: {
          issues: {
            ...props.issues,
          },
          issuesMoreInfo: {
            ...props.issuesMoreInfo,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        issues: {
          ...props.issues,
          "ui:widget": "CheckboxImages",
          width: ["100%", 500],
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
        },
        issuesMoreInfo: {
          ...props.issuesMoreInfo,
          multiline: true,
          rows: 3,
          width: ["100%", 500],
        },
      };
    },
  },
  // 41
  {
    key: "ElectricalPanelRatingStep",
    progress: progress.yourHome,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["electricalPanelRating"],
        properties: {
          electricalPanelRating: {
            ...props.electricalPanelRating,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      electricalPanelRating: {
        ...props.electricalPanelRating,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 42
  {
    key: "HeatingEquipmentAgeStep",
    progress: progress.yourSystem,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.heatingEquipmentType?.length) return false;
      return true;
    },
    asyncOnLoad: false,
    onLoad: ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      return parseCleanValues({ payload, slideState, theme });
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["heatingEquipmentTypeAge"],
        properties: {
          heatingEquipmentTypeAge: {
            ...props.heatingEquipmentTypeAge,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      heatingEquipmentTypeAge: {
        ...props.heatingEquipmentTypeAge,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 43'
  {
    key: "HeatingEquipmentRatedEfficiencyStep",
    progress: progress.yourSystem,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    canSkip: true,
    skipPlacement: "form",
    skipText: "I don't know",
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.heatingEquipmentType?.length) return false;
      if (!isAFUERated({ payload })) return false;
      return true;
    },
    onLoad: ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      return parseCleanValues({ payload, slideState, theme });
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["heatingEquipmentAFUERating"],
        properties: {
          heatingEquipmentAFUERating: {
            ...props.heatingEquipmentAFUERating,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        heatingEquipmentAFUERating: {
          ...props.heatingEquipmentAFUERating,
          "ui:options": {
            updown: true,
            number: true,
            label: false,
          },
          labelPlacement: "none",
          width: "200px",
          type: "number",
          endAdornment: "AFUE",
          fontSize: "1.5rem",
          labelFontSize: "1.5rem",
        },
      };
    },
  },
  // 43''
  {
    key: "HeatingEquipmentRatedHSPFEfficiencyStep",
    progress: progress.yourSystem,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    canSkip: true,
    skipPlacement: "form",
    skipText: "I don't know",
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.heatingEquipmentType?.length) return false;
      if (!isHSPFRated({ payload })) return false;
      return true;
    },
    onLoad: ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      return parseCleanValues({ payload, slideState, theme });
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["heatingEquipmentHSPFRating"],
        properties: {
          heatingEquipmentHSPFRating: {
            ...props.heatingEquipmentHSPFRating,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        heatingEquipmentHSPFRating: {
          ...props.heatingEquipmentHSPFRating,
          "ui:options": {
            updown: true,
            number: true,
            label: false,
          },
          labelPlacement: "none",
          width: "200px",
          type: "number",
          endAdornment: "HSPF",
          fontSize: "1.5rem",
          labelFontSize: "1.5rem",
        },
      };
    },
  },
  // 44
  {
    key: "HeatingEquipmentRatedCapacityStep",
    progress: progress.yourSystem,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    canSkip: true,
    skipPlacement: "form",
    skipText: "I don't know",
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!isBTURated({ payload })) return false;
      return true;
    },
    onLoad: ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      return parseCleanValues({ payload, slideState, theme });
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["heatingEquipmentBTUHrRating"],
        properties: {
          heatingEquipmentBTUHrRating: {
            ...props.heatingEquipmentBTUHrRating,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        heatingEquipmentBTUHrRating: {
          ...props.heatingEquipmentBTUHrRating,
          "ui:options": {
            updown: true,
            number: true,
            label: false,
          },
          labelPlacement: "none",
          width: "200px",
          type: "number",
          endAdornment: "BTU/hr",
          fontSize: "1.5rem",
          labelFontSize: "1.5rem",
        },
      };
    },
  },
  // 45
  {
    key: "CoolingEquipmentAgeStep",
    progress: progress.yourSystem,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.coolingEquipmentType?.length) return false;
      return true;
    },
    onLoad: ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      return parseCleanValues({ payload, slideState, theme });
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["coolingEquipmentTypeAge"],
        properties: {
          coolingEquipmentTypeAge: {
            ...props.coolingEquipmentTypeAge,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      coolingEquipmentTypeAge: {
        ...props.coolingEquipmentTypeAge,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 46
  {
    key: "CoolingEquipmentRatedEfficiencyStep",
    progress: progress.yourSystem,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    canSkip: true,
    skipPlacement: "form",
    skipText: "I don't know",
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!isSEERRated({ payload })) return false;
      return true;
    },
    onLoad: ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      return parseCleanValues({ payload, slideState, theme });
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["coolingEquipmentSEERRating"],
        properties: {
          coolingEquipmentSEERRating: {
            ...props.coolingEquipmentSEERRating,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        coolingEquipmentSEERRating: {
          ...props.coolingEquipmentSEERRating,
          "ui:options": {
            updown: true,
            number: true,
            label: false,
          },
          labelPlacement: "none",
          width: "200px",
          type: "number",
          endAdornment: "SEER",
          fontSize: "1.5rem",
          labelFontSize: "1.5rem",
        },
      };
    },
  },
  // 47
  {
    key: "CoolingEquipmentRatedCapacityStep",
    progress: progress.yourSystem,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    canSkip: true,
    skipPlacement: "form",
    skipText: "I don't know",
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!isTonRated({ payload })) return false;
      return true;
    },
    onLoad: ({
      payload,
      slideState,
      theme,
    }: {
      payload: any;
      slideState: any;
      theme: Theme;
    }) => {
      return parseCleanValues({ payload, slideState, theme });
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["coolingEquipmentTonRating"],
        properties: {
          coolingEquipmentTonRating: {
            ...props.coolingEquipmentTonRating,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        coolingEquipmentTonRating: {
          ...props.coolingEquipmentTonRating,
          "ui:options": {
            updown: true,
            number: true,
            label: false,
          },
          labelPlacement: "none",
          width: "200px",
          type: "number",
          endAdornment: "tons",
          fontSize: "1.5rem",
          labelFontSize: "1.5rem",
        },
      };
    },
  },
  // 48
  {
    key: "SystemShortCyclesStep",
    progress: progress.yourSystem,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (
        !payload.heatingEquipmentType?.length &&
        !payload.coolingEquipmentType?.length
      )
        return false;
      if (!isDucted({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["shortCycles"],
        properties: {
          shortCycles: {
            ...props.shortCycles,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      shortCycles: {
        ...props.shortCycles,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 49
  {
    key: "NoisyDuctworkStep",
    progress: progress.yourSystem,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (
        !payload.heatingEquipmentType?.length &&
        !payload.coolingEquipmentType?.length
      )
        return false;
      if (!isDucted({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["noisyDuctwork"],
        properties: {
          noisyDuctwork: {
            ...props.noisyDuctwork,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        noisyDuctwork: {
          ...props.noisyDuctwork,
          ...sharedUIConfig.radioImages,
        },
      };
    },
  },
  // 50
  {
    key: "TemperatureStep",
    progress: progress.yourPreferences,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["temperatureSummer", "temperatureWinter"],
        properties: {
          temperatureWinter: {
            ...props.temperatureWinter,
            title: "Winter",
          },
          temperatureSummer: {
            ...props.temperatureSummer,
            title: "Summer",
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        "ui:grouped": true,
        groupDirection: "row",
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        mb: 1,
        pr: 2,
        backgroundColor: "transparent",
        maxWidth: 400,
        temperatureSummer: {
          "ui:autocomplete": "off",
          "ui:options": {
            updown: true,
            number: true,
            label: false,
          },
          // xs: 4,
          width: "160px",
          type: "number",
          endAdornment: "°F",
          fontSize: "1.5rem",
          labelFontSize: "1.5rem",
        },
        temperatureWinter: {
          "ui:autocomplete": "off",
          // xs: 4,
          width: "160px",
          type: "number",
          endAdornment: "°F",
          fontSize: "1.5rem",
          labelFontSize: "1.5rem",
        },
      };
    },
  },
  // 51
  {
    key: "HeatingEquipmentUsageStep",
    progress: progress.yourPreferences,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.heatingEquipmentType?.length) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["heatingEquipmentUsage"],
        properties: {
          heatingEquipmentUsage: {
            ...props.heatingEquipmentUsage,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      heatingEquipmentUsage: {
        ...props.heatingEquipmentUsage,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 52
  {
    key: "CoolingEquipmentUsageStep",
    progress: progress.yourPreferences,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      if (!payload.coolingEquipmentType?.length) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["coolingEquipmentUsage"],
        properties: {
          coolingEquipmentUsage: {
            ...props.coolingEquipmentUsage,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      coolingEquipmentUsage: {
        ...props.coolingEquipmentUsage,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  // 53 is being intentionally skipped for now
  // 54
  {
    key: "ProjectTimelineStep",
    progress: progress.yourPreferences,
    config: { footerContent: { type: "advisor" } },
    Title,
    Description,
    Sidebar,
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    fetchOnLoad: fetchQuickEstimate,
    schema: () => {
      return {
        ...baseSchema,
        required: ["projectTimeline"],
        properties: {
          projectTimeline: {
            ...props.projectTimeline,
          },
        },
      };
    },
    uiSchema: () => ({
      ...baseUISchema,
      projectTimeline: {
        ...props.projectTimeline,
        ...sharedUIConfig.radioImages,
      },
    }),
  },
  {
    key: "InstantEstimateStep",
    progress: progress.instantEstimate,
    Title,
    Description,
    Footer: ({ theme }: { theme: Theme }) => {
      const reminderText = theme.t("step.InstantEstimateStep.reminderText");
      if (reminderText) {
        return (
          <Stack
            sx={{
              border: `1px solid ${theme.palette.common.white}`,
              borderRadius: 5,
              padding: 2,
              m: 2,
              mt: 5,
            }}
            direction="row"
            justifyContent="center"
          >
            <Stack>
              {" "}
              <Box
                sx={{
                  width: 50,
                  height: 50,
                  marginRight: theme.spacing(2),
                  flexShrink: 0,
                  "& svg": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <Checklist />
              </Box>
            </Stack>
            <Stack>
              <Typography>
                {" "}
                <Box
                  sx={{
                    fontWeight: 600,
                    display: "inline",
                    textDecoration: "underline",
                    textTransform: "uppercase",
                  }}
                >
                  Reminder:
                </Box>{" "}
                {reminderText}
              </Typography>
            </Stack>
          </Stack>
        );
      }
      return null;
    },
    navigationPlacement: "form",
    styles: ({ theme }: { theme: Theme }) => {
      return {
        backgroundColor: theme.palette.primary.main,
      };
    },
    sx: ({ theme }: { theme: Theme }) => {
      return {
        backgroundColor: theme.palette.primary.main,
        ".MuiTypography-root": {
          color: `${theme.palette.primary.contrastText}`,
        },
        ".MuiButtonBase-root": {
          color: `${theme.palette.primary.contrastText}`,
        },
        ".MuiButton-containedPrimary": {
          backgroundColor: `${theme.palette.secondary.main}`,
        },
      };
    },
    visible: ({ payload }: { payload: any }) => {
      if (!isInstantEstimateWorkflow({ payload })) return false;
      if (!isFullQuoteWorkflow({ payload })) return false;
      return true;
    },
    schema: () => {
      return {};
    },
    uiSchema: () => {
      return {};
    },
  },
];

export default instantEstimateWorkflowSlides;
