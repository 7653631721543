import React, { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Toolbar, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Sherpa from "@library/components/Sherpa";
import { SlideSchema } from "@library/components/Sherpa/functions";
import api from "@library/api";
import { SettingsContext } from "@library/settings/provider";

import { initialOnboardingPayload } from "../../domain/onboarding";
import sortingWorkflowSlides from "./workflows/sorting";
import rebateOnlyWorkflowSlides from "./workflows/rebateOnly";
import instantEstimateWorkflowSlides from "./workflows/instantEstimate";
import OnboardingNavigation from "./components/OnboardingNavigation";

const slides = [
  ...sortingWorkflowSlides,
  ...instantEstimateWorkflowSlides,
  ...rebateOnlyWorkflowSlides,
] as unknown as SlideSchema[];

const OnboardingV3PageView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const storageKey = "onboarding";
  const onboardingId = localStorage.getItem("onboardingId");
  const { user, isAuthenticated, isLoading, channelId } =
    useContext(SettingsContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // @TODO we need to clean all this up
  const homes = user.Home ?? [];
  const home = homes[0];
  const jobs = home?.Job ?? [];
  const job = jobs[0];
  const quotes = job?.Quote ?? [];
  const quote = quotes[0];
  const onboarding = quote?.Onboarding ?? {};

  // localStorage
  const localCache = JSON.parse(localStorage.getItem(storageKey) || "{}");
  const localPayload = localCache.payload;
  const localSlideKey =
    localCache.slideKey ?? theme.config?.onboarding?.default?.slideKey;
  const localSlideIndex =
    slides.findIndex((s) => s.key === localSlideKey) || localCache.slideIndex;

  const _initialPayload = {
    ...initialOnboardingPayload,
    ...(theme.config?.onboarding?.default?.payload || {}),
    ...localPayload,
  };
  _initialPayload.user.channelId =
    onboarding?.data?.user.channelId &&
    onboarding?.data?.user.channelId !== "pearl"
      ? onboarding?.data?.user.channelId
      : channelId;
  const [initialPayload, setInitialPayload] = useState(_initialPayload);
  const [initialSlideIndex, setInitialSlideIndex] = useState(localSlideIndex);

  useEffect(() => {
    if (isAuthenticated) {
      const databaseInitialPayload = {
        ...initialPayload,
        ...(onboarding?.data || {}),
      };
      databaseInitialPayload.user.channelId =
        onboarding?.data?.user.channelId &&
        onboarding?.data?.user.channelId !== "pearl"
          ? onboarding?.data?.user.channelId
          : channelId;
      setInitialPayload(databaseInitialPayload);
      localStorage.setItem("onboardingId", onboarding.id);
      localStorage.setItem(
        "onboarding",
        JSON.stringify(
          {
            payload: databaseInitialPayload,
            slideIndex: onboarding?.step?.activeStep || 0,
            slideKey: onboarding?.step?.activeStepKey || undefined,
          },
          null,
          4
        )
      );
      if (onboarding?.step?.activeStepKey) {
        const slideIndex = slides.findIndex(
          (s) => s.key === onboarding.step.activeStepKey
        );
        setInitialSlideIndex(slideIndex);
      }
    } else if (onboardingId && !isLoading && !isAuthenticated) {
      api.get(`onboarding/partial/${onboardingId}`).then((response) => {
        setInitialPayload({
          ...initialPayload,
          ...(response?.data?.data || {}),
        });
        if (response?.data?.step?.activeStepKey) {
          const slideIndex = slides.findIndex(
            (s) => s.key === response.data.step.activeStepKey
          );
          setInitialSlideIndex(slideIndex);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onboardingStatus = onboarding?.status || "";

  useEffect(() => {
    if (onboarding?.status === "COMPLETED") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStatus]);

  return (
    <>
      {isSubmitting ? (
        <Box component={"main"}>
          <Toolbar />{" "}
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Sherpa
          slides={slides}
          data={{}}
          initialSlideIndex={initialSlideIndex > -1 ? initialSlideIndex : 0}
          initialPayload={initialPayload}
          Navigation={OnboardingNavigation}
          navigationPlacement="footer"
          onProgress={({ payload, slideIndex, slideKey }) => {
            const onboardingId = localStorage.getItem("onboardingId");
            if (!isAuthenticated && onboardingId) {
              return api.put(`onboarding/partial/${onboardingId}`, {
                payload,
                slideIndex,
                slideKey,
              });
            }
            if (isAuthenticated && (onboarding.id || onboardingId)) {
              return api.put(`onboarding/${onboarding.id || onboardingId}`, {
                payload,
                activeStep: slideIndex,
                activeStepKey: slideKey,
              });
            }
            // @TODO route to save a claimed onboarding
            return Promise.resolve();
          }}
          onSubmit={async ({ payload, slideIndex, slideKey, slide }) => {
            if (slide.noSubmit) return false;
            const onboardingId =
              onboarding.id || localStorage.getItem("onboardingId");
            if (isAuthenticated && onboardingId) {
              setIsSubmitting(true);
              try {
                await api.put(`onboarding/${onboardingId}`, {
                  payload,
                  activeStep: slideIndex,
                  activeStepKey: slideKey,
                });
                const response = await api.post(`onboarding/${onboarding.id}`, {
                  tiers: theme.config?.tiers?.available ?? ["base", "pearl"],
                });
                const { data: { jobId, quoteId } = {} } = response;
                localStorage.removeItem("onboardingId");
                localStorage.removeItem("onboarding");
                navigate(`/job/${jobId}/${quoteId}`);
              } catch (e) {
                setIsSubmitting(false);
                // @TODO set error
              }
            }
          }}
          storageKey={storageKey}
        />
      )}
    </>
  );
};

export default OnboardingV3PageView;
