import React, { useMemo } from "react";
import {
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";
import ImpactChart from "../ImpactChart";
import {
  weatherOptions,
  // energyUsageOptions, // FYI: not messin' with usage for now
  energyPricesOptions,
} from "../../constants";
import { formatNumber, processGraphData } from "@library/common";

const LIFETIME = 15;

const baseLanguageLocation = "concierge.PackageDetails.EnergyImpactContent";

export const EnergyImpactContent: React.FC<SectionContentProps> = ({
  theme,
  graphData,
  graphDataOptions,
  onGraphOptionChange,
}) => {
  const processedData = useMemo(
    // FYI: this returns an empty arr for now while
    // energy impact data gets some lovin'
    () => processGraphData(graphData, "footprint"),
    [graphData]
  );

  const totalEnergyChange = processedData.reduce(
    (sum, month) => sum + month.savings,
    0
  );

  const handleWeatherChange = (value: string) => {
    onGraphOptionChange?.({ weatherSeverity: value });
  };

  const handleEnergyPricesChange = (value: string) => {
    onGraphOptionChange?.({ energyPrices: value });
  };

  return (
    <Root>
      <Box>
        <Typography
          className={classes.sectionContentDescription}
          sx={{ mb: 2 }}
        >
          {theme?.t(`${baseLanguageLocation}.energyIntro`, {
            direction: totalEnergyChange > 0 ? "increase" : "reduce",
          })}{" "}
          <strong>
            {formatNumber(totalEnergyChange, {
              showNegative: false,
            })}
          </strong>{" "}
          {theme?.t(`${baseLanguageLocation}.energyMiddle`)}{" "}
          <strong>
            {formatNumber(totalEnergyChange * LIFETIME, {
              showNegative: false,
            })}
          </strong>{" "}
          {theme?.t(`${baseLanguageLocation}.energyEnd`)}
        </Typography>
        {/* Dropdowns container */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>
                {theme?.t(`${baseLanguageLocation}.weather`)}
              </InputLabel>
              <Select
                value={graphDataOptions?.weatherSeverity || "Average"}
                label={theme?.t(`${baseLanguageLocation}.weather`)}
                onChange={(e) => handleWeatherChange(e.target.value)}
              >
                {weatherOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* FYI: not messin' with usage for now */}
          {/* <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>My Energy Usage</InputLabel>
              <Select
                value={energyUsage}
                label="My Energy Usage"
                onChange={(e) => setEnergyUsage(e.target.value)}
              >
                {energyUsageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>
                {theme?.t(`${baseLanguageLocation}.energyPrices`)}
              </InputLabel>
              <Select
                value={graphDataOptions?.energyPrices || "Status Quo"}
                label={theme?.t(`${baseLanguageLocation}.energyPrices`)}
                onChange={(e) => handleEnergyPricesChange(e.target.value)}
              >
                {energyPricesOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ImpactChart
              displayType="energy"
              data={processedData || []}
              contrastColor={theme?.palette.primary.light}
            />
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
