/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Slide,
  Stack,
  useTheme,
  SxProps,
  Theme as MaterialTheme,
} from "@mui/material";
import isFunction from "lodash/isFunction";
import merge from "lodash/merge";
import queryString from "query-string";
import { withTheme } from "@rjsf/core";
import { Theme } from "@rjsf/mui";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useLocation, useSearchParams } from "react-router-dom";

import widgets from "@library/components/form/widgets";
import fields from "@library/components/form/fields";
import { SettingsContext } from "@library/settings/provider";
import FieldErrorTemplate from "@library/components/form/templates/FieldErrorTemplate";
import ErrorListTemplate from "@library/components/form/templates/ErrorListTemplate";

import {
  getNextSlide,
  getPreviousSlide,
  getSpecificSlide,
  getSlide,
  SlideSchema,
} from "./functions";
import SkipModal from "./components/SkipModal";
import Progress from "./components/Progress";

const schema = (): RJSFSchema => {
  return {
    type: "string",
  };
};

const uiSchema = (): UiSchema => {
  return {
    "ui:autocomplete": "off",
  };
};

interface SherpaContext {
  payload: Partial<any>;
  setPayload: (value: Partial<any>) => void;
  slideIndex: number;
  setSlideIndex: (value: number) => void;
}

const SherpaContext = createContext<SherpaContext>({
  payload: {},
  setPayload: () => {},
  slideIndex: 0,
  setSlideIndex: () => {},
});

const NoComponent = () => {
  return <></>;
};

export const Sherpa = ({
  slides,
  initialPayload = {},
  initialSlideIndex = 0,
  data = {},
  onProgress = () => {
    return Promise.resolve();
  },
  onSubmit = () => {
    return Promise.resolve();
  },
  storageKey,
  readonly = false,
  progressSx,
  Navigation,
  asyncOnLoad,
  asyncOnNext,
  navigationPlacement: _navigationPlacement,
  sx: _sx,
}: {
  slides: SlideSchema[];
  initialPayload?: any;
  initialSlideIndex?: number;
  data?: any;
  onProgress: ({
    payload,
    slideIndex,
    slideKey,
  }: {
    payload: any;
    slideIndex?: number;
    slideKey?: string;
  }) => Promise<any>;
  storageKey?: string;
  onSubmit: ({
    payload,
    slideIndex,
    slideKey,
    slide,
  }: {
    payload: any;
    slideIndex?: number;
    slideKey?: string;
    slide: SlideSchema;
  }) => Promise<any>;
  readonly?: boolean;
  progressSx?: any;
  Navigation?: React.FC<{
    theme: any;
    payload: any;
    slideState: any;
    slideIndex: number;
    slide: SlideSchema;
    nextSlideIndex: number;
    setSlideDirection: (direction: number) => void;
    formRef: any;
    setSkipModalOpen: (open: boolean) => void;
  }>;
  asyncOnLoad?: boolean;
  asyncOnNext?: boolean;
  navigationPlacement?: string;
  sx?: ({ theme }: { theme: MaterialTheme }) => SxProps;
}) => {
  const theme = useTheme();
  const { user, setUser, setIsAuthenticated } = useContext(SettingsContext);
  const [isLoading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [show, setShow] = useState(true);
  const [payload, setPayload] = useState<any>({
    ...initialPayload,
  });

  const [skipModalOpen, _setSkipModalOpen] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(initialSlideIndex ?? 0);
  const [nextSlideIndex, setNextSlideIndex] = useState<number>(slideIndex);
  const [slideDirection, setSlideDirection] = useState<number>(0);
  const [slide, setSlide] = useState<SlideSchema>({
    key: "",
    schema,
    uiSchema,
  });

  const [slideState, setSlideState] = useState<Record<string, any>>({}); // Useful current slide state
  const [error, setError] = useState<string | ReactElement>("");
  const { search } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchParams, setSearchParams] = useSearchParams();

  const Form = withTheme(Theme);
  const formRef = useRef(null);

  const Header = slide.Header ?? NoComponent;
  const Title = slide.Title ?? NoComponent;
  const Description = slide.Description ?? NoComponent;
  const Footer = slide.Footer ?? NoComponent;
  const Callout = slide.Callout ?? NoComponent;
  const Sidebar = slide.Sidebar ?? NoComponent;
  const fetchOnLoad = slide.fetchOnLoad;
  const isAsyncOnLoad = asyncOnLoad ?? slide.asyncOnLoad ?? true;
  const isAsyncOnNext = asyncOnNext ?? slide.asyncOnNext ?? true;
  const emptySx = () => {
    return {};
  };
  const sxFunc = slide?.sx ?? _sx ?? emptySx;
  const themeSx = theme.config?.sx?.step?.[slide?.key] || {};
  const sx = merge(
    {
      ...sxFunc({ theme }),
    },
    themeSx
  );

  const onLoad = slide.onLoad;
  const navigationPlacement =
    slide.navigationPlacement ?? _navigationPlacement ?? "form";

  const handlePageChange = (_slideIndex: number) => {
    setSlideIndex(_slideIndex);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (fetchOnLoad) {
      setIsFetching(true);
      fetchOnLoad({ slideState, payload, theme }).then((newSlideState) => {
        setSlideState({
          ...slideState,
          ...newSlideState,
        });
        setIsFetching(false);
      });
    } else {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnLoad]);

  useEffect(() => {
    setPayload(initialPayload);
  }, [initialPayload]);

  useEffect(() => {
    if (onLoad) {
      const resp: Record<string, any> = onLoad({
        slideState,
        payload,
        theme,
        setPayload,
      });
      let newSlideState = resp;
      if (resp.slideState || resp.payload) {
        newSlideState = resp.slideState || slideState;
        if (resp.payload) {
          const newPayload = resp.payload || payload;
          setPayload(newPayload);
        }
      }
      setSlideState({
        ...slideState,
        ...newSlideState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad, initialPayload, isFetching]);

  useEffect(() => {
    if (initialSlideIndex && initialSlideIndex >= slideIndex) {
      setSlideIndex(initialSlideIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSlideIndex]);

  useEffect(() => {
    getSlide({ slides, slideIndex }).then((nextSlide) => {
      setSlide(nextSlide);
    });
    getNextSlide({
      slides,
      index: slideIndex,
      payload,
      setPayload: () => {},
      setError: () => {},
      user,
      setUser: () => {},
      setIsAuthenticated: () => {},
      executeOnNext: false,
      setLoading: () => {},
      theme,
      readonly,
    }).then((_nextSlideIndex) => {
      if (_nextSlideIndex !== slideIndex) {
        setError("");
      }
      setNextSlideIndex(_nextSlideIndex);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideIndex]);

  useEffect(() => {
    const qs = queryString.parse(location.search) ?? {};
    if (qs.s) {
      const slideIndexParam = parseInt(String(qs.s), 10);
      if (slideIndexParam > -1) {
        handlePageChange(slideIndexParam);
        if (readonly) return;
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
        setSearchParams({ s: "" });
      }
    }
  }, [slideIndex, search, setSearchParams, readonly]);

  const submitForm = useCallback(() => {
    if (slide.noSubmit) return;
    setLoading(true);
    const slideKey = slides[slideIndex].key;
    if (storageKey) {
      localStorage.setItem(
        storageKey,
        JSON.stringify({ payload, slideIndex, slideKey }, null, 4)
      );
    }
    onSubmit({
      payload,
      slideIndex,
      slideKey,
      slide,
    })
      .then(() => {
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [onSubmit, payload, slides, slideIndex, storageKey, slide]);

  const submitProgress = ({
    payload,
    slideIndex,
    slideKey,
  }: {
    payload: any;
    slideIndex: number;
    slideKey: string;
  }) => {
    if (storageKey) {
      localStorage.setItem(
        storageKey,
        JSON.stringify({ payload, slideIndex, slideKey }, null, 4)
      );
    }
    if (onProgress) {
      onProgress({
        payload,
        slideIndex,
        slideKey,
      }).catch((e) => {
        console.warn(e);
      });
    }
  };

  useEffect(() => {
    if (isFunction(slide.visible)) {
      const isVisible = slide.visible?.({ payload, theme });
      if (!isVisible && nextSlideIndex === -1) {
        setLoading(true);
        submitForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSlideIndex]);

  useEffect(() => {
    if (slideDirection !== 0) {
      setShow(false);
    }
  }, [slideDirection]);

  useEffect(() => {
    if (show) return;

    setTimeout(() => {
      if (slideDirection === 1) {
        getNextSlide({
          slides,
          index: slideIndex,
          payload,
          setPayload,
          setError,
          user,
          setUser,
          setIsAuthenticated,
          executeOnNext: true,
          setLoading,
          theme,
          readonly,
        }).then(async (newSlideIndex) => {
          if (newSlideIndex !== slideIndex) {
            setError("");
            if (newSlideIndex === -1) {
              submitForm();
            } else {
              submitProgress({
                payload,
                slideIndex: newSlideIndex,
                slideKey: slides[newSlideIndex].key,
              });
              setSlideIndex(newSlideIndex);
              window.scrollTo(0, 0);
            }
          }
        });
      }
      if (slideDirection === -1) {
        getPreviousSlide({
          slides,
          index: slideIndex,
          payload,
          theme,
        }).then(async (newSlideIndex) => {
          setError("");
          submitProgress({
            payload,
            slideIndex: newSlideIndex,
            slideKey: slides[newSlideIndex].key,
          });
          setSlideIndex(newSlideIndex);
          window.scrollTo(0, 0);
        });
      }
      setShow(true);
      setTimeout(() => {
        setSlideDirection(0);
      }, 200);
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideDirection, slideIndex, show, payload]);

  const handleSkip = async (toSkip: boolean) => {
    setSkipModalOpen(false);
    if (toSkip) {
      if (slide.onSkip) {
        const {
          error: skipError,
          slideKey: newSlideKey,
          payload: nextPayload,
        } = (await slide.onSkip({
          index: slideIndex,
          payload,
          setPayload,
          user,
          setUser,
          setIsAuthenticated,
          readonly,
        })) || {};
        if (nextPayload) {
          setPayload(nextPayload);
        }
        if (skipError) {
          setError(skipError);
        } else {
          if (newSlideKey) {
            const newSlideIndex =
              slides.findIndex((s) => s.key === newSlideKey) || slideIndex;
            const newSlide = await getSlide({
              slides,
              slideIndex: newSlideIndex,
              slideKey: newSlideKey,
            });
            setSlide(newSlide);
            setSlideIndex(newSlideIndex);
            submitProgress({
              payload: nextPayload || payload,
              slideIndex: newSlideIndex,
              slideKey: newSlideKey,
            });
          } else {
            setSlideDirection(1);
          }
        }
      } else {
        setSlideDirection(1);
      }
    }
  };

  const setSkipModalOpen = (value: boolean = true) => {
    if (slide.showSkipModal === false) {
      handleSkip(true);
    } else {
      _setSkipModalOpen(value);
    }
  };

  const background = (slide.background && slide.background(theme)) || "none";

  let direction = "left" as keyof { left: true; right: true };
  if (show) {
    if (slideDirection === -1) {
      direction = "right";
    } else if (slideDirection === 1) {
      direction = "left";
    }
  } else {
    if (slideDirection === -1) {
      direction = "left";
    } else if (slideDirection === 1) {
      direction = "right";
    }
  }

  const __schema = slide.schema
    ? slide.schema({ payload, slideState, theme }) || {}
    : {};
  const hasFormElements = Object.keys(__schema).length;

  return (
    <SherpaContext.Provider
      value={{ payload, setPayload, slideIndex, setSlideIndex }}
    >
      <Stack sx={{ ...sx }}>
        <Stack style={{ overflow: "hidden " }} sx={{ mb: 2 }}>
          {/* Loading overlay */}
          {isLoading ||
          (isFetching && !isAsyncOnLoad) ||
          (isFetching && !isAsyncOnNext) ? (
            <Box
              sx={{
                zIndex: 9999,
                position: "fixed",
                inset: 0,
                top: 64,
                background: `rgba(255,255,255,${nextSlideIndex === -1 || !isAsyncOnLoad ? 1.0 : 0.4})`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
          {/* Progress bar */}
          {!readonly && slide.progress !== false && (
            <Progress
              slides={slides}
              slide={slide}
              slideIndex={slideIndex}
              onChange={handlePageChange}
              sx={progressSx}
            />
          )}

          {/* Main content */}
          <Slide
            in={Boolean(show && direction.length)}
            direction={direction}
            easing={{
              enter:
                slide.animation !== false
                  ? theme.transitions.easing.easeOut
                  : "0.0s",
              exit:
                slide.animation !== false
                  ? theme.transitions.easing.sharp
                  : "0.0s",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Box
                sx={{
                  background,
                  minHeight: `calc(100vh - ${slide.progress !== false ? 128 : 64}px)`,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center", // Add this
                  px: 3, // Add padding to prevent content from touching edges
                }}
              >
                {/* Title, Description, Header */}
                <Box>
                  {Title && (
                    <Title
                      stepName={slide.key}
                      slideIndex={slideIndex}
                      payload={payload}
                      setPayload={setPayload}
                      slideState={slideState}
                      data={data}
                      formRef={formRef}
                    />
                  )}
                  {Description && (
                    <Description
                      stepName={slide.key}
                      slideIndex={slideIndex}
                      payload={payload}
                      setPayload={setPayload}
                      slideState={slideState}
                      data={data}
                      formRef={formRef}
                    />
                  )}
                  {Header && (
                    <Header
                      stepName={slide.key}
                      slideIndex={slideIndex}
                      payload={payload}
                      setPayload={setPayload}
                      slideState={slideState}
                      data={data}
                      formRef={formRef}
                      setSlideDirection={setSlideDirection}
                    />
                  )}
                </Box>

                {/* Form */}
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 800,
                    margin: "0 auto",
                    mt: 2,
                    pb: hasFormElements ? 10 : 0, // Ensure scrollability with footer
                  }}
                >
                  <Form
                    ref={formRef}
                    schema={{
                      ...slide.schema({ payload, slideState, theme }),
                    }}
                    uiSchema={{
                      ...uiSchema,
                      ...slide.uiSchema({
                        payload,
                        slideState,
                        theme,
                        formRef,
                      }),
                    }}
                    fields={fields}
                    widgets={widgets}
                    formData={payload}
                    validator={validator}
                    formContext={{
                      setPayload,
                      payload,
                      setSlideDirection,
                      slideState,
                      setSlideState,
                      slideIndex,
                      setError,
                    }}
                    onSubmit={({ formData }) => {
                      if (slide.customSubmit) {
                        return;
                      }
                      setPayload(formData);
                      setSlideDirection(1);
                      setError("");
                    }}
                    templates={{
                      ErrorListTemplate,
                      FieldErrorTemplate,
                    }}
                    transformErrors={(errors) => {
                      return errors.map((error) => {
                        if (error.name === "required") {
                          // error.message = <Alert severity="error" sx={{ width: "100%" }}>Required</Alert>;
                          error.message = "Required";
                        }
                        return error;
                      });
                    }}
                  >
                    {Callout && (
                      <Callout
                        stepName={slide.key}
                        slideIndex={slideIndex}
                        payload={payload}
                        setPayload={setPayload}
                        slideState={slideState}
                        data={data}
                        formRef={formRef}
                      />
                    )}
                    {error && (
                      <Stack
                        spacing={2}
                        m={2}
                        direction="row"
                        justifyContent="center"
                      >
                        <Alert sx={{ margin: 2 }} severity="error">
                          {error}
                        </Alert>
                      </Stack>
                    )}
                    {slide.canSkip && slide.skipPlacement === "form" && (
                      <Stack justifyContent="center" sx={{ m: 1 }}>
                        <Button
                          variant="text"
                          onClick={() => {
                            setSkipModalOpen(true);
                          }}
                          color="secondary"
                          sx={{ textTransform: "none" }}
                        >
                          {slide.skipTitle ||
                            slide.skipText ||
                            "Skip this step"}
                        </Button>
                      </Stack>
                    )}
                  </Form>
                </Box>

                {/* Form navigation */}
                {navigationPlacement === "form" && slide?.Navigation ? (
                  <slide.Navigation
                    theme={theme}
                    payload={payload}
                    slideState={slideState}
                    slideIndex={slideIndex}
                    slide={slide}
                    nextSlideIndex={nextSlideIndex}
                    setSlideDirection={setSlideDirection}
                    formRef={formRef}
                    setSkipModalOpen={setSkipModalOpen}
                  />
                ) : navigationPlacement === "form" && Navigation ? (
                  <Navigation
                    theme={theme}
                    payload={payload}
                    slideState={slideState}
                    slideIndex={slideIndex}
                    slide={slide}
                    nextSlideIndex={nextSlideIndex}
                    setSlideDirection={setSlideDirection}
                    formRef={formRef}
                    setSkipModalOpen={setSkipModalOpen}
                  />
                ) : navigationPlacement === "form" ? (
                  <Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      mt={2}
                      mr={2}
                      justifyContent="flex-end"
                      alignItems="center"
                      className="Navigation"
                    >
                      <Box>
                        {slideIndex > 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                              setSlideDirection(-1);
                            }}
                            sx={{
                              px: 2,
                              mx: 1,
                              height: 50,
                              fontSize: 18,
                              minWidth: "100px",
                              color: slide.dark
                                ? theme.palette.primary.contrastText
                                : undefined,
                              borderColor: slide.dark
                                ? "transparent"
                                : undefined,
                              backgroundColor: slide.dark
                                ? "rgba(255,255,255,.2)"
                                : undefined,
                            }}
                          >
                            {slide.backTitle ? slide.backTitle : "Back"}
                          </Button>
                        )}
                        {slide.canSkip && slide.skipPlacement !== "form" && (
                          <Button
                            variant="contained"
                            onClick={() => setSkipModalOpen(true)}
                            color="secondary"
                            sx={{
                              textTransform: "none",
                              height: 50,
                              fontSize: 18,
                              px: 2,
                              minWidth: "100px",
                            }}
                          >
                            {slide.skipTitle ||
                              slide.skipText ||
                              "Skip this step"}
                          </Button>
                        )}
                        {slide.nextTitle !== "" && slide.noSubmit !== true && (
                          <Button
                            variant="contained"
                            type="submit"
                            data-cy="submit"
                            data-key={slide.key}
                            color="primary"
                            sx={{
                              height: 50,
                              fontSize: 18,
                              px: 2,
                              mx: 1,
                              minWidth: "100px",
                            }}
                            onClick={() => {
                              const form = formRef.current || {
                                submit: () => {},
                              };
                              if (form && form.submit) form.submit();
                            }}
                          >
                            {nextSlideIndex !== -1
                              ? slide.nextTitle && isFunction(slide.nextTitle)
                                ? slide.nextTitle({ theme })
                                : slide.nextTitle
                                  ? slide.nextTitle
                                  : "Next"
                              : "Next"}
                          </Button>
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                ) : null}
                {Footer && (
                  <Footer
                    theme={theme}
                    stepName={slide.key}
                    setPayload={setPayload}
                    formRef={formRef}
                    payload={payload}
                    slideIndex={slideIndex}
                    slideState={slideState}
                    setSlideState={setSlideState}
                    setError={setError}
                    error={error}
                    data={data}
                    setSlideDirection={setSlideDirection}
                    setSlideIndex={setSlideIndex}
                    getSpecificSlide={getSpecificSlide}
                  />
                )}
              </Box>
            </Box>
          </Slide>
        </Stack>

        {/* Sidebar */}
        {Sidebar && (
          <Box
            sx={{
              position: "absolute",
              right: 16,
              top: 80,
              // width: 400,
              zIndex: 2,
            }}
          >
            <Sidebar
              stepName={slide.key}
              slideIndex={slideIndex}
              payload={payload}
              setPayload={setPayload}
              slideState={slideState}
              data={data}
              formRef={formRef}
            />
          </Box>
        )}

        {/* Footer navigation */}
        {navigationPlacement === "footer" && slide?.Navigation ? (
          <slide.Navigation
            theme={theme}
            payload={payload}
            slideState={slideState}
            slideIndex={slideIndex}
            slide={slide}
            nextSlideIndex={nextSlideIndex}
            setSlideDirection={setSlideDirection}
            formRef={formRef}
            setSkipModalOpen={setSkipModalOpen}
          />
        ) : navigationPlacement === "footer" && Navigation ? (
          <Navigation
            theme={theme}
            payload={payload}
            slideState={slideState}
            slideIndex={slideIndex}
            slide={slide}
            nextSlideIndex={nextSlideIndex}
            setSlideDirection={setSlideDirection}
            formRef={formRef}
            setSkipModalOpen={setSkipModalOpen}
          />
        ) : navigationPlacement === "footer" ? (
          <Stack
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              boxShadow: "0px 0px 20px 0px #CCC",
              height: "75px",
              backgroundColor: theme.palette.primary.contrastText,
              zIndex: 2,
            }}
            className="Navigation"
          >
            <Stack
              spacing={2}
              direction="row"
              mt={2}
              mr={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box>
                {slideIndex > 0 && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setSlideDirection(-1);
                    }}
                    sx={{
                      px: 2,
                      mx: 1,
                      height: 50,
                      fontSize: 18,
                      minWidth: "100px",
                      color: slide.dark
                        ? theme.palette.primary.contrastText
                        : undefined,
                      borderColor: slide.dark ? "transparent" : undefined,
                      backgroundColor: slide.dark
                        ? "rgba(255,255,255,.2)"
                        : undefined,
                    }}
                  >
                    {slide.backTitle ? slide.backTitle : "Back"}
                  </Button>
                )}
                {slide.canSkip && slide.skipPlacement !== "form" && (
                  <Button
                    variant="contained"
                    onClick={() => setSkipModalOpen(true)}
                    color="secondary"
                    sx={{
                      textTransform: "none",
                      height: 50,
                      fontSize: 18,
                      px: 2,
                      minWidth: "100px",
                    }}
                  >
                    {slide.skipTitle || slide.skipText || "Skip this step"}
                  </Button>
                )}
                {slide.nextTitle !== "" && slide.noSubmit !== true && (
                  <Button
                    variant="contained"
                    type="submit"
                    data-cy="submit"
                    data-key={slide.key}
                    color="primary"
                    sx={{
                      height: 50,
                      fontSize: 18,
                      px: 2,
                      mx: 1,
                      minWidth: "100px",
                    }}
                    onClick={() => {
                      const form = formRef.current || { submit: () => {} };
                      if (form && form.submit) form.submit();
                    }}
                  >
                    {nextSlideIndex !== -1
                      ? slide.nextTitle && isFunction(slide.nextTitle)
                        ? slide.nextTitle({ theme })
                        : slide.nextTitle
                          ? slide.nextTitle
                          : "Next"
                      : "Next"}
                  </Button>
                )}
              </Box>
            </Stack>
          </Stack>
        ) : null}

        <SkipModal open={skipModalOpen} onClose={handleSkip} />
      </Stack>
    </SherpaContext.Provider>
  );
};

export default Sherpa;
