import { styled } from "@mui/material";

const PREFIX = "MeetYourAdvisorStep";

export const classes = {
  card: `${PREFIX}-card`,
  titleSection: `${PREFIX}-titleSection`,
  imageContainer: `${PREFIX}-imageContainer`,
  readMore: `${PREFIX}-readMore`,
  costSection: `${PREFIX}-costSection`,
  costRow: `${PREFIX}-costRow`,
  expandableContent: `${PREFIX}-expandableContent`,
  descriptionSection: `${PREFIX}-descriptionSection`,
  billImpactSection: `${PREFIX}-billImpactSection`,
  climateImpactSection: `${PREFIX}-climateImpactSection`,
  subtitle: `${PREFIX}-subtitle`,
  strikeThrough: `${PREFIX}-strikeThrough`,
  bottomBorder: `${PREFIX}-bottomBorder`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.card}`]: {
    borderRadius: 10,
    height: "100%",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: "none",
    width: "100%",
    boxSizing: "border-box",
    display: "grid",
    gridTemplateAreas: `
      "title"
      "image"
      "description"
      "costs"
      "billImpact"
      "climateImpact"
    `,
    gridTemplateRows: "auto 200px auto auto auto auto",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
  [`& .${classes.titleSection}`]: {
    gridArea: "title",
    "& h6": {
      lineHeight: 1,
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-body2": {
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.imageContainer}`]: {
    gridArea: "image",
    width: "100%",
    height: "200px",
    borderRadius: 4,
    overflow: "hidden",
    "& > div": {
      width: "100%",
      height: "100%",
    },
  },
  [`& .${classes.expandableContent}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    transition: "all 0.3s ease",
  },
  [`& .${classes.descriptionSection}`]: {
    gridArea: "description",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
    transition: "height 0.3s ease-in-out",
    minHeight: "100px", // Add minimum height to ensure content is visible
  },
  [`& .${classes.costSection}`]: {
    gridArea: "costs",
    backgroundColor: `${theme.palette.primary.main}15`,
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  [`& .${classes.billImpactSection}`]: {
    gridArea: "billImpact",
    backgroundColor: `${theme.palette.primary.main}15`,
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  [`& .${classes.climateImpactSection}`]: {
    gridArea: "climateImpact",
    backgroundColor: `${theme.palette.primary.main}15`,
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  [`& .${classes.readMore}`]: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    paddingTop: theme.spacing(1),
    marginTop: "auto", // Push to bottom of container
  },
  [`& .${classes.costRow}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
    "& .MuiTypography-subtitle2": {
      lineHeight: 1,
      fontWeight: 600,
    },
    "& .MuiTypography-caption": {
      lineHeight: 1,
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.subtitle}`]: {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
    marginTop: theme.spacing(0.5),
  },
  [`& .${classes.strikeThrough}`]: {
    textDecoration: "line-through",
  },
  [`& .${classes.bottomBorder}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .initial-content": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    minHeight: "100px", // Match minHeight of description section
  },
}));
