/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { classes, Root } from "./styles";

interface SwiperCarouselProps {
  items: any[];
  renderItem: (
    item: any,
    index: number,
    isSelected: boolean
  ) => React.ReactNode;
  selectedIndex?: number;
  onSelectItem?: (index: number) => void;
  subTitle?: string;
  selectable?: boolean;
}

const SwiperCarousel: React.FC<SwiperCarouselProps> = ({
  items,
  renderItem,
  selectedIndex,
  onSelectItem,
  subTitle,
  selectable = true,
}) => {
  const sliderRef = useRef<SwiperRef>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const slidesPerView = 3;

  const getVisibleRange = () => {
    const start = activeIndex + 1;
    const end = Math.min(activeIndex + slidesPerView, items.length);
    return `${start}-${end}`;
  };

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handleItemClick = (index: number) => {
    if (selectable && onSelectItem) {
      onSelectItem(index);
    }
  };

  return (
    <Root>
      <Box className={classes.swiperNavRowContainer}>
        <Typography variant="body2">{subTitle}</Typography>
        <Box className={classes.swiperNavContainer}>
          <Typography variant="body2">
            {getVisibleRange()} of {items.length}
          </Typography>
          <IconButton className={classes.swiperNavButton} onClick={handlePrev}>
            <ChevronLeft />
          </IconButton>
          <IconButton className={classes.swiperNavButton} onClick={handleNext}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <Swiper
          rewind={true}
          ref={sliderRef}
          modules={[A11y]}
          spaceBetween={15}
          slidesPerView={3}
          slidesPerGroup={1}
          centeredSlides={false}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 15,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
              slidesPerGroup: 1,
            },
          }}
        >
          {items.map((item, index) => (
            <SwiperSlide
              key={`item-${index}`}
              onClick={() => handleItemClick(index)}
              style={{ cursor: selectable ? "pointer" : "default" }}
            >
              {renderItem(
                item,
                index,
                selectable ? index === selectedIndex : false
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Root>
  );
};

export default SwiperCarousel;
