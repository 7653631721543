import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import { baseConfig } from "../config";
import { baseOverrides } from "../overrides";

import bg from "./home-background.jpg";
import logo from "./logo.png";

const FONT_LIGHT = 300;
const FONT_REGULAR = 400;
const FONT_NORMAL = 500;
const FONT_MEDIUM = 600;
const FONT_BOLD = 600;

const config = merge({}, cloneDeep(baseConfig), {
  name: "A²ZERO",
  language: {
    "en-us": {
      onboardingTitle:
        "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
      tier: {
        base: "Base",
        pearl: "Pearl",
        a2zero: "A²ZERO",
        a2zero_he: "A²ZERO High-Efficiency",
      },
      step: {
        LetsGetStartedStep: {
          title: "Welcome to A²ZERO Heat Pump Concierge!",
          description:
            "You could be eligible for up to [$x] in state, city, and utility rebates to electrify your home with efficient, electric space heating and cooling via heat pumps and weatherization.  Reserve funds today - it only takes 10 minutes.",
        },
        InstantEstimateStep: {
          reminderText:
            "Reserve your A²ZERO rebates by scheduling your home assessment.",
        },
      },
    },
  },
  tiers: {
    default: "a2zero",
    recommended: "a2zero",
    available: ["base", "pearl", "a2zero", "a2zero_he"],
  },
  rebates: {
    ami: {
      lower: "80",
      upper: "120",
    },
  },
  sx: {
    step: {
      LetsGetStartedStep: {
        backgroundColor: "primary.main",
        ".MuiTypography-root": {
          color: "primary.contrastText",
          textAlign: "left !important",
          paddingLeft: "0px !important",
        },
        ".MuiButtonBase-root": {
          color: "primary.contrastText",
        },
        ".MuiButton-outlinedPrimary": {
          borderColor: "#FFF",
        },
        ".MuiButton-containedPrimary": {
          backgroundColor: "secondary.main",
        },
        ".Navigation": {
          backgroundColor: "primary.light",
          boxShadow: "none !important",
        },
        ".LetsGetStartedStep-container": {
          alignItems: "flex-start",
        },
        ".LetsGetStartedStep-boxContainer": {
          backgroundColor: "#0b9a6d !important",
          svg: {
            path: {
              stroke: "#FFFFFF !important",
            },
          },
        },
        ".LetsGetStartedStep-buttonContainer": {
          px: "32px !important",
        },
      },
    },
  },
  onboarding: {
    default: {
      payload: {
        projectType: "heating_cooling",
        heatingCoolingProjectScope: "both",
        heatingCoolingProjectType: "replace",
        projectMotivation: "online_quote",
        wholeOrPartialHome: "whole",
      },
      slideKey: "LetsGetStartedStep",
    },
  },
});

const overrides = merge({}, cloneDeep(baseOverrides), {
  logo: {
    imageSrc: logo,
    alt: "A²ZERO Logo",
    width: 150,
    height: 30,
    poweredBy: true,
  },
  splash: {
    imageSrc: bg,
  },
  //shadows:[],
  typography: {
    fontFamily: "Fira Sans",
    fontWeightLight: FONT_LIGHT,
    fontWeightRegular: FONT_REGULAR,
    fontWeightNormal: FONT_NORMAL,
    fontWeightMedium: FONT_MEDIUM,
    fontWeightBold: FONT_BOLD,
    h1: {
      fontWeight: FONT_REGULAR,
      fontSize: "42px",
    },
    h2: {
      fontWeight: FONT_REGULAR,
      fontSize: "25px",
    },
    h3: {
      fontWeight: FONT_REGULAR,
      fontSize: "22px",
    },
    h4: {
      fontWeight: FONT_MEDIUM,
      fontSize: "20px",
    },
    h5: {
      fontWeight: FONT_MEDIUM,
      fontSize: "18px",
    },
    body1: {
      fontWeight: 400,
      "@media (max-width:600px)": {
        fontSize: "0.8rem",
      },
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: "#0b9a6d",
      dark: "#0b6b4c",
      light: "#3BAE8A",
    },
    secondary: {
      main: "#43749E",
      light: "#017bbc",
    },
    accent: {
      main: "#FCF2D9",
    },
    success: {
      main: "#FCF2D9",
    },
    warning: {
      main: "#97ff00", //"#7FCD0D"
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },
  },
});

export default {
  ...overrides,
  config,
};
