import React from "react";
import { Typography, Box, Grid, Card, Divider } from "@mui/material";

import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";
import { formatCurrency } from "@library/common";
import { Rebate } from "@library/domain/estimate";

const baseLanguageLocation = "concierge.PackageDetails.PricingContent";

export const PricingContent: React.FC<SectionContentProps> = ({
  theme,
  package: pkg,
}) => {
  const upFrontCost = pkg?.data?.upFrontCostTotal || 0;
  const rebates = (pkg?.polyvectorPayload?.rebates || []) as Rebate[];
  const eligibleRebates = rebates.filter(
    (e) => e.type !== "Post-Install Rebate"
  );
  const potentialRebates = rebates.filter(
    (e) => e.type === "Post-Install Rebate"
  );
  const eligibleRebatesTotal = eligibleRebates.reduce(
    (n, { total }) => n + total,
    0
  );
  const potentialRebatesTotal = potentialRebates.reduce(
    (n, { total }) => n + total,
    0
  );

  // Calculate costs after rebates
  const afterEligibleRebates = upFrontCost - eligibleRebatesTotal;

  // Payment options calculations
  const downPayment = Math.round(afterEligibleRebates * 0.25); // 25% down
  const monthlyPayment = Math.round((afterEligibleRebates - downPayment) / 120); // 120 months

  const selfFinanceLineItems = [
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.first.dueAtAssessment`
      ),
      value: "0",
    },
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.first.dueAtSigning`
      ),
      value: downPayment.toLocaleString(),
    },
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.first.dueAfterInstallation`
      ),
      value: (afterEligibleRebates - downPayment).toLocaleString(),
    },
  ];

  const loanLineItems = [
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.second.downPayment`
      ),
      value: "0",
    },
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.second.apr`
      ),
      value: "8.0%",
    },
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.second.loanTerm`
      ),
      value: "120",
    },
  ];

  return (
    <Root>
      <Box>
        <Typography className={classes.sectionContentDescription}>
          {theme?.t(`${baseLanguageLocation}.introText`, {
            eligibleAmount: formatCurrency(eligibleRebatesTotal),
            potentialAmount: formatCurrency(potentialRebatesTotal),
          })}
        </Typography>
        <Typography
          variant={"h5"}
          className={classes.sectionContentSubtitle}
          sx={{ mt: 2, mb: 1 }}
        >
          {theme?.t(`${baseLanguageLocation}.subtitle`)}:
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card className={classes.lightPurpleCard}>
              <Box
                className={`${classes.outlinedLightBorderBox} ${classes.contentLineItem}`}
              >
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.beforeRebates`)}
                </Typography>
                <Typography className={classes.boldText}>
                  {formatCurrency(upFrontCost)}
                </Typography>
              </Box>
              <Box className={classes.rebateDetailContainer}>
                <Box className={classes.contentLineItem}>
                  <Typography className={classes.boldText}>
                    {theme?.t(`${baseLanguageLocation}.eligibleRebates`)}
                  </Typography>
                  <Typography>
                    -{formatCurrency(eligibleRebatesTotal)}
                  </Typography>
                </Box>
              </Box>
              <Box
                className={`${classes.filledDarkBox} ${classes.contentLineItem}`}
              >
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.totalAfterRebates`)}
                </Typography>
                <Typography className={classes.boldText}>
                  {formatCurrency(afterEligibleRebates)}
                </Typography>
              </Box>
            </Card>
            {potentialRebatesTotal > 0 && (
              <Card className={classes.lightPurpleCard} sx={{ mt: 2 }}>
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.rebates.additional.title`)}
                </Typography>
                <Typography variant="body2">
                  {theme?.t(
                    `${baseLanguageLocation}.rebates.additional.description`,
                    {
                      amount: formatCurrency(potentialRebatesTotal),
                    }
                  )}
                </Typography>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <Typography
                sx={{ p: 2 }}
                variant="h5"
                color="primary.light"
                className={classes.largeCardTitle}
              >
                {theme?.t(`${baseLanguageLocation}.paymentOptions.subtitle`)}
              </Typography>
              <Divider />

              {/* Self-Finance Option */}
              <Box sx={{ p: 2 }}>
                <Typography
                  className={classes.paymentOptionTitle}
                  sx={{ mb: 1 }}
                >
                  {theme?.t(
                    `${baseLanguageLocation}.paymentOptions.options.first.title`
                  )}
                </Typography>
                {selfFinanceLineItems.map((lineItem, index) => (
                  <Box
                    key={index}
                    className={classes.contentLineItem}
                    sx={{ mb: 1 }}
                  >
                    <Typography variant="body2">{lineItem.name}</Typography>
                    <Typography variant="body2">
                      {formatCurrency(lineItem.value)}
                    </Typography>
                  </Box>
                ))}
                <Box
                  className={`${classes.filledDarkBox} ${classes.contentLineItem}`}
                >
                  <Typography className={classes.boldText}>
                    {theme?.t(`${baseLanguageLocation}.totalPayment`)}
                  </Typography>
                  <Typography className={classes.boldText}>
                    {formatCurrency(afterEligibleRebates)}
                  </Typography>
                </Box>
              </Box>

              {/* Financing Option */}
              <Box sx={{ p: 2 }}>
                <Typography
                  className={classes.paymentOptionTitle}
                  sx={{ mb: 1 }}
                >
                  {theme?.t(
                    `${baseLanguageLocation}.paymentOptions.options.second.title`
                  )}
                </Typography>
                {loanLineItems.map((lineItem, index) => (
                  <Box
                    key={index}
                    className={classes.contentLineItem}
                    sx={{ mb: 1 }}
                  >
                    <Typography variant="body2">{lineItem.name}</Typography>
                    <Typography variant="body2">{lineItem.value}</Typography>
                  </Box>
                ))}
                <Box
                  className={`${classes.filledDarkBox} ${classes.contentLineItem}`}
                >
                  <Typography className={classes.boldText}>
                    {theme?.t(
                      `${baseLanguageLocation}.paymentOptions.options.second.monthlyPayment`
                    )}
                  </Typography>
                  <Typography className={classes.boldText}>
                    {formatCurrency(monthlyPayment)}/month
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
