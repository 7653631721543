import React from "react";
import { TennantConfig } from ".";

// ""
// const arthurImage = "apps/customer/public/assets/testimonialArthur.jpeg";
const arthurImage = "apps/customer/public/assets/happyCustomer.png";

export const baseConfig = {
  name: "Pearl",
  showBrands: true,
  tiers: {
    default: "base",
    recommended: "base",
    available: ["base", "pearl"],
  },
  language: {
    "en-us": {
      labels: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phoneNumber: "Phone Number",
        homeType: "Home Type",
        yearBuilt: "Year Built",
        sqFootage: "Sq Footage",
        aboveGroundStories: "Above Ground Stories",
        numBedrooms: "Number of Bedrooms",
        ceilingHeight: "Ceiling Height",
        basement: "Basement",
        attic: "Attic",
        garage: "Garage",
        projectType: {
          label: "",
          enum: {
            heating_cooling: "Heating & Cooling",
            heating: "Heating",
            air_conditioning: "Air Conditioning",
            weatherization: "Weatherization",
            water_heating: "Water Heating",
            residential_solar: "Residential Solar",
            ev_charging: "Electrical Vehicle Charging",
            energy_efficient_appliances: "Energy-Efficient Appliances",
            electrical_upgrades: "Electrical Upgrades",
            im_not_sure: "I'm not sure yet",
          },
        },
        projectMotivation: {
          label: "",
          enum: {
            support: "I need emergency support.",
            online_quote:
              "I want an online quote - I am shopping for a new system now.",
            directional_estimate:
              "I want a directional estimate - I am budgeting for the future.",
            feedback:
              "I already have a few quotes, and I'd like feedback on them.",
            incentives:
              "I want to search for available discounts, rebates and tax incentives.",
          },
        },
        heatingCoolingProjectScope: {
          label: "",
          enum: {
            heating: "Heating",
            cooling: "Air Conditioning",
            both: "Both",
          },
        },
        heatingCoolingProjectType: {
          label: "",
          enum: {
            replace: "Replace your current system",
            service: "Service or maintain your current system",
            add: "Add heat for a part of your home that isn't heated today",
          },
        },
        heatingCoolingProjectTypeMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        wholeOrPartialHome: {
          label: "",
          enum: {
            whole: "The entire home.",
            partial: "Only part of the home.",
          },
        },
        floorSize: {
          label: "Floor Size",
          enum: {
            second_floor_is_same_size:
              "2nd floor is the same size as the 1st floor.",
            second_floor_a_bit_smaller:
              "2nd floor is a bit smaller than the 1st floor.",
            second_floor_a_lot_smaller:
              "2nd floor is a lot smaller than the 1st floor.",
          },
        },
        electricUtility: {
          label: "Electricity",
        },
        gasUtility: {
          label: "Natural Gas",
        },
        basementType: {
          label: "Basement Type",
          enum: {
            below_grade: 'Fully "below grade" and underground.',
            partially_below_grade: 'Partially "below grade" - garden level.',
            above_grade: 'Fully "above grade" - a walkout.',
          },
        },
        basementFinished: {
          label: "Basement Finish",
          enum: {
            finished: "Fully finished",
            unfinished: "Completely unfinished",
            partially_finished: "Partially finished",
          },
        },
        atticType: {
          label: "Attic Type",
          enum: {
            permanent_stairs: 'A "room" accessible by a permanent staircase.',
            hatch: 'A "scuttle attic" accessible through a ceiling hatch.',
            no_attic: "No attic (or not accessible).",
          },
        },
        atticFinish: {
          label: "Attic Finish",
          enum: {
            finished: "Fully finished",
            unfinished: "Completely unfinished",
            partially_finished: "Partially finished",
          },
        },
        garageType: {
          label: "Garage Type",
          enum: {
            fully_detached: "Fully detached from the house.",
            attached_with_no_living_space_above:
              "Attached, but no living space above it.",
            attached_with_living_space_above:
              "Attached, with living space above it.",
          },
        },
        howWouldYouLikeToProceed: {
          label: "",
          enum: {
            instant_quote: {
              title: "Get an instant quote online",
              description: "It takes five minutes on average.",
            },
            schedule_virtual_consultation: {
              title: "Schedule a virtual consultation",
              description:
                "You'll connect with your assigned Pearl Edison Advisor to discuss your options.",
            },
            save_progress: {
              title: "Save my progress and come back later",
              description:
                "We'll send you a link to your email address to pick this up at a later date.",
            },
          },
        },
        rooms: {
          type: {
            label: "Room type?",
            enum: {
              BEDROOM: "Bedroom",
              BATHROOM: "Bathroom",
              KITCHEN: "Kitchen",
              DINING_ROOM: "Dining Room",
              LIVING_ROOM: "Living Room",
              MASTER_BEDROOM: "Bedroom",
              MASTER_BATHROOM: "Bathroom",
              REC_ROOM: "Recreation Room",
              MEDIA_ROOM: "Media Room",
              OFFICE: "Office",
              DEN: "Den",
              FOYER: "Foyer",
              LAUNDRY_ROOM: "Laundry Room",
            },
          },
          name: {
            label: "Room name?",
          },
          comfort: {
            label: "Comfort",
          },
          isComfortIssue: {
            label: "I have a comfort issue in this room",
          },
          comfortIssueFreqency: {
            label: "When does it occur?",
            enum: {
              always: "Always",
              summer: "Summer",
              winter: "Winter",
            },
          },
          comfortIssueInfo: {
            label: "Tell us about your comfort issue. (Optional)",
          },
          floor: {
            label: "Which floor?",
            enum: {
              basement: "Basement",
              "1": "1st floor",
              "2": "2nd floor",
              "3": "3rd floor",
              attic: "Attic",
            },
          },
          size: {
            label: "What size?",
            enum: {
              SMALL: "Small",
              MEDIUM: "Medium",
              LARGE: "Large",
              XL: "XL",
              XXL: "XXL",
            },
          },
          walls: {
            label: "How many walls have windows?",
          },
          wallsWithWindows: {
            label: "How many windows?",
            enum: {
              none: "None",
              "just one": "Just one.",
              "a few": "A few.",
              "a lot": "A lot.",
              "huge ones": "Huge ones!",
            },
          },
          isCurrentlyHeated: {
            label: "Currently heat?",
          },
          isHeatComfortIssue: { label: "Comfort issues?" },
          heatComfortIssueInfo: { label: "Please explain. (Optional)" },
          isCurrentlyAirConditioned: { label: "Has A/C now?" },
          isAirConditionComfortIssue: { label: "Comfort Issues?" },
          airConditionComfortIssueInfo: { label: "Please explain. (Optional)" },
        },
        heatingEquipmentType: {
          label: "Primary Heating System Type",
          enum: {
            furnace_with_ducts: "Furnace with ductwork",
            boiler_with_radiators: "Boiler with radiators",
            electric_resistive_with_baseboards:
              "Electric resistive heat with baseboards",
            heat_pump_mini_split: "Heat pump, also known as a “mini split”",
            none_of_these: "None of these match my heating system",
          },
        },
        heatingEquipmentUsage: {
          enum: {
            always_on:
              "The system is always on - I rarely touch the thermostat.",
            frequency_adjustments:
              "I adjust the thermostat frequently - for example, when I leave the house.",
            rarely_use: "I don't use the system unless it's really cold out.",
          },
        },
        heatingEquipmentTypeMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        fuelSource: {
          label: "Primary Heating System Fuel",
          enum: {
            natural_gas: "Natural gas",
            fuel_oil: "Fuel oil",
            electricity: "Electricity",
            propane: "Propane",
            something_else: "Another fuel source",
            i_don_t_know: "I don't know",
          },
        },
        coolingEquipmentType: {
          label: "Primary Air Conditioning System Type",
          enum: {
            central_air_conditioning: "Central air conditioning",
            window_unit_air_conditioning: "Window-unit air conditioning",
            evaporative_cooling: "Evaporative cooling",
            heat_pump_mini_split: 'Heat pump, also known as a "Mini Split"',
            something_else: "Another system",
            no_air_conditioning: "No air conditioning",
          },
        },
        coolingEquipmentUsage: {
          enum: {
            always_on:
              "The system is always on - I rarely touch the thermostat.",
            frequency_adjustments:
              "I adjust the thermostat frequently - for example, when I leave the house.",
            rarely_use: "I don't use the system unless it's really warm out.",
          },
        },
        coolingEquipmentTypeMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        materials: {
          exteriorWalls: { label: "Exterior Walls" },
          interiorWalls: { label: "Interior Walls" },
        },
        exteriorWalls: {
          label: "Exterior Walls",
          enum: {
            stucco: "Stucco",
            vinyl: "Vinyl",
            fiber_cement: "Fiber Cement",
            brick: "Brick",
            wood: "Wood",
            stone: "Stone",
            metal: "Metal",
          },
        },
        interiorWalls: {
          label: "Interior Walls",
          enum: {
            stucco: "Stucco",
            drywall: "Drywall",
            plaster: "Plaster",
            brick: "Brick",
            wood_panel: "Wood Panel",
            concrete: "Concrete",
            ceramic_tile: "Ceramic Tile",
          },
        },
        numWindows: {
          enum: {
            a_few: "Very few windows",
            average: "An average number of windows",
            a_lot: "A lot of windows",
            many_large: 'Many large "picture" windows',
          },
        },
        windowType: {
          enum: {
            single: "Single-pane windows",
            double: "Double-pane windows",
            triple: "Triple-pane windows",
            im_not_sure: "I'm not sure",
          },
        },
        upgrades: {
          label: "Upgrades",
          enum: {
            added_insulation: {
              title: "Added insulation",
              // description: "Lorem ipsum dolor sit amet",
            },
            upgraded_windows: {
              title: "Upgraded windows",
            },
            air_sealing: {
              title: "Air sealing",
            },
            upgraded_electrical_panel: {
              title: "Upgraded electrical panel",
            },
            duct_insulation_sealing: {
              title: "Duct sealing / insulation",
            },
            something_else: {
              title: "Something else",
            },
          },
        },
        upgradesMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        airSealingScope: {
          label: "Scope",
          enum: {
            whole: "Whole-Home",
            targeted: "Targeted",
            i_don_t_know: "I don't know",
          },
        },
        airSealingInstallation: {
          label: "Installation",
          enum: {
            professional: "Professional",
            diy: "DIY",
            i_don_t_know: "I don't know",
          },
        },
        ductSealingScope: {
          label: "Scope",
          enum: {
            tape_mastic: "Tape / Mastic",
            aeroseal: "AeroSeal",
            i_don_t_know: "I don't know",
          },
        },
        ductSealingInstallation: {
          label: "Installation",
          enum: {
            professional: "Professional",
            diy: "DIY",
            i_don_t_know: "I don't know",
          },
        },
        atticInsulation: {
          enum: {
            yes: "Attic / ceiling insulation",
          },
        },
        atticInsulationType: {
          label: "Insulation type",
          enum: {
            undefined: "",
            loose_fill_cellulose: "Loose-fill cellulose",
            dense_packed_cellulose: "Dense-packed cellulose",
            fiberglass_batts: "Fiberglass batts",
            rigid_foam_board: "Rigid foam board",
            i_don_t_know: "I don't know",
          },
        },
        basementInsulation: {
          enum: {
            yes: "Basement / floor insulation",
          },
        },
        basementInsulationType: {
          label: "Insulation type",
          enum: {
            loose_fill_cellulose: "Loose-fill cellulose",
            dense_packed_cellulose: "Dense-packed cellulose",
            fiberglass_batts: "Fiberglass batts",
            rigid_foam_board: "Rigid foam board",
            i_don_t_know: "I don't know",
          },
        },
        wallInsulation: {
          enum: {
            yes: "Wall insulation",
          },
        },
        wallInsulationType: {
          label: "Insulation type",
          enum: {
            undefined: "",
            loose_fill_cellulose: "Loose-fill cellulose",
            dense_packed_cellulose: "Dense-packed cellulose",
            fiberglass_batts: "Fiberglass batts",
            rigid_foam_board: "Rigid foam board",
            i_don_t_know: "I don't know",
          },
        },
        rimJoistInsulation: {
          enum: {
            yes: "Rim joist insulation",
          },
        },
        rimJoistInsulationType: {
          label: "Insulation type",
          enum: {
            undefined: "",
            loose_fill_cellulose: "Loose-fill cellulose",
            dense_packed_cellulose: "Dense-packed cellulose",
            fiberglass_batts: "Fiberglass batts",
            rigid_foam_board: "Rigid foam board",
            i_don_t_know: "I don't know",
          },
        },
        issues: {
          label: "Issues",
          enum: {
            roof_damage: "Roof damage",
            asbestos: "Asbestos",
            wall_damage: "Wall damage",
            visible_mold: "Visible mold",
            missing_windows: "Missing windows",
            standing_water: "Standing water",
            electrical_issues: "Electrical issues",
            indoor_air_quality: "Indoor air quality",
            structural: {
              title: "Structural damage",
              description: "(e.g. walls, windows, roof)",
            },
            electrical: {
              title: "Electrical issues",
              description: "(e.g. flickering, knob-and-tube)",
            },
            health_and_safety: {
              title: "Potential health and safety issues",
              description: "(e.g. asbestos, radon, mold / moisture)",
            },
          },
        },
        issuesMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        electricalPanelRating: {
          enum: {
            lt_100: "100 amps or less",
            between_100_200: "Between 100 and 200 amps",
            gt_200: "200 amps or more",
            i_don_t_know: "I don't know",
          },
        },
        heatingEquipmentTypeAge: {
          enum: {
            lt_5: "Less than 5 years old",
            between_5_10: "5 to 10 years old",
            between_10_15: "10 to 15 years old",
            gt_15: "More than 15 years old",
            i_don_t_know: "I don't know",
          },
        },
        coolingEquipmentTypeAge: {
          enum: {
            lt_5: "Less than 5 years old",
            between_5_10: "5 to 10 years old",
            between_10_15: "10 to 15 years old",
            gt_15: "More than 15 years old",
            i_don_t_know: "I don't know",
          },
        },
        shortCycles: {
          enum: {
            yes: "Yes",
            no: "No",
            i_don_t_know: "I don't know",
          },
        },
        noisyDuctwork: {
          enum: {
            yes: "Yes",
            no: "No",
            i_don_t_know: "I don't know",
          },
        },
        draftyStuffy: {
          label: "Drafty / Stuffy",
          enum: {
            yes_drafty:
              "Yes, there can be cool drafts even when windows are shut.",
            yes_stuffy:
              "Yes, sometimes the air quality can feel stuffy or uncomfortable.",
            no: "All good!",
          },
        },
        draftyStuffyMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        drafty: {
          enum: {
            yes_drafty: "Yes",
            not_drafty: "No",
          },
        },
        stuffy: {
          enum: {
            yes_stuffy: "Yes",
            not_stuffy: "No",
          },
        },
        homeQuality: {
          label: "Home Quality",
          enum: {
            yes: "Yes!",
            no_always_cold: "No, too cold in the winter.",
            no_always_hot: "No, too hot in the summer.",
            uneven: "No, some rooms are too hot or too cold.",
          },
        },
        homeQualityMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        whyAreYouShoppingToday: {
          label: "Why Are You Shopping",
          enum: {
            equipmentBroken: "My equipment is broken.",
            equipmentLastLeg: "My equipment is old, I want to replace it.",
            moreEfficientSystem: "I want a more efficient system.",
            justBrowsing: "I'm just browsing.",
          },
        },
        whatsImportantToYou: {
          label: "What's Important To You",
          enum: {
            lowUpFrontCost: "Low up-front cost",
            lowerMonthlyBills: "Reduce energy costs",
            climateImpact: "Climate impact",
            betterComfort: "Comfort",
            somethingElse: "Other",
          },
        },
        whatsImportantToYouInfo: {
          label: "Anything else we should know? (Optional)",
        },
        ownOrRent: {
          enum: {
            own: "I own my home",
            rent: "I rent my home",
          },
        },
        projectTimeline: {
          enum: {
            asap: "ASAP",
            flexible: "Flexible",
            i_don_t_know: "I don't know",
          },
        },
        peopleLiveInHome: {
          label: "People Live In Home",
        },
        communicationPreference: {
          label: "Communication Preference",
          enum: {
            email: "Email",
            text: "Text",
            call: "Call",
          },
        },
      },
      step: {
        SchedulingAddressStep: {
          title: "What's your address?",
        },
        SchedulingCommunicationStep: {
          title: "Great, we can help with your {projectTypeClean} project.",
          description:
            "Please provide your name and contact information. Then, we'll schedule your aduit.",
        },
        SchedulingStep: {
          title: "Thanks. Schedule your consultation.",
          description: "Reach us any time at {constants.PHONE_NUMBER}",
        },
        ProjectTypeStep: {
          title: "Welcome to Pearl Edison Concierge!",
          description: "What type of project are you working on?",
        },
        HeatingCoolingProjectScopeStep: {
          title: "Great! Tell us a bit about your project.",
          description: "Do you want...",
        },
        HeatingCoolingProjectStep: {
          title: "Great! Tell us a bit more about your project.",
          description: "Do you want to...",
        },
        HeatingOrAirConditioningServiceAddressStep: {
          title: "What's your address?",
        },
        HeatingOrAirConditioningServiceStep: {
          title: "Great, let's schedule service.",
          description: "Please provide your name and contact information.",
        },
        HeatingOrAirConditioningScheduleServiceStep: {
          title:
            "Thanks. You can schedule any time, or call us directly if it's an emergency.",
          description: "Reach us any time at {constants.PHONE_NUMBER}",
        },
        HeatingOrAirConditioningReplacementStep: {
          title: "Great, we can support you with that.",
          description: "How do you want to get started?",
        },
        HeatingOrAirConditioningReplaceEmergencyStep: {
          title:
            "We can help with your emergency. Please provide your name and contact information",
          description: "Reach us anytime at {constants.PHONE_NUMBER}",
        },
        HeatingOrAirConditioningReplaceEmergencyScheduleStep: {
          title:
            "Thanks. You can schedule any time, or call us directly if it's an emergency.",
          description: "Reach us any time at {constants.PHONE_NUMBER}",
        },
        HeatingOrAirConditioningWholeOrPartialStep: {
          title:
            "Will this system serve the entire home, or only part of the home?",
          description: "",
        },
        LetsGetStartedStep: {
          title: "Get started today!",
          description: "Pearl Edison is your Energy Efficiency Concierge.",
          buttons: {
            seeProcess: "See Our Process",
            startEstimate: "Start Your Estimate",
          },
          todaySection: {
            title: "Today you can:",
            estimate: {
              title: "Get an instant estimate",
              timeframe: "Takes about 5 minutes",
              description:
                "We use details about your project, home, and goals to give you an instant estimate, including rebates, so you can decide if you're interested.",
            },
            advisor: {
              title: "Connect with a Pearl Edison Advisor",
              timeframe: "Usually scheduled in one day.",
              description:
                "An energy efficiency expert certified by the Building Performance Institute will support you.",
              button: "Meet An Advisor",
            },
            sampleButton: "See A Sample",
          },
          futureSection: {
            title: "Then, when you're ready, you can:",
            assessment: {
              title: "Schedule a free home assessment",
              timeframe: "Usually scheduled within one week.",
              description:
                "A free energy audit to finalize your project, after you complete the instant estimate and virtual consultation.",
            },
            project: {
              title: "Approve your project",
              subtitle: "Move at your pace!",
              description:
                "After your Home Assessment, you'll get a guaranteed final proposal—no surprises.",
            },
            contractor: {
              title: "Meet your contractor",
              subtitle: "Pearl Edison Certified",
              description:
                "Building Performance Institute certified, here to support you each step of the way.",
            },
          },
        },
        UploadQuoteStep: {
          title: "Upload your quote to get started.",
          description:
            "Your Pearl Edison Advisor will review and provide feedback.",
        },
        IncentivesAddressStep: {
          title: "What's your address?",
          description:
            "We'll search for available discounts, rebates and tax incentives.",
        },
        EligbleRebateStep: {
          title:
            "We found up to ${eligibleRebateTotal} in discounts, rebates and tax credits you could be eligible for.",
          description:
            "Do you want to check your eligibility now? It takes ~2 minutes.",
        },
        PotentiallyEligbleRebateStep: {
          title:
            "Depending on the scope of your project, you're potentially eligible for up to ${eligibleRebateTotal}.",
          description:
            "We'll show you a few options that are eligbile for these rebates.",
        },
        RebateInformationStep: {
          title: "Great! A quick note before we get started.",
          description:
            "Your eligibility is determined by the rebate provider and depends on where you live, whether you own your home, how much money you make, how many dependents you support, and other home and project details. We will verify your eligibility during your home assessment.",
        },
        UtilityCompanyStep: {
          title: "Who do you buy your energy from?",
          description: "Please confirm your utility company(s).",
        },
        SideBar: {
          collapsed: {
            title: "Full Estimate",
            estimatedCost: {
              label: "Estimated Cost",
            },
            billImpact: {
              label: "Bill Impact",
              perYear: "/yr",
            },
            climateImpact: {
              label: "Climate Impact",
              unit: "MT/yr",
            },
          },
          expanded: {
            title: "Live Estimate",
            systemSelection: {
              label: "When using:",
            },
            estimatedCost: {
              title: "Estimated Cost",
              subtitle: "Your up-front cost after applying instant rebates",
            },
            billImpact: {
              title: "Annual Bill Impact",
              subtitle: "Based on homes that are similar to yours",
              perYear: "/ yr",
            },
            climateImpact: {
              title: "Climate Impact",
              subtitle: "Based on homes that are similar to yours",
              unit: "MT/yr",
            },
          },
        },
        LightEstimateOptionsStep: {
          title:
            "We're still working on your Instant Estimate, but based on the info you provided, here are a few initial options.",
          description:
            "You can continue to get an Instant Estimate or schedule a consultation with a Pearl Edison Advisor.",
          readMore: {
            more: "Read More",
            less: "Show Less",
          },
          installedCost: {
            title: "Installed Cost",
            subtitle: "Before Instant Rebates",
          },
          yourPrice: {
            title: "Your Price",
            subtitle: "After Instant Rebates",
          },
          monthlyCost: {
            title: "Monthly Cost",
            subtitle: "w/ Financing",
            perMonth: "/ mo",
          },
          instantRebates: {
            title: "Rebates",
          },
          annualBill: {
            title: "Annual Bill Savings",
            subtitle: "Based on similar homes",
            perYear: "per year",
          },
          climateImpact: {
            title: "Climate Impact",
            subtitle: "Based on similar homes",
            unit: "MT CO2/yr reduction",
          },
        },
        InstantEstimateStep: {
          title: "Your Instant Estimate is ready!",
          description:
            "You can view it now or go back to edit your selections.",
        },
        HowWouldYouLikeToProceedStep: {
          title: "How would you like to proceed?",
        },
        HeatingAndCoolingSchedulingStep: {
          title: "Thanks. Schedule your consultation.",
          description: "Reach us any time at {constants.PHONE_NUMBER}",
        },
        AccountCreationStep: {
          title: "Create an account to save your quote",
          description:
            "We'll send you a link so you can access your instant quote after it's complete.",
        },
        PostSaveProgressStep: {
          title: "Your progress has been saved",
          description: [
            "You can continue with your Instant Estimate now or we've sent an email with a login link that expires in 1 day so you can finish later. You may request a new magic link at any time from the ",
            <a href="/login">login page</a>,
            ".",
          ],
        },
        MeetYourAdvisorStep: {
          title: "You're ready for the next step.",
          description:
            "But first, here are a few things that should interest you.",
          bullets: {
            first: {
              text: "Below, meet your dedicated Pearl Edison advisor. We're here anytime you need us.",
            },
            second: {
              text: "On the right you'll find your estimate summary. Keep an eye on this as you provide more information to see how it impacts your estimate.",
            },
            third: {
              text: "Now, we'll ask a few more questions about your home, your current systems, and your goals for this project.",
            },
          },
        },
        AddressStep: {
          title:
            "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
          description: "",
        },
        OnboardingStep: { title: "", description: "" },
        GettingStartedStep: {
          title: "Let’s get started.",
          description:
            "We’ll help you design the right efficient heating and air conditioning system for your unique home and goals. It only takes 10 minutes.",
          step1Title: "Get your instant design and estimate",
          step1Description:
            "Tell us about your home and goals, then find your rebates. We’ll design the right system for your unique home and goals.",
          step1Time: "~10 min",
          step2Title: "Meet your advisor",
          step2Description:
            "We’ll confirm final details, answer your questions, and finalize your design.",
          step2Time: "within 1 day",
          step3Title: "Electrify your home",
          step3Description:
            "When you’re ready, we’ll schedule your installation with one of our licensed, vetted, experienced contractor partners.",
          step3Time: "within 2 weeks",
        },
        HomeConfirmationStep: {
          title: "Is this your home?",
          description: "",
        },
        FullNameStep: {
          title: "Tell us about yourself.",
          description: "We'll send you a link to view your estimate.",
        },
        HomeProfileStep: {
          title: "Please confirm details about your home.",
          description: "",
          yourHome: "Your Home",
          serviceMessage:
            "We're proud to serve the City of {city}, and excited for the opportunity to serve you.",
          sections: {
            homeDetails: "Home Details",
            hvacSystem: "HVAC System",
          },
        },
        FloorSizeStep: {
          title: "How large is each floor?",
          description: "",
        },
        BasementTypeStep: {
          title: "Which basement is most like yours?",
          description: "",
          whyTitle: "Why are we asking?",
          whyDescription:
            "This helps us estimate heat loss from your basement. Basements can account for about 25% of your home's total heat gain / loss.",
        },
        BasementFinishedStep: {
          title: "Is your basement finished?",
          description: "",
          whyTitle: "Why are we asking?",
          whyDescription:
            "This helps us estimate heat loss from your basement. Basements can account for about 25% of your home's total heat gain / loss.",
        },
        AtticTypeStep: {
          title: "Which attic is most like yours?",
          description: "",
          whyDescription:
            "This helps us estimate heat loss from your attic and exterior ceiling.  Your attic and exterior ceiling can account for as much as 40% of your home's total heat gain / loss.",
        },
        AtticFinishedStep: {
          title: "Is your attic finished?",
          description: "",
        },
        GarageStep: {
          title: "Which garage is most like yours?",
          description: "",
          whyDescription:
            "This helps us estimate heat loss through your unconditioned garage.",
        },
        FloorPlanStep: {
          title: "List all of the rooms in your home",
          description: "",
          whyDescription:
            "Your home doesn't have existing ductwork we can leverage, so we use this information to determine how to size and where to site indoor equipment in order to ensure you're comfortable.",
        },
        RoomsConfigurationStep: {
          title: "Tell us a bit more about each room.",
          description: "",
          whyDescription:
            "Your home doesn't have existing ductwork we can leverage, so we use this information to determine how to size and where to site indoor equipment in order to ensure you're comfortable.",
        },
        NumberOfWindowsStep: {
          title: "Would you say that your home has...",
          description: "",
          whyDescription:
            "This helps us estimate heat loss from your windows.  Windows can account for about 25% or more of your home's total heat gain / loss.",
        },
        HeatingEquipmentStep: {
          title: "What kind of heating equipment do you currently have?",
          description: "",
        },
        FuelSourceStep: {
          title: 'What is your "fuel source" for heating?',
          description: "",
        },
        HeatRoomsStep: {
          title:
            "Which rooms do you heat today? Do you experience any winter comfort issues?",
          description: "",
        },
        AirConditioningStep: {
          title:
            "What kind of air conditioning equipment do you currently have?",
          description: "",
        },
        AirConditioningRoomsStep: {
          title:
            "Which rooms have air conditioning today? Do you experience any summer comfort issues?",
          description: "",
        },
        MaterialsSteps: {
          title: "What material are (most of) your walls made of?",
          description: "",
        },
        WindowsStep: {
          title: "What kind of windows do you have?",
          description: "",
          whyDescription:
            "This helps us estimate the heat loss from your windows. Windows can account about 25% or more of your home's total heat gain / loss.",
        },
        UpgradeStep: {
          title: "Have you made any recent energy efficiency investments?",
          description: "",
          whyDescription:
            "These investments make your home more efficient and reduce the size of the system you need to serve your home. If you made a recent investment, then we'll update our system design and recommendation accordingly.",
        },
        AirSealingInvestmentStep: {
          title:
            "Please share a few details about your air sealing investment.",
          description: "",
          whyDescription:
            "These investments make your home more efficient and reduce the size of the system you need to serve your home. If you made a recent investment, then we'll update our system design and recommendation accordingly.",
        },
        DuctSealingInvestmentStep: {
          title:
            "Please share a few details about your duct sealing investment.",
          description: "",
          whyDescription:
            "These investments make your home more efficient and reduce the size of the system you need to serve your home. If you made a recent investment, then we'll update our system design and recommendation accordingly.",
        },
        InsulationInvestmentStep: {
          title: "Please share a few details about your insulation investment.",
          description: "",
          whyDescription:
            "These investments make your home more efficient and reduce the size of the system you need to serve your home. If you made a recent investment, then we'll update our system design and recommendation accordingly.",
        },
        IssuesStep: {
          title: "Does your home have any of the following issues?",
          description: "",
          whyDescription:
            "These issues are usually show-stoppers - meaning they need to be fixed before we can replace your hearing or air conditioning system. Your Pearl Edison Advisor will help you figure out next steps.",
        },
        ElectricalPanelRatingStep: {
          title: "Do you know the rating of your electical panel?",
          description: "",
          whyDescription:
            "This helps us evaluate whether we need to upgrade your electical service as part of this project - or, to design a system that will work without upgrading your electrical service.",
        },
        HeatingEquipmentAgeStep: {
          title:
            "How old is your {heatingEquipmentFuelClean} {heatingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "This helps us evaluate whether we need to upgrade your electical service as part of this project - or, to design a system that will work without upgrading your electrical service.",
        },
        HeatingEquipmentRatedEfficiencyStep: {
          title:
            "What is the rated efficiency of your {heatingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. Older systems are typically less efficient, because older technology was less efficient and because performance degrades over time.",
        },
        HeatingEquipmentRatedHSPFEfficiencyStep: {
          title:
            "What is the rated efficiency of your {heatingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. Older systems are typically less efficient, because older technology was less efficient and because performance degrades over time.",
        },
        CoolingEquipmentAgeStep: {
          title: "How old is your {coolingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "This helps us evaluate whether we need to upgrade your electical service as part of this project - or, to design a system that will work without upgrading your electrical service.",
        },
        HeatingEquipmentRatedCapacityStep: {
          title:
            "What is the rated capacity of your {heatingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. An oversized system operates less efficiently - and over 90% of systems are oversized.",
        },
        CoolingEquipmentRatedEfficiencyStep: {
          title:
            "What is the rated efficiency of your {coolingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. Older systems are typically less efficient, because older technology was less efficient and because performance degrades over time.",
        },
        CoolingEquipmentRatedCapacityStep: {
          title:
            "What is the rated capacity of your {coolingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. An oversized system operates less efficiently - and over 90% of systems are oversized.",
        },
        SystemShortCyclesStep: {
          title:
            "Does your heating or air conditioning system turn on and off frequently in short bursts?",
          description: "",
          whyDescription:
            'We use this information to analyze potential utility bill savings.  It\'s >10% less efficient for your system to frequently turn on and off - called "short cycling" - instead of running constantly.  This also reduces the life of your equipment!',
        },
        NoisyDuctworkStep: {
          title: "Is your ductwork noisy?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings.  If your ductwork is noisy, then static pressure is building up because your ductwork is undersized.  This decreases the efficiency of your system and reduces the life of the equipment.",
        },
        TemperatureStep: {
          title: "What temperature do you usually set the thermostat at?",
          description: "",
          whyDescription:
            "How you use your system impacts both system design and potential utility bill savings. We ask this question to improve the accuracy of our Instant Estiamte.",
        },
        DraftyStuffyStep: {
          title: 'Does your home ever feel "drafty" or "stuffy"?',
          description: "",
        },
        DraftyStep: {
          title: 'Does your home ever feel "drafty"?',
          description: "",
          whyDescription:
            "This helps us estimate heat loss from infiltration. In a typical home, infiltration can account for up to 30% of total heat loss, meaning that a significant portion of heating energy is used to warm air that leaks into the building through cracks and gaps in the building envelope.",
        },
        StuffyStep: {
          title: 'Does your home ever feel "stuffy"?',
          description: "",
          whyDescription:
            "This helps us evaluate if your home is properly ventilated. If your home isn't properly ventilated, then this could be a sign of an indoor air quality issue. We'll check this during your Home Assessment.",
        },
        HomeQualityStep: {
          title: "Is the temperature of your home usually comfortable?",
          description: "",
        },
        HeatingEquipmentUsageStep: {
          title: "How do you typically use your heating system?",
          description: "",
          whyDescription:
            "How you use your system impacts both system design and potential utility bill savings.  We ask this question to imrpove the accuracy of our Instant Estimate.",
        },
        CoolingEquipmentUsageStep: {
          title: "How do you typically use your cooling system?",
          description: "",
          whyDescription:
            "How you use your system impacts both system design and potential utility bill savings.  We ask this question to imrpove the accuracy of our Instant Estimate.",
        },
        ProjectTimelineStep: {
          title: "What's your timeline?",
          description: "",
        },
        WhyAreYouShoppingStep: {
          title: "Why are you shopping today?",
          description: "",
        },
        WhatsImportantToYouStep: {
          title: "What's important to you?",
          description: "",
        },
        AlmostDone: {
          title: "Almost done.",
          description: "Let‘s find your rebates.",
        },
        OwnOrRentStep: {
          title: "Do you own or rent your home?",
          description:
            "Note: Some rebate programs require that you own and occupy your home to be eligible.",
        },
        PeopleLiveInHomeStep: {
          title: "How many people live in the home?",
          description:
            "Note: This includes you, a spouse if you have one, your children, and other people that live with you - anyone that you financially support.",
        },
        HouseHoldIncomeStep: {
          title: "What is your annual household income?",
          description:
            "Note: This includes income earned by every member of the household. This includes you, a spouse if you have one, your children, and other people that live with you -- anyone that you financially support.",
        },
        CommunicationPreferencesStep: {
          title: "Communication Preference",
          description: "",
        },
        FinalStep: {
          title: "Are you ready to see your quote?",
          description: 'Pressing "submit" will generate your estimate.',
        },
        GetEstimates: { title: "Creating your estimates!", description: "" },
      },
      concierge: {
        title: ", here is your instant estimate!", // prepended by user's name
        loadingTitle: ", loading your instant estimate!", // prepended by user's name
        description:
          "Review your options for efficient heating and cooling. Then, connect with your Pearl Edison advisor to finalize your quote.",
        ScheduleFooter: {
          finalizeButtonDescription: "Ready to finalize your quote?",
          finalizeButtonLabel: "Schedule a Home Assessment",
          consultationButtonDescription: "Still have questions?",
          consultationButtonLabel: "Schedule a Virtual Consultation",
          yourAdvisor: "Your Pearl Edison Advisor",
        },
        SchedulingDialog: {
          done: "Done",
          nextStep: "Take the next step!",
          schedule: "Schedule a", // appended with title
          assessment: {
            duration: "1 hr 30 min",
            title: "Home Assessment",
            subtitle: "Ready to finalize your quote?",
            eventDescription:
              "Please select a day and time that works for you to have a certified, Pearl Edison contractor visit your home for an in-person assessment.",
          },
          consultation: {
            duration: "1 hr",
            title: "Virtual Consultation",
            subtitle: "Still have questions?",
            eventDescription:
              "Please select a day and time that works for you to have a conversation with a Pearl Edison specialist. We are Looking forward to discussing your home!",
          },
        },
        SwiperCarousel: {
          title: "Your options for", // appended with address
        },
        PackageDetails: {
          PackageCard: {
            packageTitle: "Package", // for "{tier} Package"
            recommendedText: "Recommended for you",
            pricingOptions: {
              title: "Pricing Options",
              singlePayment: "Single Payment:",
              financeIntro: "Finance for as little as",
              financeDetails: "over 10 years, with $0 down.",
            },
            impacts: {
              annual: {
                title: "Annual Bill Impact:",
                tbd: "TBD",
              },
              climate: {
                title: "Climate Impact:",
                units: "MT",
                tbd: "TBD",
              },
            },
            footer: {
              currentlyViewing: "Currently Viewing this package",
              learnMore: "Learn More",
            },
          },
          SummaryContent: {
            subtitle: "Understanding Your System",
            learnMore: "Learn More",
            summaryQuote: "Summary Quote",
            cost: "Cost",
            rebates: "Rebates",
            costAfterRebates: "Cost After Rebates",
            potentialFinancing:
              "Pay as little as {amount} per month with flexible financing from Michigan Saves.",
          },
          ScopeContent: {
            title: "Scope of Work",
            description:
              "We designed an efficient heating and cooling system based on your unique home and the goals and preferences you shared. See your detailed scope of work below. Your Pearl Edison advisor can answer an of your questions!",
            total: "Total Cost Before Rebates",
            scope: "Scope",
            learnMore: "Learn More",
            otherOptions: "Other Options",
            installationNote:
              "Your installation will be performed by a Pearl Edison contractor.",
            options: {
              fasterInstallation: {
                title: "Need a faster installation?",
                description: "Have an urgent need? We've got you covered.",
                dialogSubtitle: "Have an urgent need?",
                dialogText:
                  "Call (312) 358-5085 or confirm your number below and we'll be in touch ASAP.",
                dialogButtonText: "Contact Me",
              },
              customizeSystem: {
                title: "Want to customize your system?",
                description:
                  "You can modify the scope to better reflect your goals.",
                dialogButtonText: "Confirm Preference",
                options: {
                  waterHeater: {
                    title: "Water Heater",
                    description:
                      "Replacing your water heater could result in additional savings.",
                  },
                  airQuality: {
                    title: "Air Quality",
                    description:
                      "Consider investing in air purification, filtration, and humidification.",
                  },
                  insulation: {
                    title: "Insulation",
                    description:
                      "Adding insulation means better comfort and additional savings.",
                  },
                  other: {
                    title: "Something Else",
                  },
                },
              },
              brandPreference: {
                title: "Have a brand preference?",
                description:
                  "Let us know! Note: this often adds time and cost.",
                dialogSubtitle: "Tell us about your brand preferences",
                dialogFooter: "Note: this often adds time and cost.",
                dialogButtonText: "Save Message",
              },
              request: "Request",
            },
          },
          BillImpactContent: {
            weather: "Weather",
            energyPrices: "Energy Prices",
            savingsIntro: "We project that you'll save",
            savingsMiddle: "per year initially and greater than",
            savingsEnd: "over the life of your system.",
          },
          EnergyImpactContent: {
            weather: "Weather",
            energyPrices: "Energy Prices",
            energyIntro: "We project that you'll {direction} your energy by",
            energyMiddle: "kWh per year initially and",
            energyEnd: "kWh over the life of your system.",
          },
          PricingContent: {
            subtitle: "Cost Breakdown",
            beforeRebates: "Before Rebates",
            totalPayment: "Total Payment",
            afterRebates: "After All Rebates + Discounts",
            afterTaxCredits: "After Tax Credits",
            introText:
              "We searched for every applicable discount, rebate, and tax incentive to lower your price. You're eligible for {eligibleAmount}, and you could be eligible for an additional {potentialAmount} - we'll verify this during your home assessment.",
            eligibleRebates: "Eligible Rebates",
            totalAfterRebates: "Total After Rebates",
            rebates: {
              payNow: {
                title: "Rebates + discounts that pay now",
                description:
                  "Applied at point of sale by Pearl Edison, to lower your price",
              },
              payLater: {
                title: "Rebates + discounts that pay later",
                description:
                  "Applied after you've paid for your new system and installation is complete",
              },
              taxCredits: {
                title: "Tax Credits",
                description:
                  "Applied as a tax refund, which will save you money if you would otherwise owe in federal or state taxes",
              },
              additional: {
                title: "Additional Rebates",
                description:
                  "You could be eligible for up to {amount} in additional rebates. We will evaluate your eligibility during your home assessment.",
              },
            },
            paymentOptions: {
              subtitle: "Payment Options",
              options: {
                first: {
                  title: "Pay in Installments",
                  dueAtAssessment: "Due at home assessment",
                  dueAtSigning: "Due at signing",
                  dueAfterInstallation: "Due after installation",
                },
                second: {
                  title: "Monthly Financing",
                  downPayment: "Down payment",
                  apr: "APR",
                  loanTerm: "Month loan term",
                  monthlyPayment: "Monthly Payment",
                },
              },
            },
          },
          LoadCalculationContent: {
            title: "Load Calculation",
            description:
              "During your home assessment, we analyzed your heating and cooling load - the amount of heat we need to move to keep you warm in the winter and cool in the summer.",
          },
          EquipmentContent: {
            title: "Equipment & Tech Specs",
            description:
              "We only install high-quality equipment from reputable manufacturers that back their products with a 10-year warranty on parts and labor.",
            heating: "Heating",
            cooling: "Cooling",
            exampleUnit:
              "Example system that meets these technical specifications",
          },
          WhyPearlEdisonContent: {
            title: "Why Pearl Edison?",
            description:
              "We provide high-quality advice, properly design your system, and arrange installation through a vetted contractor partner.",
            howItWorks: {
              title: "How It Works",
              items: {
                responsible: {
                  title: "We are responsible for your project, end-to-end.",
                  description:
                    "We are responsible for the design, installation, and commissioning of your system. (We are not a lead gen service sell comfort, not data.) That means we're on the hook if you aren't happy.",
                },
                guarantee: {
                  title:
                    "We guarantee your price, including rebates + incentives.",
                  description:
                    "After your home assessment, we set a 'ceiling' for your price, including applicable rebates and incentives. We work on a fixed-price basis, even if we are going to lose money on the job.",
                },
                negotiate: {
                  title: "We negotiate on your behalf to get a good deal.",
                  description:
                    "We negotiate scoping and pricing with our network of contractor partners. We typically save our customers ~10% vs. going direct. If we're able to bring the price down, we'll reduce your price!",
                },
                money: {
                  title: "We make money if (and only if) you save money.",
                  description:
                    "We earn a fee on your project, as a percentage of the savings we negotiate. If we can't identify a contractor willing to perform the work at your guaranteed price, then we pay",
                },
              },
            },
            ourStandards: {
              title: "Our Standards",
              items: {
                licenseCheck: {
                  title: "License Check",
                  description:
                    "We perform a license check to ensure that all Pearl Edison contractor and subcontractor partners are operating legally and ethically, and are qualified by the state to work in your area.",
                },
                technicalVetting: {
                  title: "Technical Vetting",
                  description:
                    "Our Operations team evaluates every Pearl Edison contractor partner's technical credentials to ensure that they are capable of meeting rigorous standards for installation and commissioning.",
                },
                ethicalVetting: {
                  title: "Ethical Vetting",
                  description:
                    "We work with partners who share our values - our commitment to serving customers with integrity and our commitment to grounding system design in building science and best pratices.",
                },
                codeOfConduct: {
                  title: "Code of Conduct",
                  description:
                    "Every Pearl Edison contractor partner signs up to a code of conduct. You can read it here. Failure to abide by the code of conduct results in removal from the Pearl Edison network.",
                },
              },
            },
            featuredPartner: {
              title: "Featured Partner",
              companyName: "Air Doctors",
              description:
                "We are a proud partner in the Pearl Edison network. Our goals are aligned - delivering energy-efficient products, quality work, and outstanding customer service. Consider us a lifer!",
              founded: { label: "Founded", value: "2004" },
              headquarters: {
                label: "Headquarters",
                value: "Detroit, MI",
              },
              certifications: "Certifications",
            },
          },
          TestimonialContent: {
            title: "Testimonials",
            description:
              "Hear from neighbors who worked with Pearl Edison to buy an efficient heating and air conditioning system.",
            whyElectric: "Why did you go electric?",
            projectScopeLabel: "Project scope label",
            items: {
              julianne: {
                customerName: "Julianne L.",
                location: "Detroit, MI",
                why: "To add air conditioning and improve my summer comfort.",
                scopeLabel: "3-ton dual-fuel heat pump",
                testimonial:
                  "Pearl Edison has been so great to work with! They are communicative and helpful through what could be a very complicated process. They make it simple and easy.",
              },
              macon: {
                customerName: "Macon G.",
                location: "Detroit, MI",
                why: "My bones were achin' in the winter!",
                scopeLabel: "4-ton dual-fuel heat pump",
                testimonial:
                  "Pearl Edison helped us put our home in a better position with heating and cooling. The team was kind and very efficient. Thank you so much for your time and effort to make us comfortable in our home!",
              },
              arthur: {
                customerName: "Arthur M.",
                location: "Detroit, MI",
                why: "To get AC for the first time and reduce my bills.",
                scopeLabel: "2-ton dual-fuel heat pump",
                testimonial:
                  "Very happy with my new system. I'm 60 years old and never had air conditioning before. Just in time for summer. Go Pistons!",
                imageSrc: arthurImage,
              },
              debra: {
                customerName: "Debra A.",
                location: "Detroit, MI",
                why: "My bills were really hight in the winter.",
                scopeLabel: "2-ton dual-fuel heat pump",
                testimonial:
                  "My heating bill was $700 per month and I was always cold at my house. Pearl Edison helped me fixed the problem. My bills are much lower now!",
              },
            },
          },
        },
      },
    },
    "en-es": {
      step: {
        HomeConfirmationStep: {
          title: "Esta su casa?",
          description: "",
        },
      },
    },
  },
} as unknown as TennantConfig;
