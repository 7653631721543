import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { StyledDialog, classes } from "./styles";
import { VideoType } from "./constants";

interface VideoDialogProps {
  isOpen: boolean;
  onClose: () => void;
  videoType: VideoType;
  title?: string;
}

const VideoDialog: React.FC<VideoDialogProps> = ({
  title = "",
  isOpen,
  onClose,
  videoType,
}) => {
  const getVideoSource = (type: VideoType) => {
    switch (type) {
      case VideoType.PROCESS:
        return "path/to/process-video.mp4";
      case VideoType.SAMPLE:
        return "path/to/sample-video.mp4";
      case VideoType.ADVISOR:
        return "path/to/advisor-video.mp4";
      default:
        return "";
    }
  };
  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <DialogTitle className={classes.dialogHeader}>
        {title && (
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <video controls width="100%" src={getVideoSource(videoType)}>
          Your browser does not support this video.
        </video>
      </DialogContent>
    </StyledDialog>
  );
};

export default VideoDialog;
