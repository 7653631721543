import React from "react";
import { useTheme } from "@mui/material";

export const BelowGrade = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M62 67.5L109 37.5L156 67.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59 61.5L109 29.5L159 61.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149 63.5V97.5H69V63.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149 100.5V127.5H69V100.5"
        style={{ stroke: theme.palette.secondary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        strokeDasharray="1 2"
      />
      <path
        d="M140 99.5V103.939H133.106V109.646H126.787V115.354H119.894V121.061H113V125.5"
        style={{ stroke: theme.palette.secondary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        strokeDasharray="1 2"
      />
      <path
        d="M163 97.5H55"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default BelowGrade;
