import React from "react";
import { Box } from "@mui/material";
import { ResponsiveBar, BarDatum } from "@nivo/bar";

// Extend BarDatum to ensure compatibility with Nivo's requirements
interface ImportChartData extends BarDatum {
  month: string;
  monthlyBill: number;
  savings: number | string;
}

interface ImportChartProps {
  data: ImportChartData[];
  contrastColor?: string;
  displayType?: "currency" | "energy";
}

const ImpactChart: React.FC<ImportChartProps> = ({
  data,
  contrastColor = "#7CB519",
  displayType = "currency",
}) => (
  <Box sx={{ height: 300 }}>
    <ResponsiveBar<ImportChartData>
      data={data}
      keys={["monthlyBill", "savings"]}
      indexBy="month"
      valueScale={{ type: "linear" }}
      colors={["#EEEFEE", contrastColor]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      padding={0.2}
      margin={{ top: 40, right: 20, bottom: 30, left: 20 }}
      axisTop={null}
      axisRight={null}
      axisLeft={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
      }}
      enableGridY={false}
      enableLabel={false}
      barComponent={({ bar }) => {
        const value = Math.abs(bar.data.value as number);
        const displayText =
          displayType === "currency"
            ? `-$${value}`
            : `${bar.data.value >= 0 ? "+" : "-"}${value} kWh`;

        return (
          <g transform={`translate(${bar.x},${bar.y})`}>
            <rect
              x={0}
              y={0}
              width={bar.width}
              height={bar.height}
              fill={bar.data.id === "savings" ? contrastColor : "#EEEFEE"}
            />
            {bar.data.id === "savings" && (
              <text
                x={bar.width / 2}
                y={-8}
                textAnchor="middle"
                dominantBaseline="bottom"
                style={{
                  fontSize: 12,
                  fill: contrastColor,
                }}
              >
                {displayText}
              </text>
            )}
          </g>
        );
      }}
      legends={[]}
    />
  </Box>
);

export default ImpactChart;
