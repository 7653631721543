// Rooms
import Bathroom from "./Bathroom";
import Bedroom from "./Bedroom";
import Den from "./Den";
import DiningRoom from "./DiningRoom";
import Foyer from "./Foyer";
import Kitchen from "./Kitchen";
import LaundryRoom from "./LaundryRoom";
import LivingRoom from "./LivingRoom";
import MediaRoom from "./MediaRoom";
import Office from "./Office";

// floorSize
import Same from "./Same";
import Smaller from "./Smaller";
import MuchSmaller from "./MuchSmaller";

// basementType
import BelowGrade from "./BelowGrade";
import PartiallyBelowGrade from "./PartiallyBelowGrade";
import AboveGrade from "./AboveGrade";

// basementFinished
import FinishedBasement from "./FinishedBasement";
import PartiallyFinishedBasement from "./PartiallyFinishedBasement";
import UnfinishedBasement from "./UnfinishedBasement";

// atticType
import StairsAttic from "./StairsAttic";
import HatchAttic from "./HatchAttic";
import NoAttic from "./NoAttic";

// atticFinish
import FinishedAttic from "./FinishedAttic";
import PartiallyFinishedAttic from "./PartiallyFinishedAttic";
import UnfinishedAttic from "./UnfinishedAttic";

// garageType
import FullyDetached from "./FullyDetached";
import AttachedNoLivingSpace from "./AttachedNoLivingSpace";
import AttachedLivingSpace from "./AttachedLivingSpace";

// heatingEquipmentType
import BoilerWithRadiators from "./BoilerWithRadiators";
import Electric from "./Electric";
import FuranceWithDucts from "./FuranceWithDucts";
import MiniSplit from "./MiniSplit";

// coolingEquipmentType
import CentralAirConditioning from "./CentralAirConditioning";
import MiniSplitAirConditioning from "./MiniSplitAirConditioning";
import EvaporativeAirConditioning from "./EvaporativeAirConditioning";
import WindowUnitAirConditioning from "./WindowUnitAirConditioning";

// materials
import Stucco from "./Stucco";
import Vinyl from "./Vinyl";
import FiberCement from "./FiberCement";
import Brick from "./Brick";
import Wood from "./Wood";
import Stone from "./Stone";
import Metal from "./Metal";
import Drywall from "./Drywall";
import Plaster from "./Plaster";
import WoodPanel from "./WoodPanel";
import Concrete from "./Concrete";
import CeramicTile from "./CeramicTile";

// windowType
import SinglePane from "./SinglePane";
import DoublePane from "./DoublePane";
import TriplePane from "./TriplePane";

// projectType
import Heating from "./Heating";
import AirConditioning from "./AirConditioning";
import Weatherization from "./Weatherization";
import WaterHeating from "./WaterHeating";
import ResidentialSolar from "./ResidentialSolar";
import EVCharging from "./EVCharging";
import EnergyEfficientAppliances from "./EnergyEfficientAppliances";
import ElectricalUpgrades from "./ElectricalUpgrades";
import Checklist from "./Checklist";

export default {
  projectType: {
    heating_cooling: Heating,
    heating: Heating,
    air_conditioning: AirConditioning,
    weatherization: Weatherization,
    water_heating: WaterHeating,
    residential_solar: ResidentialSolar,
    ev_charging: EVCharging,
    energy_efficient_appliances: EnergyEfficientAppliances,
    electrical_upgrades: ElectricalUpgrades,
    im_not_sure: Checklist,
  },
  roomType: {
    BATHROOM: Bathroom,
    BEDROOM: Bedroom,
    DEN: Den,
    DINING_ROOM: DiningRoom,
    FOYER: Foyer,
    KITCHEN: Kitchen,
    LAUNDRY_ROOM: LaundryRoom,
    LIVING_ROOM: LivingRoom,
    MEDIA_ROOM: MediaRoom,
    OFFICE: Office,
  },
  floorSize: {
    second_floor_is_same_size: Same,
    second_floor_a_bit_smaller: Smaller,
    second_floor_a_lot_smaller: MuchSmaller,
  },
  basementType: {
    below_grade: BelowGrade,
    partially_below_grade: PartiallyBelowGrade,
    above_grade: AboveGrade,
  },
  basementFinished: {
    finished: FinishedBasement,
    partially_finished: PartiallyFinishedBasement,
    unfinished: UnfinishedBasement,
  },
  atticType: {
    permanent_stairs: StairsAttic,
    hatch: HatchAttic,
    no_attic: NoAttic,
  },
  atticFinish: {
    unfinished: UnfinishedAttic,
    partially_finished: PartiallyFinishedAttic,
    finished: FinishedAttic,
  },
  garageType: {
    fully_detached: FullyDetached,
    attached_with_no_living_space_above: AttachedNoLivingSpace,
    attached_with_living_space_above: AttachedLivingSpace,
  },
  heatingEquipmentType: {
    boiler_with_radiators: BoilerWithRadiators,
    furnace_with_ducts: FuranceWithDucts,
    electric_resistive_with_baseboards: Electric,
    heat_pump_mini_split: MiniSplit,
  },
  coolingEquipmentType: {
    central_air_conditioning: CentralAirConditioning,
    window_unit_air_conditioning: WindowUnitAirConditioning,
    evaporative_cooling: EvaporativeAirConditioning,
    heat_pump_mini_split: MiniSplitAirConditioning,
  },
  exteriorWalls: {
    stucco: Stucco,
    vinyl: Vinyl,
    fiber_cement: FiberCement,
    brick: Brick,
    wood: Wood,
    stone: Stone,
    metal: Metal,
  },
  interiorWalls: {
    stucco: Stucco,
    drywall: Drywall,
    plaster: Plaster,
    brick: Brick,
    wood_panel: WoodPanel,
    concrete: Concrete,
    ceramic_tile: CeramicTile,
  },
  windowType: {
    single: SinglePane,
    double: DoublePane,
    triple: TriplePane,
  },
};
