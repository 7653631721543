export const UTILITIES = {
  "26001": {
    electricity: ["Consumers Energy", "Presque Isle Electric & Gas"],
    natural_gas: ["DTE Energy", "Presque Isle Electric & Gas Co-op"],
  },
  "26005": {
    electricity: [
      "Consumers Energy",
      "Indiana Michigan Power",
      "Great Lakes Energy",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy", "Michigan Gas Utilities"],
  },
  "26009": {
    electricity: ["Consumers Energy", "Great Lakes Energy"],
    natural_gas: ["DTE Energy"],
  },
  "26011": {
    electricity: ["Consumers Energy"],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26015": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "HomeWorks Tri-County Electric Cooperative",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy", "Michigan Gas Utilities"],
  },
  "26017": {
    electricity: ["Consumers Energy", "City of Bay City"],
    natural_gas: ["Consumers Energy"],
  },
  "26019": {
    electricity: ["Consumers Energy", "Cherryland Electric"],
    natural_gas: ["DTE Energy", "Superior Energy"],
  },
  "26023": {
    electricity: [
      "Consumers Energy",
      "Coldwater Board of Public Utilities",
      "Union City Electric Department",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy", "Michigan Gas Utilities"],
  },
  "26025": {
    electricity: [
      "Consumers Energy",
      "Presque Isle Electric & Gas",
      "City of Marshall",
    ],
    natural_gas: [
      "Consumers Energy",
      "SEMCO Energy",
      "Michigan Gas Utilities",
      "Presque Isle Electric & Gas Co-op",
    ],
  },
  "26029": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "City of Charlevoix",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26031": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "Presque Isle Electric & Gas",
    ],
    natural_gas: ["DTE Energy", "Presque Isle Electric & Gas Co-op"],
  },
  "26035": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "HomeWorks Tri-County Electric Cooperative",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26037": {
    electricity: [
      "Consumers Energy",
      "HomeWorks Tri-County Electric Cooperative",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26039": {
    electricity: ["Consumers Energy", "Great Lakes Energy"],
    natural_gas: ["DTE Energy"],
  },
  "26045": {
    electricity: [
      "Consumers Energy",
      "HomeWorks Tri-County Electric Cooperative",
      "City of Eaton Rapids",
    ],
    natural_gas: ["Consumers Energy"],
  },
  "26047": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "Presque Isle Electric & Gas",
      "City of Petoskey",
      "City of Harbor Springs",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26049": {
    electricity: ["Consumers Energy"],
    natural_gas: ["Consumers Energy"],
  },
  "26051": {
    electricity: ["Consumers Energy"],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26055": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "Cherryland Electric",
      "Traverse City Light & Power",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26057": {
    electricity: [
      "Consumers Energy",
      "HomeWorks Tri-County Electric Cooperative",
      "City of St. Louis",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26059": {
    electricity: [
      "Consumers Energy",
      "DTE Energy",
      "Hillsdale Board of Public Utilities",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy", "Michigan Gas Utilities"],
  },
  "26065": {
    electricity: [
      "Consumers Energy",
      "DTE Energy",
      "HomeWorks Tri-County Electric Cooperative",
      "Lansing Board of Water & Light",
    ],
    natural_gas: ["Consumers Energy"],
  },
  "26067": {
    electricity: [
      "Consumers Energy",
      "HomeWorks Tri-County Electric Cooperative",
      "City of Portland",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26069": {
    electricity: ["Consumers Energy"],
    natural_gas: ["DTE Energy"],
  },
  "26073": {
    electricity: [
      "Consumers Energy",
      "HomeWorks Tri-County Electric Cooperative",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26075": {
    electricity: [
      "Consumers Energy",
      "HomeWorks Tri-County Electric Cooperative",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy"],
  },
  "26077": {
    electricity: [
      "Consumers Energy",
      "Indiana Michigan Power",
      "Midwest Energy & Communications",
    ],
    natural_gas: ["Consumers Energy"],
  },
  "26079": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "Cherryland Electric",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26081": {
    electricity: [
      "Consumers Energy",
      "DTE Energy",
      "Great Lakes Energy",
      "Lowell Light & Power",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26085": {
    electricity: ["Consumers Energy", "Great Lakes Energy"],
    natural_gas: ["DTE Energy"],
  },
  "26087": {
    electricity: ["Consumers Energy", "DTE Energy"],
    natural_gas: ["Consumers Energy", "SEMCO Energy"],
  },
  "26089": {
    electricity: ["Consumers Energy", "Cherryland Electric"],
    natural_gas: ["DTE Energy"],
  },
  "26091": {
    electricity: [
      "Consumers Energy",
      "DTE Energy",
      "Upper Peninsula Power Company (UPPCO)",
      "Midwest Energy & Communications",
      "Village of Clinton",
    ],
    natural_gas: ["Consumers Energy", "Michigan Gas Utilities", "Citizens Gas"],
  },
  "26093": {
    electricity: ["Consumers Energy", "DTE Energy"],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26101": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "Cherryland Electric",
    ],
    natural_gas: ["DTE Energy", "Superior Energy"],
  },
  "26105": {
    electricity: ["Consumers Energy", "Great Lakes Energy"],
    natural_gas: ["DTE Energy"],
  },
  "26107": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "HomeWorks Tri-County Electric Cooperative",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26111": {
    electricity: ["Consumers Energy"],
    natural_gas: ["Consumers Energy"],
  },
  "26113": {
    electricity: ["Consumers Energy", "Great Lakes Energy"],
    natural_gas: ["Consumers Energy"],
  },
  "26115": {
    electricity: ["Consumers Energy", "DTE Energy"],
    natural_gas: ["Consumers Energy", "DTE Energy", "Michigan Gas Utilities"],
  },
  "26117": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "HomeWorks Tri-County Electric Cooperative",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26119": {
    electricity: [
      "Consumers Energy",
      "Alpena Power",
      "Great Lakes Energy",
      "Presque Isle Electric & Gas",
    ],
    natural_gas: ["DTE Energy", "Presque Isle Electric & Gas Co-op"],
  },
  "26121": {
    electricity: ["Consumers Energy", "Great Lakes Energy"],
    natural_gas: ["DTE Energy", "Michigan Gas Utilities"],
  },
  "26123": {
    electricity: ["Consumers Energy", "Great Lakes Energy"],
    natural_gas: ["DTE Energy"],
  },
  "26125": {
    electricity: ["Consumers Energy", "DTE Energy"],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26127": {
    electricity: ["Consumers Energy", "Great Lakes Energy", "City of Hart"],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26129": {
    electricity: ["Consumers Energy"],
    natural_gas: ["DTE Energy"],
  },
  "26133": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "HomeWorks Tri-County Electric Cooperative",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy", "SEMCO Energy"],
  },
  "26135": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "Presque Isle Electric & Gas",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26137": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "Presque Isle Electric & Gas",
    ],
    natural_gas: ["DTE Energy", "Presque Isle Electric & Gas Co-op"],
  },
  "26139": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "Holland Board of Public Works",
      "Grand Haven Board of Light & Power",
      "Zeeland Board of Public Works",
    ],
    natural_gas: [
      "Consumers Energy",
      "DTE Energy",
      "SEMCO Energy",
      "Michigan Gas Utilities",
    ],
  },
  "26141": {
    electricity: [
      "Consumers Energy",
      "Alpena Power",
      "Presque Isle Electric & Gas",
    ],
    natural_gas: ["DTE Energy", "Presque Isle Electric & Gas Co-op"],
  },
  "26143": {
    electricity: ["Consumers Energy"],
    natural_gas: ["DTE Energy"],
  },
  "26145": {
    electricity: ["Consumers Energy"],
    natural_gas: ["Consumers Energy"],
  },
  "26149": {
    electricity: [
      "Consumers Energy",
      "Indiana Michigan Power",
      "Midwest Energy & Communications",
      "City of Sturgis",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy", "Michigan Gas Utilities"],
  },
  "26155": {
    electricity: ["Consumers Energy"],
    natural_gas: ["Consumers Energy"],
  },
  "26157": {
    electricity: ["Consumers Energy", "DTE Energy", "Thumb Electric"],
    natural_gas: ["Consumers Energy", "SEMCO Energy"],
  },
  "26159": {
    electricity: [
      "Consumers Energy",
      "Indiana Michigan Power",
      "Midwest Energy & Communications",
      "City of South Haven",
      "Village of Paw Paw",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy", "Michigan Gas Utilities"],
  },
  "26161": {
    electricity: [
      "Consumers Energy",
      "DTE Energy",
      "Chelsea Electric & Water Department",
    ],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26165": {
    electricity: [
      "Consumers Energy",
      "Great Lakes Energy",
      "Cherryland Electric",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26013": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Wisconsin Electric",
      "Ontonagon County REA",
      "Village of L'Anse",
      "Village of Baraga",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy"],
  },
  "26021": {
    electricity: [
      "Indiana Michigan Power",
      "Midwest Energy & Communications",
      "Niles Utilities Department",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy", "Michigan Gas Utilities"],
  },
  "26027": {
    electricity: [
      "Indiana Michigan Power",
      "Midwest Energy & Communications",
      "City of Dowagiac",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy"],
  },
  "26063": {
    electricity: ["DTE Energy", "Thumb Electric", "Sebewaing Light & Water"],
    natural_gas: ["Consumers Energy"],
  },
  "26099": {
    electricity: ["DTE Energy"],
    natural_gas: ["Consumers Energy", "SEMCO Energy"],
  },
  "26109": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Wisconsin Electric",
      "Upper Michigan Energy Resources (UMERC)",
      "Alger Delta",
      "City of Stephenson",
      "Daggett Electric Department",
    ],
    natural_gas: [
      "Consumers Energy",
      "DTE Energy",
      "Upper Michigan Energy Resources (UMERC)",
    ],
  },
  "26147": {
    electricity: ["DTE Energy"],
    natural_gas: ["Consumers Energy", "SEMCO Energy"],
  },
  "26151": {
    electricity: [
      "DTE Energy",
      "Thumb Electric",
      "Presque Isle Electric & Gas",
      "Croswell Municipal Light & Power",
    ],
    natural_gas: ["Consumers Energy", "SEMCO Energy"],
  },
  "26163": {
    electricity: ["DTE Energy", "Wyandotte Municipal Services"],
    natural_gas: ["Consumers Energy", "DTE Energy"],
  },
  "26003": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Wisconsin Electric",
      "Alger Delta",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26007": {
    electricity: ["Alpena Power", "Presque Isle Electric & Gas"],
    natural_gas: ["DTE Energy", "Presque Isle Electric & Gas Co-op"],
  },
  "26033": {
    electricity: ["Cloverland Electric"],
    natural_gas: ["DTE Energy"],
  },
  "26041": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Wisconsin Electric",
      "Upper Michigan Energy Resources (UMERC)",
      "Cloverland Electric",
      "Alger Delta",
      "City of Escanaba",
      "City of Gladstone",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26043": {
    electricity: ["Wisconsin Electric", "City of Norway"],
    natural_gas: ["DTE Energy"],
  },
  "26071": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Wisconsin Electric",
      "Upper Michigan Energy Resources (UMERC)",
      "City of Crystal Falls",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26083": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Ontonagon County REA",
    ],
    natural_gas: ["DTE Energy"],
  },
  "26095": {
    electricity: ["Cloverland Electric", "Newberry Water and Light Board"],
    natural_gas: ["DTE Energy", "SEMCO Energy", "Xcel Energy"],
  },
  "26103": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Wisconsin Electric",
      "Alger Delta",
      "Marquette Board of Light & Power",
      "Negaunee Electric Department",
    ],
    natural_gas: ["DTE Energy", "SEMCO Energy"],
  },
  "26053": {
    electricity: [
      "Wisconsin Electric",
      "Xcel Energy",
      "Bayfield Electric",
      "City of Wakefield",
    ],
    natural_gas: ["SEMCO Energy", "Xcel Energy"],
  },
  "26061": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Wisconsin Electric",
      "Ontonagon County REA",
    ],
    natural_gas: ["SEMCO Energy"],
  },
  "26097": {
    electricity: ["Cloverland Electric", "Presque Isle Electric & Gas"],
    natural_gas: ["SEMCO Energy"],
  },
  "26131": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Wisconsin Electric",
      "Xcel Energy",
      "Ontonagon County REA",
    ],
    natural_gas: ["SEMCO Energy", "Xcel Energy"],
  },
  "26153": {
    electricity: [
      "Upper Peninsula Power Company (UPPCO)",
      "Cloverland Electric",
      "Alger Delta",
    ],
    natural_gas: ["SEMCO Energy"],
  },
};
