import { Room } from "./home";

export const initialOnboardingPayload = {
  ceilingHeight: 8,
  upgrades: [],
  issues: [],
  homeQuality: [],
  homeQualityMoreInfo: "",
  gasUtility: "",
  electricUtility: "",
  heatingEquipmentType: [],
  heatingEquipmentTypeMoreInfo: "",
  coolingEquipmentType: [],
  coolingEquipmentTypeMoreInfo: "",
  temperatureSummer: 72,
  temperatureWinter: 68,
  upgradesMoreInfo: "",
  whatsImportantToYou: [],
  whatsImportantToYouInfo: "",
  user: {
    supabaseId: "",
    firstName: "",
    lastName: "",
    email: "",
    formatted_address: "",
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    fipsCountyCode: "",
    communicationPreference: "",
    phoneNumber: "",
    lat: undefined,
    lng: undefined,
    channelId: "pearl",
  },
};

export interface OnboardingUser {
  supabaseId: string;
  firstName: string;
  lastName: string;
  email: string;
  formatted_address: string;
  address: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  fipsCountyCode: string;
  communicationPreference: string;
  phoneNumber: string;
  lat: number;
  lng: number;
  channelId: string;
}

export interface OnboardingPayload {
  heatingEquipmentType: string[];
  heatingEquipmentTypeMoreInfo: string;
  coolingEquipmentType: string[];
  coolingEquipmentTypeMoreInfo: string;
  atticFinish: string;
  atticType: string;
  basementFinished: string;
  basementType: string;
  draftyStuffy: string;
  exteriorWalls: string;
  fuelSource: string;
  floorSize: string;
  garageType: string;
  houseHoldIncome: number;
  interiorWalls: string;
  temperatureSummer: number;
  temperatureWinter: number;
  upgradesMoreInfo: string;
  windowType: string;
  homeType: string;
  basement: string;
  attic: string;
  garage: string;
  sqFootage: number;
  yearBuilt: number;
  ceilingHeight: number;
  numBedrooms: number;
  numBathrooms: number;
  aboveGroundStories: number;
  user: Partial<OnboardingUser>;
  rooms: Partial<Room>[] | undefined;
  upgrades: string[];
  issues: string[];
  homeQuality: string[];
  homeQualityMoreInfo: string;
  whyAreYouShoppingToday: string;
  whatsImportantToYou: string[];
  ownOrRent: string;
  whatsImportantToYouInfo: string;
  peopleLiveInHome: number;
  communicationPreference: string;
  phoneNumber: string;
  rebateCheck?: boolean;
}

export interface Onboarding {
  id: string;
  jobId: string;
  data?: OnboardingPayload;
  stepJSON?: string;
  dataJSON?: string;
  createdAt: Date;
  updatedAt: Date;
  status?: string;
}
