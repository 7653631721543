import React, { ReactNode, useRef } from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import isFunction from "lodash/isFunction";
import toNumber from "lodash/toNumber";

import assets from "../assets";

const CARD_HEIGHT_WITH_IMAGE = 240;
const CARD_HEIGHT_NO_IMAGE = "auto";
const IMAGE_SECTION_HEIGHT = 160;
const LABEL_SECTION_HEIGHT = 80;

const Empty = () => {
  return <></>;
};

const Component = ({
  checked,
  // option,
  props,
  name,
  optionValue,
  optionLabel,
  image,
  noImage,
  fontSize,
  showRadioCircles = true,
  compressed = false,
  index,
  total,
  borderless = false,
  imageBackgroundColor,
  imagePadding,
  imageWidth,
}: {
  checked: boolean;
  inverted: boolean;
  RadioLabel: ReactNode;
  padding: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any;
  noImage: boolean;
  props: WidgetProps & {
    options?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      enumOptions?: any[];
    };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name: string;
  optionValue: string;
  optionLabel: string;
  image?: React.FC;
  borderRadius?: string | number;
  fontSize?: string;
  placement?: string;
  showRadioCircles?: boolean;
  compressed?: boolean;
  index?: number;
  total?: number;
  borderless?: boolean;
  setSlideDirection: (direction: number) => void;
  imageBackgroundColor?: string;
  imagePadding?: number;
  imageWidth?: string | number;
}) => {
  const theme = useTheme();
  const ref = useRef<HTMLInputElement>(null);
  const Image = image as React.FC<{ styles: object }>;
  const cardHeight = noImage ? CARD_HEIGHT_NO_IMAGE : CARD_HEIGHT_WITH_IMAGE;

  // Helper function to determine border radius
  const getBorderRadius = () => {
    if (!compressed) return 2;
    if (typeof index === "number" && typeof total === "number") {
      if (index === 0) return "8px 8px 0 0";
      if (index === total - 1) return "0 0 8px 8px";
    }
    return 0;
  };

  return (
    <Box
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        const _ref = ref.current;
        if (_ref?.contains(event.target as Node)) return;
        _ref?.click();
      }}
      sx={{
        cursor: "pointer",
        borderRadius: getBorderRadius(),
        overflow: "hidden",
        height: cardHeight,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        transition: "all 0.2s",
        // Updated border logic
        borderBottom: borderless
          ? "none"
          : compressed && typeof total === "number" && index !== total - 1
            ? `1px solid ${theme.palette.divider}`
            : "none",
        // Only show selection border for non-compressed or image cases
        ...(checked &&
          (!compressed || !noImage) && {
            border: `2px solid ${theme.palette.primary.light}`,
          }),
        "&:hover": {
          transform: compressed ? "none" : "scale(1.02)",
          backgroundColor: compressed ? theme.palette.action.hover : undefined,
        },
      }}
    >
      <FormControlLabel
        value={optionValue}
        ref={ref}
        control={
          <Radio
            sx={{
              width: 0,
              height: 0,
              padding: 0,
              margin: 0,
              opacity: 0,
              position: "absolute",
            }}
          />
        }
        label={
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              transition: "all 0.2s",
            }}
          >
            {!noImage && (
              <Box
                sx={{
                  backgroundColor:
                    imageBackgroundColor || theme.palette.grey[900],
                  padding: imagePadding,
                  width: "100%",
                  height: IMAGE_SECTION_HEIGHT,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  alignItems="center"
                  sx={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    styles={{
                      width: imageWidth || "100%",
                      height: "100%",
                      objectFit: "cover",
                      fill: theme.palette.primary.main,
                    }}
                  />
                </Stack>
              </Box>
            )}

            <Box
              sx={{
                // Only apply background color when there's an image or when not compressed
                backgroundColor:
                  checked && (!compressed || !noImage)
                    ? `${theme.palette.primary.main}15`
                    : theme.palette.primary.contrastText,
                padding: noImage ? 2 : 3,
                width: "100%",
                height: LABEL_SECTION_HEIGHT,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {noImage && showRadioCircles && (
                <Radio
                  checked={checked}
                  sx={{
                    padding: 0,
                    marginRight: 1,
                    color: checked
                      ? theme.palette.primary.light
                      : theme.palette.text.primary,
                    "&.Mui-checked": {
                      color: theme.palette.primary.light,
                    },
                  }}
                />
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize,
                    fontWeight: 400,
                    textAlign: "left",
                    color: checked
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  }}
                >
                  {theme.t(`labels.${props.name}.enum.${optionValue}.title`) ||
                    theme.t(`labels.${name}.enum.${optionValue}`) ||
                    optionLabel ||
                    optionValue}
                </Typography>
                {theme.t(`labels.${name}.enum.${optionValue}.description`) ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textAlign: "left",
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {theme.t(`labels.${name}.enum.${optionValue}.description`)}
                  </Typography>
                ) : null}
                {/* Add subtitle if needed */}
                {!noImage && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      marginTop: 0.5,
                    }}
                  >
                    {theme.t(
                      `labels.${props.name}.enum.${optionValue}.subtitle`
                    ) || theme.t(`labels.${name}.enum.${optionValue}.subtitle`)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        }
        sx={{
          margin: 0,
          padding: 0,
          width: "100%",
          height: cardHeight,
          "& .MuiFormControlLabel-label": {
            width: "100%",
            margin: 0,
            padding: 0,
          },
        }}
      />
    </Box>
  );
};

export const RadioImages = (props: WidgetProps) => {
  const theme = useTheme();
  const name = props.name as keyof typeof assets;
  const {
    spacing = 2,
    padding = 1,
    borderRadius = 10,
    backgroundColor = "initial",
    titleKey = "",
    inverted = false,
    stacked = false,
    width = ["100%"],
    xs = 6,
    sm = 4,
    md = 4,
    lg = 4,
    fontSize,
    placement,
    containerBorderRadius,
    images = true,
    showRadioCircles = true,
    compressed = false,
    borderless = false,
    nextOnChange = false,
    label = false,
    imageBackgroundColor,
    imagePadding = 3,
    imageWidth = "60%",
  } = props.uiSchema ?? {};

  const { payload, setPayload, setSlideDirection } = props.formContext;
  const {
    xs: childrenXS,
    sm: childrenSM,
    md: childrenMD,
    lg: childrenLG,
    minWidth: childrenMinWidth,
  } = props.uiSchema?.children ?? {};
  return (
    <Stack
      sx={{ backgroundColor, padding: 2, borderRadius: containerBorderRadius }}
      justifyContent="center"
      alignItems="center"
    >
      {label ? (
        theme.t(`labels.${titleKey}.${name}.label`) ||
        theme.t(`labels.${name}.label`) ? (
          <Typography variant="h2" mb={2} fontWeight="bold">
            {theme.t(`labels.${titleKey}.${name}.label`) ||
              theme.t(`labels.${name}.label`) ||
              name}
          </Typography>
        ) : null
      ) : null}
      <RadioGroup
        value={props.value}
        onChange={(event) => {
          if (props.schema?.type === "number") {
            props.onChange(toNumber(event.target.value));
          } else {
            props.onChange(event.target.value);
          }
          if (nextOnChange) {
            setTimeout(() => {
              setPayload({
                ...payload,
                [props.name]:
                  props.schema?.type === "number"
                    ? toNumber(event.target.value)
                    : event.target.value,
              });
              setSlideDirection(1);
            }, 50);
          }
        }}
        sx={{
          width,
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <Grid
          container
          spacing={compressed ? 0 : spacing}
          padding={compressed ? 0 : padding}
          direction={stacked ? "column" : "row"}
          alignItems="stretch"
          justifyContent="center"
        >
          {props?.options?.enumOptions?.map((option, index) => {
            const enumOptions = props.options?.enumOptions;
            const total = enumOptions ? enumOptions.length : 0;
            const value = option.value as keyof (typeof assets)[typeof name];
            const label = option.label ?? value;
            const checked = value === props.value;

            let Image = Empty as React.FC | undefined;
            let noImage = false;
            if (images) {
              Image = assets?.[name]?.[value] as React.FC | undefined;
              if (!isFunction(Image)) {
                Image = Empty;
                noImage = true;
              }
            } else {
              noImage = true;
            }
            const RadioLabel = <Stack sx={{ display: "none" }} />;
            return (
              <Grid
                item
                xs={stacked ? 1 : (childrenXS ?? xs)}
                sm={stacked ? 1 : (childrenSM ?? sm)}
                md={stacked ? 1 : (childrenMD ?? md)}
                lg={stacked ? 1 : (childrenLG ?? lg)}
                sx={{
                  minWidth: childrenMinWidth,
                  height: "100%",
                  display: "flex",
                  padding: compressed ? 0 : undefined,
                }}
                key={value}
                alignItems="center"
                justifyContent="center"
              >
                <Component
                  padding={padding}
                  option={option}
                  checked={checked}
                  inverted={inverted}
                  RadioLabel={RadioLabel}
                  props={props}
                  value={
                    props.schema?.type === "number" ? String(value) : value
                  }
                  noImage={noImage}
                  name={name}
                  optionValue={value}
                  optionLabel={label}
                  image={Image}
                  borderRadius={borderRadius}
                  fontSize={fontSize}
                  placement={placement}
                  showRadioCircles={showRadioCircles}
                  compressed={compressed}
                  index={index}
                  total={total}
                  borderless={borderless}
                  setSlideDirection={setSlideDirection}
                  imageBackgroundColor={imageBackgroundColor}
                  imagePadding={imagePadding}
                  imageWidth={imageWidth}
                />
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </Stack>
  );
};

export default RadioImages;
