import { styled } from "@mui/material";

const PREFIX = "LetsGetStartedStep";

export const classes = {
  boxContainer: `${PREFIX}-boxContainer`,
  icon: `${PREFIX}-icon`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

export const Root = styled("div")(({ theme }) => ({
  maxWidth: 1000, // the designs show the content taking up more vertical space than horizontal...
  marginBottom: theme.spacing(4),
  padding: theme.spacing(0, 2),
  [`& .${classes.boxContainer}`]: {
    borderRadius: 8,
    padding: theme.spacing(2),
    height: "100%",
    backgroundColor: theme.palette.common.white,
  },

  [`& .${classes.icon}`]: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(2),
    flexShrink: 0,
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },

  [`& .${classes.buttonContainer}`]: {
    "& .MuiButton-root": {
      minWidth: 160,
    },
  },
}));
