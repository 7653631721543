import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PublicIcon from "@mui/icons-material/Public";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";

import { InlineWidget } from "react-calendly";

import { SlideComponent } from "@library/components/Sherpa/functions";
import { Estimate } from "@library/domain/estimate";

import {
  CALENDLY_URL_HOME_CONSULT,
  formatCurrency,
  formatNumber,
} from "@library/common";
import { Close } from "@mui/icons-material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "primary.main",
  color: "white",
  boxShadow: 24,
  p: 4,
};

export const Title = ({
  stepName,
  payload,
  slideState,
  color,
}: SlideComponent) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <React.Fragment>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 4 }}
      >
        {theme.t(`step.${stepName}.title`, { ...payload, ...slideState }) ? (
          theme
            .t(`step.${stepName}.title`, { ...payload, ...slideState })
            .split("\n")
            .map((text, i) => {
              return (
                <Typography
                  key={i}
                  gutterBottom={false}
                  textAlign="center"
                  role="heading"
                  style={{
                    fontWeight: theme.typography.fontWeightBold,
                    marginTop: i > 0 ? 5 : theme.spacing(1, "auto"),
                  }}
                  sx={{
                    px: 2,
                    lineHeight: 1,
                    width: ["100%", 600, 900],
                    fontSize: ["1.5rem", "2rem", "2.5rem"],
                    display: "inline-block",
                    fontWeight: "bold",
                    textAlign: "center",
                    color,
                  }}
                >
                  {text}{" "}
                  {theme.t(`step.${stepName}.whyDescription`) ? (
                    <Button
                      variant="contained"
                      onClick={handleOpen}
                      sx={{
                        borderRadius: 10,
                        padding: 0,
                        minWidth: 0,
                        width: "25px",
                        height: "25px",
                        backgroundColor: "primary.light",
                      }}
                    >
                      ?
                    </Button>
                  ) : null}
                </Typography>
              );
            })
        ) : (
          <Typography
            gutterBottom={false}
            textAlign="center"
            role="heading"
            style={{
              fontWeight: theme.typography.fontWeightBold,
              marginTop: theme.spacing(1, "auto"),
            }}
            sx={{
              fontSize: ["1.5rem", "2rem", "2.5rem"],
              display: "inline-block",
              fontWeight: "bold",
              px: 2,
              textAlign: "center",
              color,
              width: ["100%", 600, 900],
            }}
          >
            {stepName}
          </Typography>
        )}
      </Stack>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {theme.t(`step.${stepName}.whyTitle`) || "Why are we asking?"}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {theme.t(`step.${stepName}.whyDescription`)}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export const Description = ({ stepName }: SlideComponent) => {
  const theme = useTheme();
  if (!theme.t(`step.${stepName}.description`)) return null;
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Typography
        variant="h4"
        sx={{
          display: "inline-block",
          fontWeight: "400",
          marginTop: 1,
          marginBottom: 2,
          width: ["100%", 600, 900],
          color: theme.palette.text.secondary,
          textAlign: ["center"],
          px: [2, 0],
          fontSize: ["0.8rem", "1.1rem !important"],
        }}
      >
        {theme.t(`step.${stepName}.description`)}
      </Typography>
    </Box>
  );
};

interface Estimates {
  [key: string]: Estimate;
}

const baseLanguageLocation = "step.SideBar";

export const Sidebar = ({ slideState }: SlideComponent) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState<string>("");

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  // Get all estimates
  const estimates: Estimates = slideState?.estimates || {};
  const estimateArray = Object.entries(estimates);

  // Set initial selected estimate if not set
  useEffect(() => {
    if (!selectedEstimate && estimateArray.length > 0) {
      setSelectedEstimate(estimateArray[0][0]); // Set first estimate as default
    }
  }, [estimateArray, selectedEstimate]);

  // Get current estimate data
  const currentEstimate = estimates[selectedEstimate]?.data || {};
  const estimatedCost = currentEstimate.installedCostTotal ?? "N/A";
  const billImpact = currentEstimate.billImpactEstimate ?? "N/A";
  const climateImpact = currentEstimate.climateImpactEstimate
    ? Math.ceil(currentEstimate.climateImpactEstimate)
    : "N/A";

  const handleEstimateChange = (event: SelectChangeEvent<string>) => {
    setSelectedEstimate(event.target.value);
  };

  return (
    <Stack
      sx={{
        backgroundColor: "rgb(239, 239, 239)",
        boxShadow: isExpanded
          ? "0px 0px 50px 10px rgba(239,239,239,0.5)"
          : "0px 0px 10px 20px rgba(239,239,239,1.0)",
      }}
    >
      {/* Header */}
      {!isExpanded && (
        <Box
          onClick={toggleExpanded}
          sx={{
            gap: 1,
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
            {theme.t(`${baseLanguageLocation}.collapsed.title`)}
          </Typography>
          <SettingsInputAntennaIcon
            sx={{ fontSize: 15, color: theme.palette.text.secondary }}
          />
        </Box>
      )}

      {/* Collapsed State */}
      {!isExpanded && (
        <Stack
          onClick={toggleExpanded}
          sx={{
            p: 1.5,
            cursor: "pointer",
            borderRadius: "8px",
            width: "100%",
            border: `1px solid ${theme.palette.divider}`,
            display: ["none", "none", "inherit"],
          }}
        >
          <Stack spacing={1}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <PaymentsIcon
                sx={{ color: theme.palette.text.secondary, fontSize: 15 }}
              />
              <Typography sx={{ fontSize: 14 }}>
                {theme.t(
                  `${baseLanguageLocation}.collapsed.estimatedCost.label`
                )}
                : {formatCurrency(estimatedCost)}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <ReceiptLongIcon
                sx={{ color: theme.palette.text.secondary, fontSize: 15 }}
              />
              <Typography sx={{ fontSize: 14 }}>
                {theme.t(`${baseLanguageLocation}.collapsed.billImpact.label`)}:{" "}
                {formatCurrency(billImpact, {
                  arrow: true,
                  showNegative: false,
                })}
                {theme.t(
                  `${baseLanguageLocation}.collapsed.billImpact.perYear`
                )}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <PublicIcon
                sx={{ color: theme.palette.text.secondary, fontSize: 15 }}
              />
              <Typography sx={{ fontSize: 14 }}>
                {theme.t(
                  `${baseLanguageLocation}.collapsed.climateImpact.label`
                )}
                :{" "}
                {formatNumber(climateImpact, {
                  arrow: true,
                  showNegative: false,
                })}{" "}
                {theme.t(
                  `${baseLanguageLocation}.collapsed.climateImpact.unit`
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      )}

      {/* Expanded State */}
      {isExpanded && (
        <Stack
          onClick={(e) => e.stopPropagation()} // Prevent closing when interacting with select
          sx={{
            width: 300,
            cursor: "pointer",
            backgroundColor: theme.palette.primary.contrastText,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          {/* Purple Header */}
          <Box
            sx={{
              bgcolor: theme.palette.primary.light,
              p: 1,
              gap: 1,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={toggleExpanded} // Keep header clickable for closing
          >
            <Box sx={{ gap: 1, display: "flex", alignItems: "center" }}>
              <SettingsInputAntennaIcon
                sx={{ color: theme.palette.common.white }}
              />
              <Typography variant="subtitle1">
                {theme.t(`${baseLanguageLocation}.expanded.title`)}
              </Typography>
            </Box>
            <Close sx={{ color: theme.palette.primary.contrastText }} />
          </Box>

          {/* Content */}
          <Stack sx={{ p: 1 }}>
            {/* System Selection */}
            <Box sx={{ mt: 1 }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="when-using-select-label">
                  {theme.t(
                    `${baseLanguageLocation}.expanded.systemSelection.label`
                  )}
                </InputLabel>
                <Select
                  labelId="when-using-select-label"
                  label={theme.t(
                    `${baseLanguageLocation}.expanded.systemSelection.label`
                  )}
                  fullWidth
                  value={selectedEstimate}
                  onChange={handleEstimateChange}
                  size="small"
                >
                  {estimateArray.map(([key, estimate]) => (
                    <MenuItem key={key} value={key}>
                      {estimate.v1.newSystemType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Estimated Cost */}
            <Box
              sx={{
                mt: 1,
                bgcolor: `${theme.palette.primary.main}15`,
                p: 1,
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  }}
                >
                  {theme.t(
                    `${baseLanguageLocation}.expanded.estimatedCost.title`
                  )}
                </Typography>
                {/* <IconButton size="small">
                  <AddIcon />
                </IconButton> */}
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <PaymentsIcon
                  sx={{ fontSize: 25, color: theme.palette.text.secondary }}
                />
                <Box sx={{ px: 1 }}>
                  <Typography sx={{ mt: 0.5, fontSize: 14 }}>
                    {formatCurrency(estimatedCost)}
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      display: "block",
                    }}
                    variant="caption"
                    color="text.secondary"
                  >
                    {theme.t(
                      `${baseLanguageLocation}.expanded.estimatedCost.subtitle`
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            {/* Annual Bill Impact */}
            <Box
              sx={{
                mt: 1,
                bgcolor: `${theme.palette.primary.main}15`,
                p: 1,
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  }}
                >
                  {theme.t(`${baseLanguageLocation}.expanded.billImpact.title`)}
                </Typography>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ReceiptLongIcon
                  sx={{ fontSize: 25, color: theme.palette.text.secondary }}
                />
                <Box sx={{ px: 1 }}>
                  <Typography sx={{ mt: 0.5, fontSize: 14 }}>
                    {formatCurrency(billImpact, {
                      arrow: true,
                      showNegative: false,
                    })}{" "}
                    {theme.t(
                      `${baseLanguageLocation}.expanded.billImpact.perYear`
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      display: "block",
                    }}
                    variant="caption"
                    color="text.secondary"
                  >
                    {theme.t(
                      `${baseLanguageLocation}.expanded.billImpact.subtitle`
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            {/* Climate Impact */}
            <Box
              sx={{
                mt: 1,
                bgcolor: `${theme.palette.primary.main}15`,
                p: 1,
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  }}
                >
                  {theme.t(
                    `${baseLanguageLocation}.expanded.climateImpact.title`
                  )}
                </Typography>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <PublicIcon
                  sx={{ fontSize: 25, color: theme.palette.text.secondary }}
                />
                <Box sx={{ px: 1 }}>
                  <Typography sx={{ mt: 1, fontSize: 14 }}>
                    {formatNumber(climateImpact, {
                      arrow: true,
                      showNegative: false,
                    })}{" "}
                    {theme.t(
                      `${baseLanguageLocation}.expanded.climateImpact.unit`
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      display: "block",
                    }}
                    variant="caption"
                    color="text.secondary"
                  >
                    {theme.t(
                      `${baseLanguageLocation}.expanded.climateImpact.subtitle`
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export const CalendlyVirtualConsultation = () => {
  return (
    <Stack sx={{ width: "100%" }}>
      <InlineWidget url={CALENDLY_URL_HOME_CONSULT} />
    </Stack>
  );
};
