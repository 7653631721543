import React, { useState } from "react";
import { Box, Typography, Button, Grid, Stack, useTheme } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Root, classes } from "./styles";
import Checklist from "@library/components/form/assets/Checklist";
import Phone from "@library/assets/Phone";
import Homestead from "@library/assets/Homestead";
import Agreement from "@library/assets/Agreement";
import Contract from "@library/assets/Contract";
import VideoDialog from "@library/components/VideoDialog";
import { VideoType } from "@library/components/VideoDialog/constants";

const baseLanguageLocation = "step.LetsGetStartedStep";

interface LetsGetStartedStepProps {
  setSlideDirection: (direction: number) => void;
}

const LetsGetStartedStep: React.FC<LetsGetStartedStepProps> = () => {
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<VideoType>(
    VideoType.PROCESS
  );

  const theme = useTheme();

  const openVideoDialog = (videoType: VideoType) => {
    setCurrentVideo(videoType);
    setIsVideoDialogOpen(true);
  };

  const closeVideoDialog = () => {
    setIsVideoDialogOpen(false);
  };

  return (
    <Root>
      <Stack
        spacing={1}
        alignItems="center"
        className="LetsGetStartedStep-container"
      >
        {/* <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          className={classes.buttonContainer}
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlayCircleOutlineIcon />}
            onClick={() => openVideoDialog(VideoType.PROCESS)}
          >
            {theme.t(`${baseLanguageLocation}.buttons.seeProcess`)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSlideDirection(1)} // Trigger the next slide
          >
            {theme.t(`${baseLanguageLocation}.buttons.startEstimate`)}
          </Button>
        </Stack> */}

        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Box className={classes.boxContainer}>
              <Typography variant="h5" color="primary.light" mb={2}>
                {theme.t(`${baseLanguageLocation}.todaySection.title`)}
              </Typography>

              <Stack spacing={4}>
                <Box>
                  <Stack direction="row" alignItems="flex-start">
                    <Box className={classes.icon}>
                      <Checklist dark />
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        {theme.t(
                          `${baseLanguageLocation}.todaySection.estimate.title`
                        )}
                      </Typography>
                      <Typography color="primary" sx={{ mb: 0.5 }}>
                        {theme.t(
                          `${baseLanguageLocation}.todaySection.estimate.timeframe`
                        )}
                      </Typography>
                      <Typography color="text.secondary">
                        {theme.t(
                          `${baseLanguageLocation}.todaySection.estimate.description`
                        )}
                      </Typography>
                      <Button
                        sx={{ mt: 2 }}
                        variant="outlined"
                        color="primary"
                        startIcon={<PlayCircleOutlineIcon />}
                        onClick={() => openVideoDialog(VideoType.SAMPLE)}
                      >
                        {theme.t(
                          `${baseLanguageLocation}.todaySection.sampleButton`
                        )}
                      </Button>
                      {/* <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<PlayCircleOutlineIcon />}
                        onClick={() => openVideoDialog(VideoType.PROCESS)}
                      >
                        {theme.t(`${baseLanguageLocation}.buttons.seeProcess`)}
                      </Button> */}
                    </Box>
                  </Stack>
                </Box>

                <Box>
                  <Stack direction="row" alignItems="flex-start">
                    <Box className={classes.icon}>
                      <Phone />
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        {theme.t(
                          `${baseLanguageLocation}.todaySection.advisor.title`
                        )}
                      </Typography>
                      <Typography color="primary" sx={{ mb: 0.5 }}>
                        {theme.t(
                          `${baseLanguageLocation}.todaySection.advisor.timeframe`
                        )}
                      </Typography>
                      <Typography color="text.secondary">
                        {theme.t(
                          `${baseLanguageLocation}.todaySection.advisor.description`
                        )}
                      </Typography>
                      <Button
                        sx={{ mt: 2 }}
                        variant="outlined"
                        color="primary"
                        startIcon={<PlayCircleOutlineIcon />}
                        onClick={() => openVideoDialog(VideoType.ADVISOR)}
                      >
                        {theme.t(
                          `${baseLanguageLocation}.todaySection.advisor.button`
                        )}
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Box className={classes.boxContainer}>
              <Typography variant="h5" color="primary.light" mb={2}>
                {theme.t(`${baseLanguageLocation}.futureSection.title`)}
              </Typography>

              <Stack spacing={4}>
                <Box>
                  <Stack direction="row" alignItems="flex-start">
                    <Box className={classes.icon}>
                      <Homestead />
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        {theme.t(
                          `${baseLanguageLocation}.futureSection.assessment.title`
                        )}
                      </Typography>
                      <Typography color="primary" sx={{ mb: 0.5 }}>
                        {theme.t(
                          `${baseLanguageLocation}.futureSection.assessment.timeframe`
                        )}
                      </Typography>
                      <Typography color="text.secondary">
                        {theme.t(
                          `${baseLanguageLocation}.futureSection.assessment.description`
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>

                <Box>
                  <Stack direction="row" alignItems="flex-start">
                    <Box className={classes.icon}>
                      <Agreement />
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        {theme.t(
                          `${baseLanguageLocation}.futureSection.project.title`
                        )}
                      </Typography>
                      <Typography color="primary" sx={{ mb: 0.5 }}>
                        {theme.t(
                          `${baseLanguageLocation}.futureSection.project.subtitle`
                        )}
                      </Typography>
                      <Typography color="text.secondary">
                        {theme.t(
                          `${baseLanguageLocation}.futureSection.project.description`
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>

                <Box>
                  <Stack direction="row" alignItems="flex-start">
                    <Box className={classes.icon}>
                      <Contract />
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        {theme.t(
                          `${baseLanguageLocation}.futureSection.contractor.title`
                        )}
                      </Typography>
                      <Typography color="primary" sx={{ mb: 0.5 }}>
                        {theme.t(
                          `${baseLanguageLocation}.futureSection.contractor.subtitle`
                        )}
                      </Typography>
                      <Typography color="text.secondary">
                        {theme.t(
                          `${baseLanguageLocation}.futureSection.contractor.description`
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <VideoDialog
        isOpen={isVideoDialogOpen}
        onClose={closeVideoDialog}
        videoType={currentVideo}
        title={theme.t(`${baseLanguageLocation}.description`)}
      />
    </Root>
  );
};

export default LetsGetStartedStep;
