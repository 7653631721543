import { Box, Button, ButtonGroup, Typography, useTheme } from "@mui/material";

import { Estimate, EstimateData } from "@library/domain/estimate";
import { Quote } from "@library/domain/quote";
import { User } from "@library/domain/user";
import React, { createContext } from "react";

import EstimateOption from "./EstimateOption";
import EstimateDetail from "./EstimateDetail";

export const QuoteContext = createContext<{
  quoteId: string;
  quote: Partial<Quote>;
  refresh?: () => Promise<void>;
  setQuote?: React.Dispatch<React.SetStateAction<Quote | null>>;
}>({
  quoteId: "",
  quote: {},
  refresh: undefined,
  setQuote: undefined,
});

interface Option {
  id: string;
  tier: string;
  recommended: boolean;
  visible: boolean;
  topTitle: string;
  subTitle: string;
  selected: boolean;
  value: EstimateData;
  onClick: (tier: string, id: string) => void;
  filter: string | undefined;
}

const Tier = ({
  id,
  tier,
  onClick,
  filter,
  visible,
  value,
  recommended,
  selected,
  topTitle,
  subTitle,
}: Option) => {
  return (
    <Button
      component="div"
      onClick={() => {
        onClick(tier, id);
        const element = document.getElementById("estimate-detail");
        if (element) {
          const rect = element.getBoundingClientRect();
          const y = rect.top + window.scrollY;
          window.scrollTo(0, y - 120);
        }
      }}
    >
      <EstimateOption
        topTitle={topTitle}
        subTitle={subTitle}
        value={value}
        recommended={recommended || false}
        selected={selected || false}
        visible={visible ?? false}
        filter={filter}
      />
    </Button>
  );
};

export const ConciergeView = ({
  zoom,
  filter,
  borderRadius,
  user,
  matches,
  quote,
  selectedTier,
  setSelectedEstimate,
  quoteId,
  setError,
  setWarning,
  setShowCalendly,
  draft = false,
}: {
  zoom: string | undefined;
  filter: string | undefined;
  borderRadius: string;
  user: Partial<User>;
  matches: boolean;
  quote: Quote;
  selectedTier: string;
  setSelectedEstimate: (val: string) => void;
  quoteId: string;
  setError: (val: string) => void;
  setWarning: (val: string) => void;
  setShowCalendly: (val: boolean) => void;
  draft?: boolean;
}) => {
  const theme = useTheme();

  let estimates = (quote?.Estimate || []) as Estimate[];
  const finalEstimate = estimates.find((e) => e.final);
  if (finalEstimate) {
    estimates = [finalEstimate];
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const estimateMap: Record<string, any> = {};
  let hasSelected = false;
  const matchedTier = estimates.find(
    (e) => e.tier === selectedTier && e.viewable === true
  );
  let overrideSelectedTier = selectedTier;
  estimates.forEach((estimate: Estimate) => {
    let selected = false;
    if (estimate.viewable !== true) return;
    if (!hasSelected && selectedTier === estimate.tier) {
      selected = true;
      hasSelected = true;
      overrideSelectedTier = estimate.tier;
    }
    if (!matchedTier && !hasSelected) {
      selected = true;
      hasSelected = true;
      overrideSelectedTier = estimate.tier;
    }
    if (
      !estimateMap[estimate.tier] ||
      estimateMap[estimate.tier].createdAt < estimate.createdAt
    ) {
      estimateMap[estimate.tier ?? "null"] = {
        ...estimate,
        selected,
      };
    }
  });

  const options = {
    free: {
      topTitle: theme.t(`tier.free`) || "Free",
      subTitle: "If you're rebate-eligible, you may have a free option",
      recommended: theme.config.tiers.default === "free",
      id: estimateMap.free?.id,
      value: estimateMap.free?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("free")
        : false,
    },
    replace: {
      topTitle: theme.t(`tier.replace`) || "Replace",
      subTitle: "A like-for-like replacement of your current system",
      recommended: theme.config.tiers.default === "replace",
      id: estimateMap.replace?.id,
      value: estimateMap.replace?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("replace")
        : false,
    },
    base: {
      topTitle: theme.t("tier.base") || "Base",
      subTitle: "We recommend this option to minimize upfront cost",
      recommended: theme.config.tiers.default === "base",
      id: estimateMap.base?.id,
      value: estimateMap.base?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("base")
        : false,
    },
    pearl: {
      topTitle: theme.t("tier.pearl") || "Pearl",
      subTitle: "We recommend this option to optimize your energy savings",
      recommended: theme.config.tiers.default === "pearl",
      id: estimateMap.pearl?.id,
      value: estimateMap.pearl?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("pearl")
        : false,
    },
    edison: {
      topTitle: theme.t("tier.edison") || "Edison",
      subTitle: "We recommend this option to minimize climate impact",
      recommended: theme.config.tiers.default === "edison",
      id: estimateMap.edison?.id,
      value: estimateMap.edison?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("edison")
        : false,
    },
    a2zero: {
      topTitle: theme.t("tier.a2zero") || "A²ZERO",
      subTitle: "We recommend this option to optimize your energy savings",
      recommended: theme.config.tiers.default === "a2zero",
      id: estimateMap.a2zero?.id,
      value: estimateMap.a2zero?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("a2zero")
        : false,
    },
    a2zero_he: {
      topTitle: theme.t("tier.a2zero_h2") || "A²Z Efficient",
      subTitle: "We recommend this option to minimize climate impact",
      recommended: theme.config.tiers.default === "a2zero_he",
      id: estimateMap.a2zero_he?.id,
      value: estimateMap.a2zero_he?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("a2zero_he")
        : false,
    },
  };

  const estimate = options[overrideSelectedTier as keyof typeof options];

  return (
    <Box
      sx={{
        width: "100%",
        zoom,
        borderRadius,
        p: [0, 2],
        backgroundColor: theme.palette.primary.contrastText,
      }}
    >
      <Typography
        ml={2}
        flexGrow={1}
        pr={[2]}
        pt={2}
        sx={{
          fontSize: ["1.5rem", "2.5rem"],
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        Here&rsquo;s your estimate,{" "}
        <span style={{ filter }}>{user?.firstName}</span>!
      </Typography>
      <Typography
        ml={2}
        flexGrow={1}
        variant="body1"
        textAlign="left"
        display="block"
        fontSize="1.6rem"
        pr={[2]}
      >
        Compare your options, then connect with a Pearl Advisor to finalize your
        quote.
      </Typography>
      <ButtonGroup
        variant="text"
        orientation={matches ? "horizontal" : "vertical"}
        sx={{
          ".MuiButtonGroup-grouped": {
            borderColor: "transparent",
            textTransform: "initial",
            color: "initial",
            fontWeight: "initial",
          },
          mt: [4],
          mb: [10],
        }}
        aria-label="Estimate tiers"
      >
        {(Object.keys(options) as (keyof typeof options)[]).map((key) => {
          const tier = options[key];
          if (!tier.visible) return;
          if (!tier.id) return;
          return (
            <Tier
              key={key}
              filter={filter}
              tier={key}
              selected={overrideSelectedTier === key}
              onClick={setSelectedEstimate}
              {...tier}
            />
          );
        })}
      </ButtonGroup>
      <Box m={2} id="estimate-detail">
        <EstimateDetail
          quote={quote}
          quoteId={quoteId}
          estimateId={estimate.id}
          tier={overrideSelectedTier}
          estimate={estimate.value}
          setError={setError}
          setWarning={setWarning}
          setShowCalendly={setShowCalendly}
          draft={draft}
        />
      </Box>
    </Box>
  );
};
