import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  useTheme,
  Card,
  Box,
  IconButton,
  Collapse,
} from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PublicIcon from "@mui/icons-material/Public";
import { classes, Root } from "./styles";
import { formatCurrency } from "@library/common";
import PlaceholderImage from "@library/components/PlaceholderImage";
import SwiperCarousel from "@library/components/SwiperCarousel";
import { EstimateData, PolyvectorPayload } from "@library/domain/estimate";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const baseLanguageLocation = "step.LightEstimateOptionsStep";

interface LightEstimateOptionsStepProps {
  estimates: object;
}

interface EstimateItem {
  tier: string;
  data: EstimateData & PolyvectorPayload;
  isSelected: boolean;
}

interface HeightData {
  index: number;
  fullHeight: number;
  collapsedHeight: number;
}

const LightEstimateOptionsStep: React.FC<LightEstimateOptionsStepProps> = ({
  estimates,
}) => {
  const [showRebates, setShowRebates] = useState(false);
  const [expandedSections, setExpandedSections] = useState<number[]>([]);
  const [maxDescriptionHeight, setMaxDescriptionHeight] = useState<number>(0);

  const descriptionRefs = useRef<Array<HTMLDivElement | null>>([]);
  const theme = useTheme();
  const tiers = theme.config?.tiers?.available ?? ["base", "replace", "pearl"];

  const estimateArray = Object.entries(estimates)
    .map(([tier, estimate]) => ({
      tier,
      data: { ...estimate.data, ...estimate.v1 },
      isSelected: false, // Always false since this view is non-selectable
    }))
    .sort((a, b) => tiers.indexOf(a.tier) - tiers.indexOf(b.tier));

  const updateDescriptionHeights = useCallback(() => {
    // Map through all description sections to calculate their heights
    const heights: HeightData[] = descriptionRefs.current.map((ref, index) => {
      // If no ref exists for this card, return default heights of 0
      if (!ref)
        return {
          index,
          fullHeight: 0,
          collapsedHeight: 0,
        };

      // Check if this particular card is currently expanded
      const wasExpanded = expandedSections.includes(index);

      // If this card isn't expanded, we need to temporarily expand it
      // to measure its full height potential
      if (!wasExpanded) {
        // Add a temporary class that will make all content visible
        ref.classList.add("temp-expanded");
      }

      // Measure the full height of the content when everything is visible
      // scrollHeight includes padding but not margin
      const fullHeight = ref.scrollHeight;

      // Clean up: remove the temporary expanded class if we added it
      if (!wasExpanded) {
        ref.classList.remove("temp-expanded");
      }

      // Find and measure the height of just the initial (collapsed) content
      const initialContentElement = ref.querySelector(
        ".initial-content"
      ) as HTMLElement;
      const collapsedHeight = initialContentElement?.scrollHeight || 0;

      // Return both heights for this card
      return {
        index,
        fullHeight, // Height when expanded
        collapsedHeight, // Height when collapsed
      };
    });

    // Determine if any card is currently expanded
    const isAnyExpanded = expandedSections.length > 0;

    // Calculate the new height that will be applied to ALL cards:
    // - If any card is expanded, use the maximum full height
    // - If no cards are expanded, use the maximum collapsed height
    const newHeight = isAnyExpanded
      ? Math.max(...heights.map((h) => h.fullHeight))
      : Math.max(...heights.map((h) => h.collapsedHeight));

    // Update the state with the new height
    setMaxDescriptionHeight(newHeight);
  }, [expandedSections]);

  // Update heights when sections are toggled
  useEffect(() => {
    updateDescriptionHeights();
  }, [expandedSections, updateDescriptionHeights]);

  const toggleReadMore = (index: number) => {
    setExpandedSections((prev) => {
      if (prev.includes(index)) {
        // If the section is already expanded, remove it
        return prev.filter((i) => i !== index);
      } else {
        // If the section is not expanded, add it
        return [...prev, index];
      }
    });
  };

  const renderRebateSection = (estimate: EstimateItem) => {
    if (!estimate.data?.rebates?.length) return null;

    return (
      <>
        <Collapse in={showRebates}>
          <Typography variant="subtitle2" className={classes.bottomBorder}>
            {theme.t(`${baseLanguageLocation}.instantRebates.title`)}
          </Typography>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {estimate.data.rebates.map((rebate: any, index: number) => (
            <Box
              key={index}
              className={`${classes.costRow} ${classes.bottomBorder}`}
              sx={{ py: 1 }}
            >
              <Box sx={{ lineHeight: 1 }}>
                <Typography variant="body2" color="textPrimary">
                  {rebate.name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {rebate.description}
                </Typography>
              </Box>
              <Typography>{formatCurrency(rebate.total)}</Typography>
            </Box>
          ))}
        </Collapse>
      </>
    );
  };

  const renderEstimateCard = (estimate: EstimateItem, index: number) => {
    return (
      <Card className={classes.card} sx={{ p: { xs: 1.5, sm: 2 } }}>
        {/* Title Section */}
        <Box className={classes.titleSection}>
          <Typography variant="h6">{estimate.data.newSystemType}</Typography>
          <Typography variant="body2">
            {estimate.data.equipmentBrand}
          </Typography>
        </Box>

        {/* Image Section */}
        <Box className={classes.imageContainer}>
          <PlaceholderImage imageHeight={200} />
        </Box>

        {/* Description Section */}
        <Box
          className={classes.descriptionSection}
          ref={(el: HTMLDivElement | null) => {
            if (descriptionRefs.current) {
              descriptionRefs.current[index] = el;
            }
          }}
          style={{
            height: maxDescriptionHeight || "auto",
            transition: "height 0.3s ease-in-out",
          }}
        >
          <Box className={classes.expandableContent}>
            <Box className="initial-content">
              <Typography>{estimate.data.recommendation}</Typography>
              <Typography
                className={classes.readMore}
                onClick={() => toggleReadMore(index)}
              >
                {expandedSections.includes(index)
                  ? theme.t(`${baseLanguageLocation}.readMore.less`)
                  : theme.t(`${baseLanguageLocation}.readMore.more`)}
              </Typography>
            </Box>
            {expandedSections.includes(index) && (
              <Typography>{estimate.data.recommendationExplanation}</Typography>
            )}
          </Box>
        </Box>

        {/* Cost Section */}
        <Box className={classes.costSection}>
          <Box className={classes.costRow}>
            <PaymentsIcon color="action" />
            <IconButton
              onClick={() => setShowRebates(!showRebates)}
              size="small"
            >
              {showRebates ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </Box>
          <Box className={classes.costRow}>
            <Box>
              <Typography variant="subtitle2" sx={{ mb: 0 }}>
                {theme.t(`${baseLanguageLocation}.installedCost.title`)}
              </Typography>
              <Typography variant="caption">
                {theme.t(`${baseLanguageLocation}.installedCost.subtitle`)}
              </Typography>
            </Box>
            <Typography className={classes.strikeThrough}>
              {formatCurrency(estimate.data.installedCostTotal)}
            </Typography>
          </Box>

          <Box className={classes.costRow}>
            <Box>
              <Typography variant="subtitle2" color="primary">
                {theme.t(`${baseLanguageLocation}.yourPrice.title`)}
              </Typography>
              <Typography variant="caption">
                {theme.t(`${baseLanguageLocation}.yourPrice.subtitle`)}
              </Typography>
            </Box>
            <Typography color="primary.main">
              {formatCurrency(estimate.data.netPrice)}
            </Typography>
          </Box>

          <Box className={classes.costRow}>
            <Box>
              <Typography variant="subtitle2" color="primary">
                {theme.t(`${baseLanguageLocation}.monthlyCost.title`)}
              </Typography>
              <Typography variant="caption">
                {theme.t(`${baseLanguageLocation}.monthlyCost.subtitle`)}
              </Typography>
            </Box>
            <Typography color="primary.main">
              {estimate.data.netPrice
                ? formatCurrency(estimate.data.netPrice / 120)
                : "N/A"}{" "}
              {theme.t(`${baseLanguageLocation}.monthlyCost.perMonth`)}
            </Typography>
          </Box>
          {renderRebateSection(estimate)}
        </Box>

        {/* Bill Impact Section */}
        <Box className={classes.billImpactSection}>
          <ReceiptLongIcon color="action" />
          <Box className={classes.costRow}>
            <Box>
              <Typography variant="subtitle2">
                {theme.t(`${baseLanguageLocation}.annualBill.title`)}
              </Typography>
              <Typography className={classes.subtitle}>
                {theme.t(`${baseLanguageLocation}.annualBill.subtitle`)}
              </Typography>
            </Box>
            <Typography>
              {formatCurrency(estimate.data.billImpactEstimate, {
                increaseDecrease: true,
              })}{" "}
              {theme.t(`${baseLanguageLocation}.annualBill.perYear`)}
            </Typography>
          </Box>
        </Box>

        {/* Climate Impact Section */}
        <Box className={classes.climateImpactSection}>
          <PublicIcon color="action" />
          <Box className={classes.costRow}>
            <Box>
              <Typography variant="subtitle2">
                {theme.t(`${baseLanguageLocation}.climateImpact.title`)}
              </Typography>
              <Typography className={classes.subtitle}>
                {theme.t(`${baseLanguageLocation}.climateImpact.subtitle`)}
              </Typography>
            </Box>
            <Typography>
              {estimate?.data?.climateImpactEstimate &&
                Math.abs(estimate.data.climateImpactEstimate).toFixed(1)}{" "}
              {theme.t(`${baseLanguageLocation}.climateImpact.unit`)}
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <Root>
      <Box
        sx={{
          width: "100vw", // Take up available viewport width
          mx: "auto",
          px: { xs: 1, sm: 2, md: 3 }, // Reduced padding on mobile
          paddingBottom: 8,
        }}
      >
        <SwiperCarousel
          selectable={false}
          items={estimateArray}
          subTitle="Your Options:"
          renderItem={renderEstimateCard}
        />
      </Box>
    </Root>
  );
};

export default LightEstimateOptionsStep;
