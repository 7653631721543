import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Card,
  Typography,
  Box,
  Button,
  Grid,
  useTheme,
  Theme,
  useMediaQuery,
  CircularProgress,
  Tooltip,
  Dialog,
  Modal,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import isNumber from "lodash/isNumber";

import {
  Info as InfoIcon,
  ThumbDownOffAlt,
  ThumbUpOffAlt,
} from "@mui/icons-material";

import {
  ConciergePageViewProps,
  GraphDataOptions,
  Package,
  PackageCardProps,
  PackageDetailsProps,
  PackageDetailsSection,
  ScheduleFooterProps,
} from "./types";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Root, classes } from "./styles";
import { SettingsContext } from "@library/settings/provider";

import {
  SummaryContent,
  ScopeContent,
  PricingContent,
  BillImpactContent,
  EnergyImpactContent,
  LoadCalculationContent,
  EquipmentContent,
  WhyPearlEdisonContent,
  TestimonialContent,
} from "./components/sections";
import PearlLogoDark from "@library/assets/pearl_logo_dark.png";
// import SchedulingDialog from "@library/components/SchedulingDialog"; // FYI: commenting out and relying on calendly integration for now
import { InlineWidget } from "react-calendly";
import { useParams } from "react-router-dom";
import api from "@library/api";
import { Job } from "@library/domain/job";
import { Quote } from "@library/domain/quote";
import ErrorToast from "@library/components/ErrorToast";
import { formatCurrency, formatNumber } from "@library/common";
import {
  CALENDLY_URL_HOME_ASSESSMENT,
  CALENDLY_URL_HOME_CONSULT,
} from "@library/common";
import { QuoteContext } from "@library/components/Concierge";
import AdvisorProfile from "@library/components/AdvisorProfile";
import { OnboardingPayload } from "@library/domain/onboarding";
import { Estimate, Rebate } from "@library/domain/estimate";
import { TenantName } from "@library/theme/multitenancy";
import SwiperCarousel from "@library/components/SwiperCarousel";

const getSections = (
  selectedPackage: Package,
  theme: Theme,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphData: any,
  graphDataOptions: GraphDataOptions,
  onGraphOptionChange: (options: Partial<GraphDataOptions>) => void
): PackageDetailsSection[] => [
  {
    label: "Summary",
    value: "summary",
    content: <SummaryContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Scope of Work",
    value: "scope",
    content: <ScopeContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Pricing, Rebates & Financing",
    value: "pricing",
    content: <PricingContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Bill Impact",
    value: "bill",
    content: (
      <BillImpactContent
        theme={theme}
        graphData={graphData}
        graphDataOptions={graphDataOptions}
        onGraphOptionChange={onGraphOptionChange}
      />
    ),
  },
  {
    label: "Energy Savings Impact",
    value: "energy",
    content: (
      <EnergyImpactContent
        theme={theme}
        graphData={graphData}
        graphDataOptions={graphDataOptions}
        onGraphOptionChange={onGraphOptionChange}
      />
    ),
  },
  {
    label: "Load Calculation",
    value: "loadCalc",
    content: <LoadCalculationContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Equipment & Tech Specs",
    value: "equipment",
    content: <EquipmentContent package={selectedPackage} theme={theme} />,
  },

  {
    label: "Why Pearl Edison?",
    value: "whyPearl",
    content: <WhyPearlEdisonContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Testimonials",
    value: "testimonials",
    content: <TestimonialContent package={selectedPackage} theme={theme} />,
  },
  // ... other sections
];

const PackageCard: React.FC<PackageCardProps> = ({
  theme,
  package: pkg,
  onSelect,
}) => {
  const baseLanguageLocation = "concierge.PackageDetails.PackageCard";
  const upFrontCost = pkg?.data?.upFrontCostTotal || 0;
  const rebates = (pkg?.polyvectorPayload?.rebates || []) as Rebate[];
  const eligibleRebate = rebates.reduce((n, { total }) => n + total, 0);

  // Calculate costs after rebates
  const afterEligibleRebates = upFrontCost - eligibleRebate;

  // Payment options calculations
  const monthlyPayment = Math.round(afterEligibleRebates / 120); // 120 months
  const defaultTier = theme.config?.tiers?.default || "pearl";

  return (
    <Box position="relative">
      <Card
        className={
          pkg.isSelected
            ? `${classes.selectedPackageContainer} ${classes.packageContainer}`
            : classes.packageContainer
        }
      >
        <Typography variant="h4" color={pkg.isSelected ? "primary" : "inherit"}>
          {theme?.t(`tier.${pkg.tier}`)}
        </Typography>
        {pkg.tier === defaultTier && (
          <Typography
            variant="subtitle2"
            className={classes.recommendedPackageText}
          >
            {theme?.t(`${baseLanguageLocation}.recommendedText`)}
          </Typography>
        )}
        <Typography variant="body2" sx={{ mt: 2 }}>
          {pkg.data?.recommendation}
        </Typography>

        <Box sx={{ mt: 3, width: "100%" }}>
          <Box className={classes.bottomBorderContainer}>
            <Typography className={classes.boldText}>
              {theme?.t(`${baseLanguageLocation}.pricingOptions.title`)}
            </Typography>
          </Box>
          <Box className={classes.bottomBorderContainer}>
            <Typography variant="body2">
              {theme?.t(`${baseLanguageLocation}.pricingOptions.singlePayment`)}
            </Typography>
            <Typography className={classes.lightWeightText}>
              {formatCurrency(pkg.data?.upFrontCostTotal)}
            </Typography>
          </Box>
          <Typography variant="body2">
            {theme?.t(`${baseLanguageLocation}.pricingOptions.financeIntro`)}{" "}
            <strong
              className={
                pkg.tier === "pearl" ? classes.recommendedStrongText : ""
              }
            >
              {formatCurrency(monthlyPayment)}/month
            </strong>{" "}
            {theme?.t(`${baseLanguageLocation}.pricingOptions.financeDetails`)}
          </Typography>
        </Box>

        <Box sx={{ mt: 2, width: "100%" }}>
          <Box className={classes.bottomBorderContainer}>
            <Typography className={classes.boldText}>
              {theme?.t(`${baseLanguageLocation}.impacts.annual.title`)}
            </Typography>
            <Typography className={classes.lightWeightText}>
              {isNumber(pkg?.data?.billImpactEstimate)
                ? formatCurrency(pkg?.data?.billImpactEstimate, {
                    increaseDecrease: true,
                    empty: true,
                  }) || "$0"
                : theme?.t(`${baseLanguageLocation}.impacts.annual.tbd`)}
              <Tooltip title={pkg?.data?.billImpactExplanation}>
                <InfoIcon className={classes.lightIcon} />
              </Tooltip>
            </Typography>
          </Box>
          <Box className={classes.bottomBorderContainer}>
            <Typography className={classes.boldText}>
              {theme?.t(`${baseLanguageLocation}.impacts.climate.title`)}
            </Typography>
            <Typography className={classes.lightWeightText}>
              {isNumber(pkg?.data?.climateImpactEstimate)
                ? formatNumber(pkg?.data?.climateImpactEstimate, {
                    units: theme?.t(
                      `${baseLanguageLocation}.impacts.climate.units`
                    ),
                    increaseDecrease: true,
                  }) || "0 MT"
                : theme?.t(`${baseLanguageLocation}.impacts.climate.tbd`)}
              <Tooltip title={pkg?.data?.climateImpactExplanation}>
                <InfoIcon className={classes.lightIcon} />
              </Tooltip>
            </Typography>
          </Box>
        </Box>

        <Box className={classes.cardFooterContent}>
          {pkg.isSelected ? (
            <Typography
              className={`${classes.cardFooterViewingText} ${classes.lightWeightText}`}
            >
              {theme?.t(`${baseLanguageLocation}.footer.currentlyViewing`)}
            </Typography>
          ) : (
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt: 2 }}
              onClick={onSelect}
              className={classes.cardFooterButton}
            >
              {theme?.t(`${baseLanguageLocation}.footer.learnMore`)}
            </Button>
          )}
        </Box>
      </Card>
      {pkg.isSelected && <div className={classes.selectedPackageArrow} />}
    </Box>
  );
};

const ScheduleFooter: React.FC<ScheduleFooterProps> = ({
  onScheduleAssessment,
  onScheduleConsultation,
  theme,
  job,
}) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const MobileFooter = () => (
    <Box className={classes.mobileFooterContainer}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="h6" className={classes.mobileFooterTitle}>
              {theme.t(`concierge.ScheduleFooter.finalizeButtonDescription`)}
            </Typography>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              className={classes.mobileFooterButton}
              onClick={onScheduleAssessment}
            >
              {theme.t(`concierge.ScheduleFooter.finalizeButtonLabel`)}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box>
            <Typography variant="h6" className={classes.mobileFooterTitle}>
              {theme.t(
                `concierge.ScheduleFooter.consultationButtonDescription`
              )}
            </Typography>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              className={classes.mobileFooterButton}
              onClick={onScheduleConsultation}
            >
              {theme.t(`concierge.ScheduleFooter.consultationButtonLabel`)}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const DesktopFooter = ({ job }: { job: Job }) => (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <Box style={{ width: "100%" }}>
          <Typography sx={{ mb: 1 }}>
            {theme.t(`concierge.ScheduleFooter.finalizeButtonDescription`)}
          </Typography>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            className={classes.footerButton}
            onClick={onScheduleAssessment}
          >
            {theme.t(`concierge.ScheduleFooter.finalizeButtonLabel`)}
          </Button>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box>
          <Typography sx={{ mb: 1 }}>
            {theme.t(`concierge.ScheduleFooter.consultationButtonDescription`)}
          </Typography>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            className={classes.footerButton}
            onClick={onScheduleConsultation}
          >
            {theme.t(`concierge.ScheduleFooter.consultationButtonLabel`)}
          </Button>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <AdvisorProfile
          payload={
            {
              user: {
                formatted_address: `${job?.Home?.address1} ${job?.Home?.city} ${job?.Home?.state}`,
              },
            } as OnboardingPayload
          }
          theme={theme}
        />
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.footerContainer}>
      {isMobileOrTablet ? <MobileFooter /> : <DesktopFooter job={job} />}
    </Box>
  );
};

const PackageDetails: React.FC<PackageDetailsProps> = ({
  selectedPackage,
  selectedSection,
  onSectionChange,
  graphData,
  graphDataOptions,
  onGraphOptionChange,
}) => {
  const theme = useTheme();

  const [loadingInterest, setLoadingInterest] = useState<boolean | null>(null); // null, true (thumbs up), or false (thumbs down)

  const { quote, quoteId, setQuote } = useContext(QuoteContext);

  const sections = getSections(
    selectedPackage,
    theme,
    graphData,
    graphDataOptions,
    onGraphOptionChange
  );
  const activeSection =
    sections.find((section) => section.value === selectedSection) ||
    sections[0];

  const getInterestState = () => {
    if (selectedPackage && selectedPackage.tier) {
      const userInput = quote?.userInput as {
        [key: string]: { isInterested: boolean };
      };
      return userInput?.[selectedPackage.tier]?.isInterested ?? null;
    }
    return null;
  };

  const handleCustomerInterest = async (value: boolean) => {
    const tier = selectedPackage.tier;

    if (tier) {
      try {
        setLoadingInterest(value); // Set which button is loading
        const userInput = {
          [tier]: {
            isInterested: value,
          },
        };

        const response = await api.put("quote", { userInput }, { id: quoteId });

        if (response.success && setQuote) {
          setQuote(response.data);
        }
      } catch (error) {
        console.error("Error updating customer interest:", error);
      } finally {
        setLoadingInterest(null); // Reset loading state
      }
    }
  };

  const interestState = getInterestState();

  return (
    <Box className={classes.packageDetailsContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box>
            <Box className={classes.packageDetailsTitleOuterContainer}>
              <Box className={classes.packageDetailsTitleContainer}>
                <Typography className={classes.packageDetailsTitle}>
                  {theme?.t(`tier.${selectedPackage?.tier}`)}
                </Typography>
                <Typography
                  variant="h4"
                  className={classes.packageDetailsSummary}
                >
                  {activeSection.label}
                </Typography>
              </Box>
              <Box className={classes.interestedContainer}>
                <Typography variant="body2">
                  Interested in this package?
                </Typography>
                <Button
                  variant={interestState === true ? "contained" : "outlined"}
                  className={classes.thumbUpButton}
                  onClick={() => handleCustomerInterest(true)}
                  disabled={interestState === true || loadingInterest !== null}
                  sx={{
                    ml: 1,
                    backgroundColor:
                      interestState === true
                        ? theme.palette.secondary.main
                        : "transparent",
                    color:
                      interestState === true
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                    "&:hover": {
                      backgroundColor:
                        interestState === true
                          ? theme.palette.secondary.main
                          : "transparent",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.secondary.contrastText,
                      opacity: 0.7,
                    },
                  }}
                >
                  {loadingInterest === true ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <ThumbUpOffAlt />
                  )}
                </Button>
                <Button
                  variant={interestState === false ? "contained" : "outlined"}
                  className={classes.thumbDownButton}
                  onClick={() => handleCustomerInterest(false)}
                  disabled={interestState === false || loadingInterest !== null}
                  sx={{
                    ml: 1,
                    backgroundColor:
                      interestState === false
                        ? theme.palette.error.main
                        : "transparent",
                    color:
                      interestState === false
                        ? theme.palette.error.contrastText
                        : theme.palette.error.main,
                    borderColor: theme.palette.error.main,
                    "&:hover": {
                      backgroundColor:
                        interestState === false
                          ? theme.palette.error.main
                          : "transparent",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: theme.palette.error.main,
                      color: theme.palette.error.contrastText,
                      opacity: 0.7,
                    },
                  }}
                >
                  {loadingInterest === false ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <ThumbDownOffAlt />
                  )}
                </Button>
              </Box>
            </Box>
            {activeSection.content}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className={classes.verticalNavContainer}>
            {sections.map((section) => (
              <Button
                key={section.value}
                fullWidth
                className={`${classes.verticalNavButton} ${
                  selectedSection === section.value
                    ? classes.verticalNavButtonActive
                    : ""
                }`}
                onClick={() => onSectionChange(section.value)}
              >
                {section.label}
              </Button>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const RebateModal = ({ quote }: { quote: Quote | null }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [rebates, setRebates] = useState({
    "Eligible Rebate": 0,
    "Potential Additional Rebate": 0,
    "Maximum Rebate": 0,
  });

  useEffect(() => {
    const _r = {
      "Eligible Rebate": 0,
      "Potential Additional Rebate": 0,
      "Maximum Rebate": 0,
    };
    if (quote?.channelId !== "a2zero") return;
    quote?.Estimate?.forEach((estimate) => {
      const rebates = estimate.polyvectorPayload?.rebates || [];
      const eligibleRebates = rebates.filter(
        (e) => e.type !== "Post-Install Rebate"
      );
      const potentialRebates = rebates.filter(
        (e) => e.type === "Post-Install Rebate"
      );
      const eligibleRebatesTotal = eligibleRebates.reduce(
        (n, { total }) => n + total,
        0
      );
      const potentialRebatesTotal = potentialRebates.reduce(
        (n, { total }) => n + total,
        0
      );
      _r["Eligible Rebate"] =
        eligibleRebatesTotal + potentialRebatesTotal >= _r["Maximum Rebate"]
          ? eligibleRebatesTotal || 0
          : _r["Eligible Rebate"];
      _r["Potential Additional Rebate"] =
        eligibleRebatesTotal + potentialRebatesTotal >= _r["Maximum Rebate"]
          ? potentialRebatesTotal || 0
          : _r["Potential Additional Rebate"];
      _r["Maximum Rebate"] =
        eligibleRebatesTotal + potentialRebatesTotal >= _r["Maximum Rebate"]
          ? eligibleRebatesTotal + potentialRebatesTotal || 0
          : _r["Maximum Rebate"];
    });
    if (_r["Maximum Rebate"] > 0) {
      setRebates(_r);
      setOpen(true);
    }
  }, [quote]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: "32px",
          borderRadius: "8px",
          maxWidth: "600px",
          position: "relative",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Grid container sx={{ width: "100%" }} spacing={3}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "right",
              paddingRight: theme.spacing(2),
            }}
          >
            <img src={theme.logo.imageSrc} height="20" />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "left",
              paddingLeft: theme.spacing(2),
            }}
          >
            <img src={PearlLogoDark} height="20" />
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" variant="subtitle1">
              Eligible Rebates
            </Typography>
            <Typography align="center" variant="h4">
              {rebates["Eligible Rebate"].toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" variant="subtitle1">
              Potential Rebates
            </Typography>
            <Typography align="center" variant="h4">
              {rebates["Potential Additional Rebate"].toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" variant="subtitle1">
              Maximum Rebate
            </Typography>
            <Typography align="center" variant="h4">
              {rebates["Maximum Rebate"].toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="body2">
              Be sure to schedule your home assessment to reserve your rebate.
              <br />
              <b>
                Your {theme.config.name} rebate is not reserved until you
                complete this step!
              </b>
            </Typography>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(3),
          }}
        >
          <Button variant="contained" onClick={handleClose}>
            View My Estimate
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const ConciergePageViewV3: React.FC<ConciergePageViewProps> = () => {
  const { jobId, quoteId = "" } = useParams();
  const { user, setChannel } = useContext(SettingsContext);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0); // Default to Pearl Package
  const [selectedSection, setSelectedSection] = useState("summary");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [quote, setQuote] = useState<Quote | null>(null);
  const [job, setJob] = useState<Job | null>(null);
  const [isScheduleAssessmentDialogOpen, setIsScheduleAssessmentDialogOpen] =
    useState(false);
  const [
    isScheduleConsultationDialogOpen,
    setisScheduleConsultationDialogOpen,
  ] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [graphData, setGraphData] = useState<any>(null);
  const [graphDataOptions, setGraphDataOptions] = useState<GraphDataOptions>({
    weatherSeverity: "Average",
    energyPrices: "Status Quo",
  });
  let _jobId = jobId;
  if (!jobId) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user?.Home?.forEach((h: any) => {
      h.Job?.forEach((j: Job) => {
        j?.Quote?.forEach((q: Quote) => {
          if (q.id === quoteId) {
            _jobId = j.id;
          }
        });
      });
    });
  }
  const theme = useTheme();
  const tiers = theme.config?.tiers?.available ?? ["base", "replace", "pearl"];
  const defaultTier = theme.config?.tiers?.default || "pearl";

  const packages =
    quote?.Estimate?.filter((e) => e.viewable).sort(
      (a, b) => tiers.indexOf(a.tier) - tiers.indexOf(b.tier)
    ) || [];
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const fetchConciergeData = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await api.get("concierge", {
        jobId: _jobId,
        quoteId: quoteId,
      });
      if (response.success) {
        setJob(response.data.job);
        setQuote(response.data.quote);
        const _packages =
          response.data.quote?.Estimate?.filter(
            (e: Estimate) => e.viewable
          ).sort(
            (a: Estimate, b: Estimate) =>
              tiers.indexOf(a.tier) - tiers.indexOf(b.tier)
          ) || [];
        const defaultSelectedPackageIndex = _packages.findIndex(
          (e: Estimate) => e.tier === defaultTier
        );
        setSelectedPackageIndex(defaultSelectedPackageIndex);
      } else {
        setError("Failed to fetch jobs");
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setError("An error occurred while fetching jobs");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGraphData = async () => {
    try {
      const payload = {
        ...quote?.Onboarding?.data,
        energyPrices: graphDataOptions.energyPrices,
        weatherSeverity: graphDataOptions.weatherSeverity,
      };

      const response = await api.post("engine/partial", {
        payload: payload,
        fields: ["energy_model"],
        tier: packages?.[selectedPackageIndex].tier,
      });

      if (response.success) {
        setGraphData(response.data?.data?.energyModel || {});
      }
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  const refresh = useCallback(async () => {
    await fetchConciergeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use refresh for initial data fetch
  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (quote) {
      fetchGraphData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote, graphDataOptions, selectedPackageIndex]);

  useEffect(() => {
    if (quote?.channelId) {
      setChannel(quote.channelId as TenantName);
    }
  }, [quote, setChannel]);

  const handleGraphOptionChange = (options: Partial<GraphDataOptions>) => {
    setGraphDataOptions((prev) => ({
      ...prev,
      ...options,
    }));
  };

  const handleSectionChange = (section: string) => {
    setSelectedSection(section);
  };

  const toggleScheduleAssessmentDialog = () => {
    setIsScheduleAssessmentDialogOpen(!isScheduleAssessmentDialogOpen);
  };

  const toggleScheduleConsultationDialog = () => {
    setisScheduleConsultationDialogOpen(!isScheduleConsultationDialogOpen);
  };

  const handlePackageSelect = (index: number) => {
    setSelectedPackageIndex(index);
  };

  const renderPackageCard = (
    pkg: Package,
    index: number,
    isSelected: boolean
  ) => (
    <PackageCard
      theme={theme}
      package={{
        ...pkg,
        isSelected,
      }}
      onSelect={() => handlePackageSelect(index)}
    />
  );

  return (
    <Root>
      <QuoteContext.Provider
        value={{ quoteId: quoteId, quote: quote as Quote, refresh, setQuote }}
      >
        <Box className={classes.titleContainer}>
          <Typography variant="h4" align="center" className={classes.titleText}>
            {user?.firstName || "Hello"}
            {isLoading
              ? theme.t(`concierge.loadingTitle`)
              : theme.t(`concierge.title`)}
          </Typography>

          <Typography align="center" variant="body2">
            {theme.t(`concierge.description`)}
          </Typography>

          <ErrorToast onClose={() => setError("")} text={error} />
        </Box>

        {isLoading ? (
          <Box className={classes.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <Box className={classes.contentContainer}>
            {isMobileOrTablet && (
              <Box sx={{ mb: 2 }}>
                <AdvisorProfile
                  payload={
                    {
                      user: {
                        formatted_address: `${job?.Home?.address1} ${job?.Home?.city} ${job?.Home?.state}`,
                      },
                    } as OnboardingPayload
                  }
                  theme={theme}
                />
              </Box>
            )}
            <SwiperCarousel
              items={packages}
              renderItem={renderPackageCard}
              selectedIndex={selectedPackageIndex}
              onSelectItem={handlePackageSelect}
              subTitle={`${theme.t(`concierge.SwiperCarousel.title`)} ${job?.Home?.address1 || ""}:`}
              selectable={true}
            />

            <PackageDetails
              graphData={graphData}
              selectedPackage={packages[selectedPackageIndex] || {}}
              selectedSection={selectedSection}
              onSectionChange={handleSectionChange}
              graphDataOptions={graphDataOptions}
              onGraphOptionChange={handleGraphOptionChange}
            />
          </Box>
        )}
        <ScheduleFooter
          theme={theme}
          onScheduleAssessment={toggleScheduleAssessmentDialog}
          onScheduleConsultation={toggleScheduleConsultationDialog}
          job={job || ({} as Job)}
        />
        {/* FYI: commenting out and relying on calendly integration for now */}
        {/* <SchedulingDialog
        type="assessment"
        open={isScheduleAssessmentDialogOpen}
        onClose={toggleScheduleAssessmentDialog}
      />
      <SchedulingDialog
        type="consultation"
        open={isScheduleConsultationDialogOpen}
        onClose={toggleScheduleConsultationDialog}
      /> */}
        <Dialog
          fullWidth
          maxWidth="lg"
          open={isScheduleAssessmentDialogOpen}
          onClose={toggleScheduleAssessmentDialog}
        >
          <InlineWidget url={CALENDLY_URL_HOME_ASSESSMENT} />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={isScheduleConsultationDialogOpen}
          onClose={toggleScheduleConsultationDialog}
        >
          <InlineWidget url={CALENDLY_URL_HOME_CONSULT} />
        </Dialog>
        <RebateModal quote={quote} />
      </QuoteContext.Provider>
    </Root>
  );
};

export default ConciergePageViewV3;
