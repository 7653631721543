import React, { useState } from "react";
import { Phone as PhoneIcon, Email as EmailIcon } from "@mui/icons-material";
import { Root, classes } from "./styles";
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Advisor, BorderStyle, defaultAdvisor } from "./types";
import { OnboardingPayload } from "@library/domain/onboarding";
import RepresentativeDialog from "../RepresentativeDialog";

interface AdvisorProfileProps {
  advisor?: Partial<Advisor>;
  theme: Theme;
  borderStyle?: BorderStyle;
  payload?: OnboardingPayload;
}

const AdvisorProfile: React.FC<AdvisorProfileProps> = ({
  advisor,
  theme,
  borderStyle = "full",
  payload,
}) => {
  const currentAdvisor = {
    ...defaultAdvisor,
    ...advisor,
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = () => {
    setIsDialogOpen(true);
  };

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const getBorderStyles = () => {
    switch (borderStyle) {
      case "right":
        return {
          borderRadius: "0 !important",
          borderRight: `1px solid ${theme.palette.divider}`,
        };
      case "none":
        return {
          border: "none",
        };
      case "full":
      default:
        return {
          border: `1px solid ${theme.palette.divider}`,
        };
    }
  };

  // If mobile, render just the Avatar
  if (isMobile) {
    return (
      <Root>
        <Stack
          sx={{ ml: 1 }}
          className={classes.mobileAvatarContainer}
          onClick={handleClick}
        >
          <Avatar
            alt="Profile picture"
            src={currentAdvisor.image}
            sx={{
              width: 40,
              height: 40,
            }}
          />
        </Stack>
        <RepresentativeDialog
          open={isDialogOpen}
          toggle={toggleDialog}
          person={currentAdvisor}
          payload={payload}
        />
      </Root>
    );
  }

  // Otherwise render the full profile
  return (
    <Root>
      <Stack
        className={classes.userProfileButton}
        sx={{ ...getBorderStyles() }}
        onClick={handleClick}
      >
        <Box className={classes.avatarAndNameContainer}>
          <Avatar
            alt="Profile picture"
            src={currentAdvisor.image}
            sx={{ width: 40, height: 40 }}
          />
          <Box sx={{ ml: 1, textAlign: "left" }}>
            <Typography className={classes.advisorLabel}>
              {theme.t(`concierge.ScheduleFooter.yourAdvisor`)}
            </Typography>
            <Typography variant="h5" className={classes.advisorName}>
              {currentAdvisor.name}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.advisorContactInfoContainer}>
          <Button
            component="a"
            href={`tel:+${currentAdvisor.phone}`}
            target="_blank"
            className={classes.advisorContactInfoRow}
          >
            <PhoneIcon />
            <Typography>{currentAdvisor.phone}</Typography>
          </Button>
          <Button
            component="a"
            href={`mailto:${currentAdvisor.email}?subject=${encodeURIComponent(`Please contact me regarding the quote for ${payload?.user?.formatted_address ? payload.user.formatted_address : "my home"}`)}`}
            target="_blank"
            className={classes.advisorContactInfoRow}
          >
            <EmailIcon />
            <Typography>{currentAdvisor.email}</Typography>
          </Button>
        </Box>
      </Stack>
      <RepresentativeDialog
        open={isDialogOpen}
        toggle={toggleDialog}
        person={currentAdvisor}
        payload={payload}
      />
    </Root>
  );
};

export default AdvisorProfile;
