import { responsiveFontSizes, Theme } from "@mui/material";
import get from "lodash/get";
import partial from "lodash/partial";
import isString from "lodash/isString";

import type { TenantName } from "./multitenancy";
import { AvailableTenants } from "./multitenancy";
import { Channel } from "@library/domain/channel";
import { PHONE_NUMBER } from "@library/common";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const replaceTokens = (template: string, values: any) => {
  if (!isString(template)) return template;
  return template.replace(/\{([^}]+)\}/g, (match, token) => {
    const value = get(values, token) ?? match;
    return value;
  });
};

function getText(
  theme: Theme,
  language: string,
  path: string,
  values?: Record<string, string | number>
) {
  const CONSTANTS = {
    PHONE_NUMBER,
  };
  const config =
    theme.config.language[language as keyof typeof theme.config.language];
  let text = get(config, path, "");
  text = replaceTokens(text, { constants: CONSTANTS, ...values });
  return text;
}

export const getTheme = (
  tenant: TenantName = "pearl",
  channel: Channel,
  language = "en-us"
) => {
  let tenantConfig = AvailableTenants.pearl(channel);
  let config = responsiveFontSizes(tenantConfig.theme);
  try {
    tenantConfig =
      AvailableTenants[tenant](channel) ?? AvailableTenants.pearl(channel);
    config = responsiveFontSizes(tenantConfig.theme);
    if (channel?.logoUrl) {
      config.logo.imageSrc = channel.logoUrl;
    } else if (channel?.LogoImage?.url) {
      config.logo.imageSrc = channel.LogoImage.url;
    }
  } catch (e) {
    // do nothing
  }
  config.t = partial(getText, config, language);
  return config;
};
