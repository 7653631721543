import React, { useContext, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Stack,
  useTheme,
  Divider,
  Link as MaterialLink,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  IconButton,
  Drawer,
  StandardProps,
  PropTypes,
  SvgIconClassKey,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Link } from "react-router-dom";
import { corpUrl, shortenFullName } from "@library/common";
import { Root } from "./styles";

import { getTopLinks } from "@library/theme/navigation";

import poweredBy from "@library/theme/multitenancy/powered_by.svg";
import type { TenantName, TopLink } from "@library/theme/multitenancy";

import { SettingsContext } from "@library/settings/provider";

export const TopDivider = () => {
  const theme = useTheme();
  return (
    <Divider
      orientation="vertical"
      flexItem
      sx={{
        color: theme.palette.primary.contrastText,
        bgcolor: theme.palette.primary.contrastText,
      }}
    />
  );
};

declare module "@mui/material/styles" {
  interface Theme {
    logo: {
      imageSrc: string;
      logoUrl: string;
      alt: string;
      width: number;
      height: number;
      poweredBy: boolean;
    };
    splash: {
      imageSrc: string;
      alt: string;
    };
  }
  interface ThemeOptions {
    logo?: {
      imageSrc?: string | undefined;
      logoUrl?: string;
      alt?: string;
      width: number;
      height: number;
      poweredBy: boolean;
    };
    splash?: {
      imageSrc?: string | undefined;
      alt?: string;
    };
  }
}

const SubMenu = ({ item }: { item: TopLink }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  if (item.href != undefined) return null;

  const handleOnMouseEnter = () => setOpen(true);
  const handleOnMouseLeaves = () => setOpen(false);

  return (
    <Box
      key={item.label}
      position="relative"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeaves}
    >
      <Stack justifyContent="center" alignItems="center" direction="row">
        <Typography
          component="div"
          variant="subtitle1"
          color="primary.contrastText"
          style={{
            fontWeight: theme.typography.fontWeightBold,
            fontSize: "1rem",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {item.label}
        </Typography>
        <ExpandMoreIcon htmlColor={theme.palette.primary.contrastText} />
      </Stack>
      {open && (
        <Stack
          direction="column"
          spacing={2}
          bgcolor="primary.main"
          textAlign="left"
          p={2}
          sx={{ position: "absolute", top: "100%", right: 0, zIndex: 2000 }}
        >
          {item.menu.map((subItem) => (
            <MaterialLink
              variant="subtitle1"
              key={subItem.href}
              href={subItem.href}
              underline="none"
              color={theme.palette.primary.contrastText}
              style={{
                fontWeight: theme.typography.fontWeightBold,
                fontSize: "0.8rem",
                textDecoration: "none",
                whiteSpace: "nowrap",
              }}
            >
              {subItem.label}
            </MaterialLink>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export interface SvgIconProps
  extends StandardProps<React.SVGProps<SVGSVGElement>, SvgIconClassKey> {
  color?: PropTypes.Color | "action" | "disabled" | "error";
  component?: React.FC<SvgIconProps>;
  fontSize?: "inherit" | "default" | "small" | "large";
  nativeColor?: string;
  titleAccess?: string;
  viewBox?: string;
}

const NavLinks = () => {
  const theme = useTheme();
  const { channelTheme } = useContext(SettingsContext);
  const topLinks = getTopLinks(channelTheme as TenantName) ?? [];
  return (
    <Stack direction="row" spacing={2} sx={{ margin: theme.spacing(2) }}>
      {topLinks.map((item: TopLink) => {
        const Icon = item.Icon as React.FC<SvgIconProps>;
        return item.href != undefined ? (
          <Stack key={item.label}>
            <MaterialLink
              href={item.href}
              target={item.target}
              underline="none"
              color={theme.palette.primary.contrastText}
              variant="subtitle1"
              style={{
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {item.Icon ? (
                <Icon fontSize="small" style={{ paddingTop: "5px" }} />
              ) : null}
              {item.label}
            </MaterialLink>
          </Stack>
        ) : (
          <SubMenu item={item} key={item.label} />
        );
      })}
      <TopDivider />
    </Stack>
  );
};

const NavLinksMobile: React.FC = () => {
  const theme = useTheme();
  const { channelTheme } = useContext(SettingsContext);

  const topLinks = getTopLinks(channelTheme as TenantName) as TopLink[];
  const [mobileNavopen, setMobileNavSetOpen] = React.useState(false);

  if (topLinks?.length) {
    return (
      <>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setMobileNavSetOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          PaperProps={{
            sx: {
              width: 250,
              backgroundColor: theme.palette.primary.main,
            },
          }}
          open={mobileNavopen}
          onClose={() => setMobileNavSetOpen(false)}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setMobileNavSetOpen(false)}
          >
            <List>
              {topLinks.map(({ label, href, menu }) => (
                <React.Fragment key={`${label}-${href}`}>
                  <ListItem key={`${label}-${href}`} disablePadding>
                    <ListItemButton>
                      {href != undefined ? (
                        <MaterialLink
                          variant="subtitle1"
                          color={theme.palette.primary.contrastText}
                          style={{
                            fontWeight: theme.typography.fontWeightBold,
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                          sx={{
                            fontSize: ["12px", "16px", "18px"],
                            paddingRight: theme.spacing(2),
                          }}
                          href={href}
                        >
                          {label}
                        </MaterialLink>
                      ) : (
                        <ListItemText>
                          <Typography
                            color={theme.palette.primary.contrastText}
                          >
                            {label}
                          </Typography>
                        </ListItemText>
                      )}
                    </ListItemButton>
                  </ListItem>
                  {menu?.map(({ label, href }) => (
                    <ListItem key={`${label}-${href}`} disablePadding>
                      <ListItemButton>
                        <MaterialLink
                          color={theme.palette.primary.contrastText}
                          href={href}
                          underline="none"
                        >
                          {label}
                        </MaterialLink>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Drawer>
      </>
    );
  }
};

const Header = ({ draft = false }: { draft?: boolean }) => {
  const { isAuthenticated, user, signOut } = useContext(SettingsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Adjust breakpoint as needed
  const isHiVisible = useMediaQuery(theme.breakpoints.up("md")); // Adj
  return (
    <Root>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          height: "64px !important",
          position: draft ? "relative" : undefined,
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            "@media (min-width: 0px)": { paddingRight: 1.5, paddingLeft: 1.5 },
            height: "64px !important",
          }}
        >
          {isMobile && <NavLinksMobile />}
          <Stack flexGrow={1} direction="row" alignItems="center">
            <Stack
              sx={{
                maxWidth: theme.logo.width || 200,
                width: ["200px", theme.logo.width],
                pt: 0.5,
              }}
              alignItems="center"
            >
              <Link to={theme.logo.logoUrl ? theme.logo.logoUrl : corpUrl("/")}>
                <img
                  src={theme.logo.imageSrc}
                  alt={theme.logo.alt}
                  width="100%"
                  height={"auto"}
                />
              </Link>
            </Stack>
            {theme.logo.poweredBy && (
              <Stack mx={2} width={["150px", "auto"]} alignItems="flex-start">
                <img
                  src={poweredBy}
                  alt="Powered by Pearl Edison"
                  width="100%"
                  height="auto"
                />
              </Stack>
            )}
          </Stack>
          <Stack
            textAlign="right"
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {!isMobile && <NavLinks />}
              {isAuthenticated ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {isHiVisible && (
                    <>
                      <Box
                        sx={{
                          mr: 2,
                          textAlign: "right",
                          display: ["initial"],
                        }}
                      >
                        {user.firstName ? (
                          <Typography
                            color={theme.palette.primary.contrastText}
                            variant="subtitle1"
                            textTransform="capitalize"
                            alignSelf="center"
                          >
                            Hi,&nbsp;
                            {shortenFullName(
                              user.firstName ?? "",
                              user.lastName ?? ""
                            )}
                          </Typography>
                        ) : null}
                      </Box>
                      <TopDivider />
                    </>
                  )}
                  <Box
                    sx={{ mr: 0, ml: isHiVisible ? 2 : 0, textAlign: "right" }}
                  >
                    <MaterialLink
                      component="div"
                      variant="subtitle1"
                      onClick={() => {
                        signOut();
                      }}
                      color={theme.palette.primary.contrastText}
                      style={{
                        fontWeight: theme.typography.fontWeightBold,
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      sx={{
                        fontSize: ["12px", "16px", "18px"],
                      }}
                    >
                      Sign out
                    </MaterialLink>
                  </Box>
                </Box>
              ) : (
                <MaterialLink
                  component={Link}
                  variant="subtitle1"
                  to="/login"
                  color={theme.palette.primary.contrastText}
                  style={{
                    textDecoration: "none",
                    fontWeight: theme.typography.fontWeightBold,
                  }}
                >
                  Sign in
                </MaterialLink>
              )}
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
    </Root>
  );
};

export default Header;
