import React from "react";
import { Box, Typography, Grid, Card, Divider } from "@mui/material";
import { SectionContentProps } from "../../types";
import { Root, classes } from "./styles";

const airDoctorsLogo = "/assets/airDoctorsLogo.png";
const airDoctorsOnSite = "/assets/airDoctorsOnSite.png";

// cert images
const certificationBetterBusiness = "/assets/certificationBetterBusiness.png";
const certificationPearlPartner = "/assets/certificationPearlPartner.png";
const certificationBuildingPerformance =
  "/assets/certificationBuildingPerformance.png";
const certificationSmallBusiness = "/assets/certificationSmallBusiness.png";

// FYI: use in place of images of public assets folder unavailable
// import PlaceholderImage from "@library/components/PlaceholderImage";

const baseLanguageLocation = "concierge.PackageDetails.WhyPearlEdisonContent";

const howItWorksItems = ["responsible", "guarantee", "negotiate", "money"];
const standardItems = [
  "licenseCheck",
  "technicalVetting",
  "ethicalVetting",
  "codeOfConduct",
];

export const WhyPearlEdisonContent: React.FC<SectionContentProps> = ({
  theme,
}) => (
  <Root>
    <Box>
      <Typography className={classes.sectionContentDescription} sx={{ mb: 2 }}>
        {theme?.t(`${baseLanguageLocation}.description`)}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography
            variant={"h5"}
            color="primary.light"
            className={classes.largeCardTitle}
            sx={{ mb: 2 }}
          >
            {theme?.t(`${baseLanguageLocation}.howItWorks.title`)}
          </Typography>
          {howItWorksItems.map((key) => (
            <Box key={key} sx={{ mb: 2 }}>
              <Typography className={classes.boldText}>
                {theme?.t(
                  `${baseLanguageLocation}.howItWorks.items.${key}.title`
                )}
              </Typography>
              <Typography variant="body2">
                {theme?.t(
                  `${baseLanguageLocation}.howItWorks.items.${key}.description`
                )}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant={"h5"}
            color="primary.light"
            className={classes.largeCardTitle}
            sx={{ mb: 2 }}
          >
            {theme?.t(`${baseLanguageLocation}.ourStandards.title`)}
          </Typography>
          {standardItems.map((key) => (
            <Box key={key} sx={{ mb: 2 }}>
              <Typography className={classes.boldText}>
                {theme?.t(
                  `${baseLanguageLocation}.ourStandards.items.${key}.title`
                )}
              </Typography>
              <Typography variant="body2">
                {theme?.t(
                  `${baseLanguageLocation}.ourStandards.items.${key}.description`
                )}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.lightPurpleCard}>
            <Typography
              className={classes.boldText}
              color="primary.light"
              sx={{ mb: 2 }}
            >
              {theme?.t(`${baseLanguageLocation}.featuredPartner.title`)}
            </Typography>
            <Box className={classes.logoContainer}>
              <img
                src={airDoctorsLogo}
                alt="Air Doctors Logo"
                className={classes.logo}
              />
            </Box>
            <img
              src={airDoctorsOnSite}
              alt="Air Doctors Onsite"
              className={classes.teamImage}
            />
            <Typography className={classes.boldText} sx={{ my: 1 }}>
              {theme?.t(`${baseLanguageLocation}.featuredPartner.companyName`)}
            </Typography>
            <Typography sx={{ mb: 2 }} variant="body2">
              {theme?.t(`${baseLanguageLocation}.featuredPartner.description`)}
            </Typography>
            <Box className={classes.contentLineItem} sx={{ my: 1 }}>
              <Typography className={classes.boldText}>
                {theme?.t(
                  `${baseLanguageLocation}.featuredPartner.founded.label`
                )}
                :
              </Typography>
              <Typography>
                {theme?.t(
                  `${baseLanguageLocation}.featuredPartner.founded.value`
                )}
              </Typography>{" "}
              {/* TODO: Find out when founded */}
            </Box>
            <Divider />
            <Box className={classes.contentLineItem} sx={{ my: 1 }}>
              <Typography className={classes.boldText}>
                {theme?.t(
                  `${baseLanguageLocation}.featuredPartner.headquarters.label`
                )}
                :
              </Typography>
              <Typography>
                {theme?.t(
                  `${baseLanguageLocation}.featuredPartner.headquarters.value`
                )}
              </Typography>
            </Box>
            <Divider />
            <Typography className={classes.boldText} sx={{ my: 1 }}>
              {theme?.t(
                `${baseLanguageLocation}.featuredPartner.certifications`
              )}
              :
            </Typography>
            <Grid item container xs={12}>
              <Grid xs={6} className={classes.certImageContainer}>
                <img
                  src={certificationBetterBusiness}
                  alt="Better Business Bureau Rating"
                  className={classes.certImage}
                />
              </Grid>
              <Grid xs={6} className={classes.certImageContainer}>
                <img
                  src={certificationPearlPartner}
                  alt="Pearl Partner"
                  className={classes.certImage}
                />
              </Grid>
              <Grid xs={12} className={classes.certImageContainer}>
                <img
                  src={certificationBuildingPerformance}
                  alt="Building Performance Association"
                  className={`${classes.certImage} ${classes.largeCertImage}`}
                />
              </Grid>
              <Grid xs={12} className={classes.certImageContainer}>
                <img
                  src={certificationSmallBusiness}
                  alt="Small Business Certified"
                  className={classes.certImage}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  </Root>
);
