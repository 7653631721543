/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import CheckIcon from "@mui/icons-material/Check";

export const Progress = ({
  slides,
  slide,
  // onChange,
  sx = { margin: 2 },
}: {
  slides: any[];
  slide: any;
  slideIndex: number;
  onChange: (slideIndex: number) => void;
  sx: any;
}) => {
  const theme = useTheme();
  const currentKey = slide.progress;
  const keys = useMemo(() => {
    const _keys: string[] = [];
    slides.forEach((s) => {
      if (s.progress && !_keys.includes(s.progress)) {
        _keys.push(s.progress);
      }
    });
    return _keys;
  }, [slides]);
  const keyIndex = keys.findIndex((value) => value === slide.progress);
  if (!currentKey) return <></>;
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ display: ["none", "inherit"], ...sx }}
    >
      {keys.map((key: string, index: number) => {
        return (
          <Stack key={key} direction="row" spacing={2}>
            {index < keyIndex ? (
              <Stack
                sx={{
                  width: "25px",
                  height: "25px",
                  borderRadius: 10,
                  backgroundColor: theme.palette.primary.main,
                }}
                alignItems="center"
                justifyContent="center"
              >
                <CheckIcon sx={{ color: "common.white" }} />
              </Stack>
            ) : null}
            {index >= keyIndex ? (
              <Stack
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  backgroundColor:
                    index < keyIndex + 1
                      ? theme.palette.primary.main
                      : theme.palette.grey[500],
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Typography sx={{ color: "common.white", fontSize: 14 }}>
                  {index + 1}
                </Typography>
              </Stack>
            ) : null}
            <Typography
              sx={{
                fontSize: 14,
                minWidth: "50px",
                color:
                  index <= keyIndex
                    ? theme.palette.primary.main
                    : theme.palette.grey[500],
                fontWeight: index == keyIndex ? "bold" : "initial",
              }}
            >
              {key}
            </Typography>
            {index < keys.length - 1 ? (
              <Typography
                sx={{
                  color:
                    index < keyIndex
                      ? theme.palette.primary.main
                      : theme.palette.grey[500],
                }}
              >
                &mdash;
              </Typography>
            ) : null}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Progress;
