import jake from "@library/assets/jake.png";
import { PHONE_NUMBER, PHONE_NUMBER_TEL } from "@library/common";

export interface Advisor {
  name: string;
  image: string;
  phone: string;
  phoneNumberTel: string;
  email: string;
}

export type BorderStyle = "full" | "right" | "none";

export const defaultAdvisor: Advisor = {
  name: "Jake Y.", // @TODO
  image: jake, // @TODO
  phone: PHONE_NUMBER, // @TODO
  phoneNumberTel: PHONE_NUMBER_TEL, // @TODO
  email: "jake@pearledison.com", // @TODO
};
