import { KeywordData } from "./types";

import dualFuelIcon from "@library/assets/dualFuel.svg";
import ductedIcon from "@library/assets/ducted.svg";
import heatPumpIcon from "@library/assets/heatPump.svg";
import weatherizationIcon from "@library/assets/weatherization.svg";

export const KEYWORD_LIBRARY: Record<string, KeywordData> = {
  "heat pump": {
    term: "Heat Pump",
    icon: heatPumpIcon,
    description:
      "A heat pump efficiently heats and cools your home using electricity - sort of like a combined furnace and air conditioner",
    learnMoreLink: "#",
  },
  ducted: {
    term: "Ducted",
    icon: ductedIcon,
    description:
      "This system will utilize your existing ductwork, assuming it's usable. This reduces the cost of your new system.",
    learnMoreLink: "#",
  },
  "dual-fuel": {
    term: "Dual-Fuel",
    icon: dualFuelIcon,
    description:
      "This system runs on electricity (heat pump) and gas (backup) - whichever is cheaper. This reduces your utility bill.",
    learnMoreLink: "#",
  },
  weatherization: {
    term: "Weatherization",
    icon: weatherizationIcon,
    description:
      "Reducing energy waste - via insulation, air sealing, and ductwork - improves comfort and reduces bills.",
    learnMoreLink: "#",
  },
};
