import React, { useMemo } from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";
import ImpactChart from "../ImpactChart";
import {
  energyPricesOptions,
  // energyUsageOptions, // FYI: not messin' with usage for now
  weatherOptions,
} from "../../constants";
import { formatCurrency, processGraphData } from "@library/common";

const LIFETIME = 15;

const baseLanguageLocation = "concierge.PackageDetails.BillImpactContent";

export const BillImpactContent: React.FC<SectionContentProps> = ({
  theme,
  graphData,
  graphDataOptions,
  onGraphOptionChange,
}) => {
  const processedData = useMemo(
    () => processGraphData(graphData, "price"),
    [graphData]
  );

  const totalSavings = processedData.reduce(
    (sum, month) => sum + month.savings,
    0
  );

  const handleWeatherChange = (value: string) => {
    onGraphOptionChange?.({ weatherSeverity: value });
  };

  const handleEnergyPricesChange = (value: string) => {
    onGraphOptionChange?.({ energyPrices: value });
  };

  return (
    <Root>
      <Box>
        <Box className={classes.sectionContentDescription} sx={{ mb: 2 }}>
          {theme?.t(`${baseLanguageLocation}.savingsIntro`)}{" "}
          <strong>{formatCurrency(totalSavings)}</strong>{" "}
          {theme?.t(`${baseLanguageLocation}.savingsMiddle`)}{" "}
          <strong>{formatCurrency(totalSavings * LIFETIME)}</strong>{" "}
          {theme?.t(`${baseLanguageLocation}.savingsEnd`)}
        </Box>
        {/* Dropdowns container */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>
                {theme?.t(`${baseLanguageLocation}.weather`)}
              </InputLabel>
              <Select
                value={graphDataOptions?.weatherSeverity || "Average"}
                label={theme?.t(`${baseLanguageLocation}.weather`)}
                onChange={(e) => handleWeatherChange(e.target.value)}
              >
                {weatherOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* FYI: not messin' with usage for now */}
          {/* <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>My Energy Usage</InputLabel>
              <Select
                value={energyUsage}
                label="My Energy Usage"
                onChange={(e) => setEnergyUsage(e.target.value)}
              >
                {energyUsageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>
                {theme?.t(`${baseLanguageLocation}.energyPrices`)}
              </InputLabel>
              <Select
                value={graphDataOptions?.energyPrices || "Status Quo"}
                label={theme?.t(`${baseLanguageLocation}.energyPrices`)}
                onChange={(e) => handleEnergyPricesChange(e.target.value)}
              >
                {energyPricesOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ImpactChart data={processedData || []} />
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
