import React from "react";
import { Typography, Box, Grid, Card, Divider } from "@mui/material";

import { Root, classes } from "./styles";
import { Rebates, SectionContentProps } from "../../types";
import michiganSavesLogo from "@library/assets/michiganSavesLogo.png";
import { formatCurrency, formatPricingData } from "@library/common";
import { parseTextForKeywords } from "../../utils/textParser";

const happyCustomer = "/assets/happyCustomer.png";

const baseLanguageLocation = "concierge.PackageDetails.SummaryContent";

export const SummaryContent: React.FC<SectionContentProps> = ({
  package: pkg,
  theme,
}) => {
  const formattedSections = formatPricingData(pkg?.polyvectorPayload);

  const upFrontCost = pkg?.data?.upFrontCostTotal || 0;
  const rebates = (pkg?.polyvectorPayload?.rebates || {}) as Rebates;
  const eligibleRebate = rebates["Eligible Rebate"] ?? 0;

  // Calculate costs after rebates
  const afterEligibleRebates = upFrontCost - eligibleRebate;

  // Payment options calculations
  const monthlyPayment = Math.round(afterEligibleRebates / 120); // 120 months

  const { decoratedText, footnotes } = parseTextForKeywords(
    pkg?.data?.recommendationExplanation || ""
  );

  return (
    <Root>
      <Box>
        {decoratedText}
        <Typography
          variant={"h5"}
          className={classes.sectionContentSubtitle}
          sx={{ mt: 2, mb: 1 }}
        >
          {theme?.t(`${baseLanguageLocation}.subtitle`)}:
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box>
              {footnotes.map((footnote, index) => (
                <Box className={classes.smallCardContainer} key={index}>
                  <Card className={classes.smallCard}>
                    <Typography
                      component="div"
                      className={classes.footnoteIndex}
                    >
                      {index + 1}
                    </Typography>
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        className={classes.smallCardIconContainer}
                      >
                        <img
                          src={footnote.icon}
                          alt={footnote.term}
                          style={{
                            width: 80,
                            height: 80,
                            objectFit: "contain",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        className={classes.smallCardTextContainer}
                      >
                        <Typography className={classes.boldText}>
                          {footnote.term}
                        </Typography>
                        <Typography variant="body2">
                          {footnote.description}
                        </Typography>
                        <a href="#">
                          {theme?.t(`${baseLanguageLocation}.learnMore`)}
                        </a>
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.largeCard}>
              <Typography
                variant={"h5"}
                className={classes.largeCardTitle}
                sx={{ ml: 2, mb: 2 }}
              >
                {theme?.t(`${baseLanguageLocation}.summaryQuote`)}
              </Typography>
              <img src={happyCustomer} style={{ width: "100%" }} />
              {formattedSections.map((section, index) => (
                <React.Fragment key={`${section.title}-${index}`}>
                  <Box
                    className={classes.contentLineItem}
                    sx={{ py: 1, px: 2 }}
                  >
                    <Typography className={classes.boldText}>
                      {section.title}
                    </Typography>
                    <Typography>${section.total.toLocaleString()}</Typography>
                  </Box>
                  <ul className={classes.list}>
                    {section.items.map((item, idx) => (
                      <li key={`${item.id}-${idx}`}>
                        <Typography variant="body2">{item.name}</Typography>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              ))}
              <Divider sx={{ mt: 2 }} />
              <Box className={classes.contentLineItem} sx={{ pt: 2, px: 2 }}>
                <Typography>
                  {theme?.t(`${baseLanguageLocation}.cost`)}
                </Typography>
                <Typography>{formatCurrency(upFrontCost)}</Typography>
              </Box>
              <Box className={classes.contentLineItem} sx={{ pt: 1, px: 2 }}>
                <Typography>
                  {theme?.t(`${baseLanguageLocation}.rebates`)}
                </Typography>
                <Typography>{formatCurrency(eligibleRebate)}</Typography>
              </Box>
              <Box className={classes.contentLineItem} sx={{ pt: 1, px: 2 }}>
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.costAfterRebates`)}
                </Typography>
                <Typography className={classes.boldText}>
                  {" "}
                  {formatCurrency(afterEligibleRebates)}
                </Typography>
              </Box>
              <Box className={classes.financeBoxContainer}>
                <img src={michiganSavesLogo} />
                <Typography>
                  {theme?.t(`${baseLanguageLocation}.potentialFinancing`, {
                    amount: formatCurrency(monthlyPayment),
                  })}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
