import React, { useCallback, useState } from "react";
import { Box, Modal, Button, Stack, Typography, useTheme } from "@mui/material";
import { InlineWidget } from "react-calendly";

import { api } from "@library/api";

import HowToProceed from "./HowToProceed";
import {
  CALENDLY_URL_HOME_ASSESSMENT,
  CALENDLY_URL_HOME_CONSULT,
} from "@library/common";

export const Calendly = ({
  quoteId,
  handleClose,
  setWarning,
}: {
  quoteId: string;
  handleClose: (_show: boolean) => void;
  setWarning: (_message: string) => void;
}) => {
  const [activeStep, setActiveStep] = useState<string>();

  const handleSubmit = useCallback(async () => {
    api
      .put("quote", { instantEstimateCompleted: true }, { id: quoteId })
      .catch(() => {
        setWarning(
          "A backend error occured while carrying out your last request."
        );
      });
    handleClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);
  const handleOnClick = (value: string) => setActiveStep(value);

  if (!activeStep) {
    return (
      <Stack
        my={5}
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        maxWidth={600}
        spacing={2}
      >
        <Typography variant="h4">How do you want to proceed?</Typography>
        <HowToProceed onClick={handleOnClick} />
      </Stack>
    );
  }

  const calendlyUrl =
    activeStep === "home_assessment"
      ? CALENDLY_URL_HOME_ASSESSMENT
      : CALENDLY_URL_HOME_CONSULT;

  return (
    <Stack alignSelf="center" my={5}>
      <Typography variant="h4">
        {activeStep === "home_assessment"
          ? "I'm ready to schedule a home assessment"
          : "I'd like a virtual consult first"}
      </Typography>
      <InlineWidget url={calendlyUrl} />
      <Box sx={{ alignSelf: "center" }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{ alignSelf: "center" }}
          onClick={handleSubmit}
        >
          Done
        </Button>
      </Box>
    </Stack>
  );
};

export const CalendlyModal = ({
  quoteId,
  handleClose = () => {},
  setWarning = () => {},
}: {
  quoteId: string;
  handleClose: (_show: boolean) => void;
  setWarning: (_message: string) => void;
}) => {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    minWidth: "400px",
    minHeight: "400px",
    bgcolor: theme.palette.primary.contrastText,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Calendly
          quoteId={quoteId}
          handleClose={handleClose}
          setWarning={setWarning}
        />
      </Box>
    </Modal>
  );
};

export default CalendlyModal;
