import { styled } from "@mui/material";

const PREFIX = "AdvisorButton";

export const classes = {
  userProfileButton: `${PREFIX}-userProfileButton`,
  avatarAndNameContainer: `${PREFIX}-avatarAndNameContainer`,
  advisorName: `${PREFIX}-advisorName`,
  advisorLabel: `${PREFIX}-advisorLabel`,
  advisorContactInfoContainer: `${PREFIX}-advisorContactInfoContainer`,
  advisorContactInfoRow: `${PREFIX}-advisorContactInfoRow`,
  advisorContactInfo: `${PREFIX}-advisorContactInfo`,
  mobileAvatarContainer: `${PREFIX}-mobileAvatarContainer`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.avatarAndNameContainer}`]: {
    display: "flex",
    alignItems: "center",
    minWidth: "fit-content",
    paddingLeft: "10px",
  },
  [`& .${classes.advisorLabel}`]: {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },
  [`& .${classes.advisorName}`]: {
    fontSize: "1rem",
    marginTop: -2,
    whiteSpace: "nowrap",
  },
  [`& .${classes.advisorContactInfoContainer}`]: {
    marginLeft: 8,
    textAlign: "left",
    minWidth: 0, // Important for text overflow to work
    "> a": {
      fontSize: "0.875rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      minWidth: 0, // Important for text overflow to work
      padding: "0px",
      paddingRight: "10px",
    },
  },
  [`.${classes.advisorContactInfoRow}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& p": {
      fontSize: "0.875rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      minWidth: 0, // Important for text overflow to work
    },
    "& svg": {
      fontSize: "0.875rem",
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.advisorContactInfo}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    "& svg": {
      color: theme.palette.common.white,
    },
  },
  [`& .${classes.userProfileButton}`]: {
    width: "100%",
    height: "75px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textTransform: "none",
    justifyContent: "space-between",
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  [`& .${classes.mobileAvatarContainer}`]: {
    width: "100%",
    height: "75px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
}));
