import React from "react";
import { useTheme } from "@mui/material";

export const Stairs = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M149.271 85.5H69.2715"
        style={{ stroke: theme.palette.secondary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M119.257 73.0321C119.181 69.9706 114.747 67.5 109.272 67.5C103.797 67.5 99.3619 69.9706 99.2856 73.0321H99.2715V77.5H119.271V73.0321H119.257Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.271 67.5V76.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.2715 117.5V95.5H80.2715V117.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.2715 80.5L109.271 50.5L156.271 80.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.2715 74.5L109.271 42.5L159.271 74.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.271 76.5V118.5H69.2715V76.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.772 89.5684V94.0684H136.772V98.5684H131.272V103.068H125.272V107.568H119.272V111.068H113.57V115.912"
        style={{ stroke: theme.palette.secondary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M163.271 118.5H55.2715"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="84.2715" cy="108.5" r="1" fill="white" />
    </svg>
  );
};
export default Stairs;
