import { Theme } from "@mui/material";

// Primary Colors
const DEEP_PURPLE = "#591E90";
const MEDIUM_ORCHID = "#9B48B5";

// Secondary Colors
const BRIGHT_BLUE = "#0088D1";
const SUNFLOWER = "#FFB404";
const RED = "#E64A19";
const LIGHT_RED = "#FBE8E6";
const COCONUT_TWIST = "#FFF9E1";

// Common
const WHITE = "#FFF";
const BLACK = "#000";

// Text
const GRAY = "#65635A";

export const baseOverrides = {
  logo: {
    imageSrc: "",
    alt: "",
    width: 100,
    height: 40,
    poweredBy: false,
  },
  typography: {
    fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
    h1: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "45px",
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
      color: GRAY,
    },
  },
  palette: {
    common: {
      white: WHITE,
      black: BLACK,
    },
    text: {
      primary: BLACK,
      secondary: GRAY,
    },
    primary: {
      main: DEEP_PURPLE,
      light: MEDIUM_ORCHID,
    },
    secondary: {
      main: BRIGHT_BLUE,
    },
    background: {
      default: "#fff",
    },
    error: {
      main: RED,
      light: LIGHT_RED,
    },
    warning: {
      main: SUNFLOWER,
      light: COCONUT_TWIST,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          return {
            textTransform: "none",
            borderRadius: "10px",
            fontWeight: 500,
            "&.MuiButton-containedPrimary:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
            "&.MuiButton-containedSecondary:hover": {
              backgroundColor: theme.palette.secondary.dark,
            },
          };
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          tableLayout: "auto",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          transform: "translate(14px, 12px) scale(1.0)",
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -12px) scale(0.75)",
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          borderRadius: "8px",
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 28,
        padding: 10,
        fontWeight: 500,
      },
    },
    defaultProps: {
      // LinkComponent: LinkBehaviour,
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "filled",
    },
    styleOverrides: {
      filled: {
        backgroundColor: "#FFF",
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: "filled",
    },
    styleOverrides: {
      filled: {
        backgroundColor: "white",

        input: {
          padding: "25px 10px 10px",
          backgroundColor: "#FFF",
        },
      },
    },
  },
} as unknown as Theme;
