import React, { useContext } from "react";
import {
  Typography,
  Box,
  Avatar,
  DialogTitle,
  Grid,
  DialogContent,
  Button,
} from "@mui/material";
import { StyledDialog, classes } from "./styles";
import { SettingsContext } from "@library/settings/provider";
import { Advisor } from "../AdvisorProfile/types";
import { OnboardingPayload } from "@library/domain/onboarding";
import { PHONE_NUMBER } from "@library/common";

interface RepresentativeDialogProps {
  open: boolean;
  toggle: () => void;
  person?: Partial<Advisor>;
  payload?: OnboardingPayload;
}

const RepresentativeDialog: React.FC<RepresentativeDialogProps> = ({
  open,
  toggle,
  person,
  payload,
}) => {
  const { user } = useContext(SettingsContext);

  // Use person data if provided, otherwise fall back to user data
  const displayPerson = person || {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    phone: user.phoneNumber,
  };

  return (
    <StyledDialog open={open} onClose={toggle}>
      <DialogTitle className={classes.repDialogTitle}>
        {person ? "Your Advisor" : "Your Rep"}
      </DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        <Grid container className={classes.avatarAndInfoContainer}>
          <Grid item xs={3}>
            <Avatar
              alt="Profile picture"
              src={displayPerson.image || ""}
              sx={{ width: 50, height: 50 }}
            />
          </Grid>
          <Grid item xs={9}>
            <Box className={classes.userInfoContainer}>
              <Typography variant="body1" sx={{ lineHeight: 1.2 }}>
                {displayPerson.name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={{ mt: 2 }}
          className={classes.emailAndPhoneContainer}
        >
          <Grid item>
            <Typography className={classes.boldText} sx={{ pr: 3 }}>
              Email
            </Typography>
          </Grid>
          <Grid item>
            <Button
              component="a"
              href={`mailto:${displayPerson.email}?subject=${encodeURIComponent(`Please contact me regarding the quote for ${payload?.user?.formatted_address ? payload.user.formatted_address : "my home"}`)}`}
              target="_blank"
              sx={{
                textTransform: "none",
                justifyContent: "flex-start",
                padding: "8px 0",
                width: "100%",
              }}
            >
              <Typography>{displayPerson.email}</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} container className={classes.emailAndPhoneContainer}>
          <Grid item>
            <Typography className={classes.boldText}>Phone</Typography>
          </Grid>
          <Grid item>
            <Button
              component="a"
              href={`tel:+${displayPerson.phone}`}
              target="_blank"
              sx={{
                textTransform: "none",
                justifyContent: "flex-start",
                padding: "8px 0",
                width: "100%",
              }}
            >
              <Typography>{displayPerson.phone || PHONE_NUMBER}</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default RepresentativeDialog;
