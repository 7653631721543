import React, { useEffect, useState } from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// import { usePlacesWidget } from "react-google-autocomplete";
import { getGoogleAddressPart } from "@library/common/address";

import { Address } from "@library/domain/home";
import { PlacesAddress } from "@library/common/address";

import api from "@library/api";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { DEFAULT_FIPS_CODE, operationalStates } from "@library/common";

import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";

const autocompleteService = { current: null };

declare global {
  interface Window {
    matchedAddress: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}
window.matchedAddress = false;

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export const AutocompleteAddress = (props: WidgetProps) => {
  const theme = useTheme();
  const { width } = props.uiSchema || {};

  const [value, setValue] = React.useState<PlaceType | null>(props.value);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const navigate = useNavigate();
  const { payload, setPayload, setSlideDirection } = props.formContext || {};
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (place: PlaceType | null) => {
    if (!place || !place.description) {
      setError("Please select an address from the dropdown");
      return;
    }
    setIsLoading(true);

    props.onChange(place.description);
    let userAddress: Partial<Address> = { state: "" };
    try {
      const response = await api.post("onboarding/address", {
        address: place.description,
      });
      userAddress = response.data as Address;
    } catch (e) {
      console.error("Unable to save address");
    }
    const { state = "" } = userAddress;
    if (!operationalStates.includes(state)) {
      setIsLoading(false);
      localStorage.setItem("pearl-not-operational-address", place.description);
      navigate("/not-operational");
      return;
    }
    if (
      !getGoogleAddressPart(
        userAddress as unknown as PlacesAddress,
        "street_number"
      )
    ) {
      setError("Address must include a street number.");
      setIsLoading(false);
      return;
    }
    const newPayload = {
      ...payload,
      user: {
        ...payload.user,
        ...userAddress,
        formatted_address: userAddress.address,
      },
    };
    newPayload.user.fipsCountyCode =
      newPayload.user.fipsCountyCode || DEFAULT_FIPS_CODE;
    setPayload(newPayload);
    setSlideDirection(1);
    setIsLoading(false);
  };

  const fetch = React.useMemo(
    () =>
      debounce(
        (input: string, callback: (results?: readonly PlaceType[]) => void) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (autocompleteService.current as any).getPlacePredictions(
            {
              input,
              componentRestrictions: {
                country: "us",
              },
              types: ["street_number", "route"],
              locationBias: "IP_BIAS",
            },
            callback
          );
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (
      !autocompleteService.current &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).google &&
      window.google?.maps?.places
    ) {
      autocompleteService.current =
        new // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    if (window.matchedAddress === true) return;
    const qs = queryString.parse(location.search);
    const address = qs.address;
    if (address && import.meta.env.VITE_GOOGLE_MAPS_API_KEY) {
      setIsLoading(true);
      api
        .post("onboarding/address", {
          address,
        })
        .then(({ data = {} }) => {
          window.matchedAddress = true;
          const { state } = data;
          if (!operationalStates.includes(state)) {
            setIsLoading(false);
            localStorage.setItem("pearl-not-operational-address", data.address);
            navigate("/not-operational");
            return;
          }
          if (!getGoogleAddressPart(data, "street_number")) {
            setError("Address must include a street number.");
            setIsLoading(false);
            return;
          }
          props.onChange(data.address);
          setPayload({
            ...payload,
            user: {
              ...payload.user,
              ...data,
              formatted_address: data.address,
            },
          });
          setSlideDirection(1);
          setIsLoading(false);
          window.history.replaceState({}, document.title, "/onboarding");
        })
        .catch(() => {
          props.onChange(address);
          setPayload({
            ...payload,
            user: {
              ...payload.user,
              address,
            },
          });
          setSlideDirection(1);
          setIsLoading(false);
          window.history.replaceState({}, document.title, "/onboarding");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addessSearchButtonText = theme.t("addessSearchButtonText");

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {isLoading && (
        <Box
          justifyContent="center"
          alignItems="center"
          sx={{
            zIndex: 9999,
            position: "fixed",
            top: "64px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            background: `rgba(255,255,255,0.05)`,
          }}
        >
          <Stack
            sx={{
              top: "calc(50% - 20px)",
              left: "calc(50% - 20px)",
              position: "relative",
            }}
          >
            <CircularProgress color="secondary" />
          </Stack>
        </Box>
      )}
      <Stack
        sx={{ width: "100%", minWidth: ["initial", "600px", "800px"] }}
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="row"
      >
        <Stack
          sx={{ width: "100%" }}
          justifyContent="flex-start"
          alignItems="flex-start"
          direction="row"
        >
          <Autocomplete
            sx={{
              width: "100%",
              div: {
                borderRadius: 1,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
              "> div": {
                height: "60px",
                backgroundColor: theme.palette.primary.contrastText,
              },
              fieldset: {
                border: "none",
              },
            }}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No locations"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(_event: any, newValue: PlaceType | null) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
              handleSubmit(newValue);
            }}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                type="text"
                // className="custom"
                placeholder={
                  theme.t("enterAddressPlaceholder") ||
                  "Enter your address to see your design and price in minutes"
                }
                inputProps={{
                  ...(params.inputProps ?? {}),
                  "data-cy": "addressInput",
                }}
                // defaultValue={props.value}
                required={props.required}
                sx={{
                  width,
                  paddingTop: "3px",
                  div: {
                    border: 0,
                  },
                  input: {
                    padding: "20px",
                    backgroundColor: theme.palette.primary.contrastText,
                    borderRadius: 1,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    height: "100%",
                  },
                }}
              />
            )}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              const matches =
                option?.structured_formatting?.main_text_matched_substrings ||
                [];

              const parts = parse(
                option?.structured_formatting?.main_text,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                matches.map((match: any) => [
                  match.offset,
                  match.offset + match.length,
                ])
              );
              return (
                <li key={key} {...optionProps}>
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid item sx={{ display: "flex", width: 44 }}>
                      <LocationOnIcon sx={{ color: "text.secondary" }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 44px)",
                        wordWrap: "break-word",
                      }}
                    >
                      {parts.map((part, index) => (
                        <Box
                          key={index}
                          component="span"
                          sx={{
                            fontWeight: part.highlight ? "bold" : "regular",
                          }}
                        >
                          {part.text}
                        </Box>
                      ))}
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {option?.structured_formatting?.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            data-cy="submit"
            onClick={() => {
              const place = { description: value } as unknown as PlaceType;
              handleSubmit(place);
            }}
            sx={{
              padding: ["17px", "19.5px"],
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              width: addessSearchButtonText ? "200px" : undefined,
              minWidth: addessSearchButtonText ? "200px" : undefined,
              height: "60px",
              minHeight: "60px",
            }}
          >
            {addessSearchButtonText ? (
              <Typography sx={{ color: theme.palette.primary.contrastText }}>
                {addessSearchButtonText}
              </Typography>
            ) : (
              <SearchIcon />
            )}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AutocompleteAddress;
